import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Lightbox } from "ngx-lightbox/lightbox.service";
import {
  c1,
  c3,
  c4,
  c7,
  c8,
  c9,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-gen-sideuser-component",
  templateUrl: "./gen-sideuser-component.component.html",
  styleUrls: ["./gen-sideuser-component.component.scss"],
})
export class GenSideUserComponentComponent implements OnInit, OnDestroy {
  constructor(
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) {}

  @Input() page_id: string;
  @Input() tabData: Tab;
  @Input() config: any;
  page: any;
  c8_value: string;
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  enableSideMenu: boolean;
  appInfo: any = { id: "app_info" };
  isAndroid: boolean;

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        ) {
          this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
          const appInfo = this.indexDBService.getItem("item", "app_info");
          appInfo.onsuccess = (event) => {
            if (appInfo.result) {
              if(this.isAndroid){
                this.enableSideMenu = appInfo.result.hide_profile ? false : true;
              }else{
                this.enableSideMenu = appInfo.result.hide_profile_ios ? false : true;
              }
            }
          };
        }
      }
    );
  }

  toggleSideMenu(event) {
    this.enableSideMenu = event;
    // if (this.enableSideMenu) {
    //   this.c8_value = c8.SIDE;
    // }else {
    //  this.c8_value = c8.NOSIDE
    // }

    // let modes = [{ id: c8.ID, value: this.c8_value },];
    // this.mdw.changeMobileTheme(modes)
    // this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)

    let hide_profile = this.enableSideMenu ? 0 : 1;
    let key = this.isAndroid ? 'hide_profile' : 'hide_profile_ios';
    this.updateAppInfo(hide_profile, key);
  }

  updateAppInfo(value, key) {
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result) {
        appInfo.result[key] = value;
        this.indexDBService.updateItem("item", appInfo.result);
      }
    };
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
