<div class="not-allowed">
  <!-- ********* Android Active **************** -->
  <div
    *ngIf="heading"
    class="col-12 m-0 p-0 d-flex align-items-center"
    [ngClass]="
      label_align == 'center'
        ? 'justify-content-center'
        : label_align == 'right'
        ? 'justify-content-end'
        : 'justify-content-start'
    "
  >
    <div class="m-0 p-0">
      <p
        class="float-none w-auto m-0 d-flex align-items-center"
        [ngStyle]="headlineColor"
        [ngClass]="labellPadding"
      >
        {{ heading }}
      </p>
    </div>
  </div>

  <div
    *ngIf="subheading"
    class="col-12 m-0 p-0 d-flex align-items-center"
    [ngClass]="
      sublabel_align == 'center'
        ? 'justify-content-center'
        : sublabel_align == 'right'
        ? 'justify-content-end'
        : 'justify-content-start'
    "
  >
    <div class="m-0 p-0">
      <p
        class="float-none w-auto m-0 d-flex align-items-center"
        [ngStyle]="subheadColor"
        [ngClass]="sublabellPadding"
      >
        {{ subheading }}
      </p>
    </div>
  </div>

  <!-- <div  *ngIf="heading" class="  m-0 p-0  d-flex justify-content-start"  [ngClass]="fontSmaller?'p-0': 'px-2'">
    <p class=" m-0 text-wrap w-auto fw-bolder" [ngStyle]="labelColor"  [ngClass]="labellPadding" >
          {{label}}
      {{heading}}
   </p>
  </div>

  <div  *ngIf="subheading" class="  m-0 p-0  d-flex justify-content-start"  [ngClass]="fontSmaller?'p-0': 'px-2 py-1'">
    <p class=" m-0 text-wrap w-auto" [ngStyle]="subheadingColor">
      {{subheading}}
   </p>
  </div> -->
  <!-- ********* Android Active **************** -->

  <div *ngIf="isAndroid">
    <div
      class="card m-0 rounded bg-transparent"
      [ngClass]="fontSmaller ? 'p-0' : 'p-0'"
      [ngClass]="shadow ? 'shadow z-3' : ''"
      [style]="
        'border:' +
        outlined_border +
        '; border-color: ' +
        border_color +
        '!important'
      "
    >
      <div
        class="btn-group"
        role="group"
        aria-label="Basic example"
        style="height: {{ cellHeight }};"
      >
        <ng-container
          *ngFor="let option of options; index as index; let last = last"
        >
          <a
            class="btn btn-outline-primary border-1 d-flex justify-content-center align-items-center"
            aria-current="page"
            [ngClass]="optionPadding"
            [ngStyle]="activeOption[option.id] ? optionBgColor : null"
            [class.btn-rounded]="index == 0 || last"
            [style]="
              'border:' + outlined_border + '; border-color: ' + border_color + '!important;' + 'pointer-events: none;' + 'flex: 1;'
            "
          >
            <p
              class="m-0 p-0 text-wrap w-auto px-1 d-flex justify-content-center align-items-center"
              [ngStyle]="
                activeOption[option.id]
                  ? optionlTextColorActive
                  : optionlTextColor
              "
            >
            <i *ngIf="activeOption[option.id]" [ngStyle]="leadingIconColor" class="float-none w-auto m-0 px-1 bx bx-check"></i>
            <i *ngIf="(option.icon && !activeOption[option.id] && option.label) || (option.icon && activeOption[option.id] && !option.label)" class="float-none w-auto m-0 px-1" [ngStyle]="leadingIconColor" [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
              {{ option.label }}
            </p>
          </a>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- ********* iOS Active and Default **************** -->

  <div *ngIf="!isAndroid">
    <div
      class="card m-0 rounded"
      [ngClass]="fontSmaller ? 'p-0' : 'p-0'"
      [ngClass]="shadow ? 'shadow z-3' : ''"
      [style]="
        'border:' + outlined_border + '; border-color: ' + border_color + '!important'
      "
    >

    <div class="row m-0 p-1" style="height: {{cellHeight}}; border-radius: 0.5rem !important;">
      <ng-container *ngFor="let option of options; index as index">
        <div class="col d-flex align-items-center justify-content-center" style="border-radius: 0.5rem !important;" [ngStyle]="activeOption[option.id]? optionBgColor: null" [ngClass]="optionPadding">
          <p
            class="m-0 p-0 text-wrap w-auto px-1 d-flex justify-content-center align-items-center"
            [ngStyle]="
              activeOption[option.id]
                ? optionlTextColorActive
                : optionlTextColor
            "
          >
            {{ option.label }}
          </p>
        </div>
      </ng-container>
    </div>
    </div>
  </div>

  <div *ngIf="helper || error" class="ps-1">
    <p *ngIf="!error" class="p-0 m-0" [ngStyle]="helperColor">
      {{ helper }}
    </p>
    <p *ngIf="error" class="p-0 m-0" [ngStyle]="errorColor">
      {{ error }}
    </p>
  </div>
</div>
