import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  ViewChild,
  ElementRef,
  NgZone,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-location-input",
  templateUrl: "./location-input.component.html",
  styleUrls: ["./location-input.component.scss"],
})
export class LocationInputComponent implements OnInit, AfterViewInit {
  @Input() controlName: string; // Required input for form control name
  @Input() label: string;
  @Input() id: string;
  @Input() address: string;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() errors: any;
  @Input() submitted = false;

  @Output() emitValues = new EventEmitter<any>();

  @ViewChild("search")
  public searchElementRef!: ElementRef;

  zoom = 12;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: true,
    disableDoubleClickZoom: true,
    mapTypeId: "roadmap",
    // draggable: false,
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    if (this.latitude && this.longitude) {
      this.center = {
        lat: this.latitude,
        lng: this.longitude,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.latitude && this.longitude) {
      this.center = {
        lat: this.latitude,
        lng: this.longitude,
      };
    }
  }

  ngAfterViewInit(): void {
    // Binding autocomplete to search input control
    const search: any = document.getElementById("search" + this.id);
    let autocomplete = new google.maps.places.Autocomplete(search);
    // Align search box to center
    // this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
    //   this.searchElementRef.nativeElement
    // );
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        //set latitude, longitude and zoom
        this.address = place.formatted_address;
        this.center = {
          lat: place.geometry.location?.lat(),
          lng: place.geometry.location?.lng(),
        };
        this.emitValues.emit({
          address: place.formatted_address,
          latitude: place.geometry.location?.lat(),
          longitude: place.geometry.location?.lng(),
        });
      });
    });
  }

  mapClicked(event: google.maps.KmlMouseEvent) {
    this.center = {
      lat: Number(event.latLng.lat().toString()),
      lng: Number(event.latLng.lng().toString()),
    };
    console.log(event.latLng);
    this.getAddressFromLatLng(event.latLng);
  }

  getAddressFromLatLng(latLng: google.maps.LatLng): void {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        // this.address = results[0].formatted_address; // Get the formatted address
        console.log(results[0].formatted_address);
      } else {
        // this.address = 'Address not found';
        console.log('hi');
      }
    });
  }
}
