
<div *ngIf="screens.includes('tabs')">
  <div  class="row p-0 m-0">
    <form class="app-search d-block col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10 order-1 order-sm-2 px-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-10">
          <div class="position-relative">
            <input id="searchContact" type="text" class="form-control" placeholder="Search..." (keyup)="TabSearch()">
            <span class="bx bx-search-alt"></span>
          </div>
        </div>
    </form>
  </div>
  <div class="container-fluid p-0 d-flex flex-column overflow-hidden" style="height: calc(100% - 60px)">
    <div class="clearfix m-0 h-100">
      <div class="row m-0 p-0">
        <tabset class="nav-tabs nav-tabs-custom col">
            <tab class="mt-4">
              <ng-template tabHeading>All</ng-template>
              <ng-template [ngTemplateOutlet]="TaskAll"></ng-template>
            </tab>
            <tab *ngIf="!menu_group" class="mt-4">
              <ng-template  tabHeading>Navigation</ng-template>
              <ng-template [ngTemplateOutlet]="TaskTabsNav"></ng-template>
            </tab>
            <tab *ngIf="!menu_group" class="mt-4">
              <ng-template tabHeading>Side</ng-template>
              <ng-template [ngTemplateOutlet]="TaskTabsSide"></ng-template>
            </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>

<div *ngIf="screens.includes('menus')">
  <div  class="row p-0 m-0">
    <form class="app-search d-block col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10 order-1 order-sm-2 px-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-10">
          <div class="position-relative">
            <input id="searchContact" type="text" class="form-control" placeholder="Search..." (keyup)="MenuSearch()">
            <span class="bx bx-search-alt"></span>
          </div>
        </div>
    </form>
  </div>
  <div class="container-fluid p-0 d-flex flex-column overflow-hidden" style="height: calc(100% - 60px)">
    <div class="clearfix m-0 h-100">
      <div class="row m-0 p-0">
        <tabset class="nav-tabs nav-tabs-custom col">
            <tab class="mt-4">
              <ng-template tabHeading>All</ng-template>
              <ng-template [ngTemplateOutlet]="TaskMenusAll"></ng-template>
            </tab>
            <tab class="mt-4">
              <ng-template tabHeading>Navigation</ng-template>
              <ng-template *ngIf="!menu_group" [ngTemplateOutlet]="TaskMenusHome"></ng-template>
            </tab>
            <tab class="mt-4">
              <ng-template tabHeading>Side</ng-template>
              <ng-template *ngIf="!menu_group" [ngTemplateOutlet]="TaskMenusSide"></ng-template>
            </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>

<ng-template #TaskAll>
  <div class="overflow-x-hidden overflow-y-auto col" style="height: 70vh">
    <div class="row">
      <ng-container *ngIf="screens.includes('tabs') && sortedHomeListFiltered.length != 0">
        <div *ngFor="let homeTab of sortedHomeListFiltered; index as item" style="height: fit-content" class="col-2" [id]="homeTab.id">
          <div class="card mb-0 p-0" *ngIf="homeTab.id != '0'">
            <div class="card-header p-1 d-flex align-items-center justify-content-between">
              <div class="font-size-12 text-truncate">
                Navigation/{{ homeTab?.title?homeTab?.title:homeTab?.id }}
              </div>
            </div>
            <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(homeTab, 'tab')">
              <!-- <app-mobile-view-slider *ngIf="homeTab" [enableStatic]="true" [fontSmaller]="fontSmaller" [inputTab]="homeTab" [staticTab]="homeTab"></app-mobile-view-slider> -->
              <app-mobile-view-tab *ngIf="homeTab" [inputTab]="homeTab" [fontSmaller]="fontSmaller"></app-mobile-view-tab>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="screens.includes('tabs')  && sortedSideListFiltered.length != 0">
        <div *ngFor="let sideTab of sortedSideListFiltered; index as item" style="height: fit-content" class="col-2" [id]="sideTab.id">
          <div class="card mb-0 p-0" *ngIf="sideTab.id != '0'">
            <div class="card-header p-1 d-flex align-items-center justify-content-between">
              <div class="font-size-12 text-truncate">
                Side/{{ sideTab.title? sideTab.title:sideTab?.id }}
              </div>
            </div>
            <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(sideTab, 'tab')">
              <app-mobile-view-tab *ngIf="sideTab" [inputTab]="sideTab" [fontSmaller]="fontSmaller"></app-mobile-view-tab>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #TaskTabsNav>
  <div class="overflow-x-hidden overflow-y-auto col" style="height: 70vh">
    <div class="row">
      <ng-container *ngIf="screens.includes('tabs') && sortedHomeListFiltered.length != 0">
        <div *ngFor="let homeTab of sortedHomeListFiltered; index as item" style="height: fit-content" class="col-2" [id]="homeTab.id">
          <div class=" card mb-0 p-0" *ngIf="homeTab.id != '0'">
            <div class="card-header p-1 d-flex align-items-center justify-content-between">
              <div class="font-size-12 text-truncate">
                Navigation/{{ homeTab?.title?homeTab?.title:homeTab?.id }}
              </div>
            </div>
            <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(homeTab, 'tab')">
              <!-- <app-mobile-view-slider *ngIf="homeTab" [enableStatic]="true" [fontSmaller]="fontSmaller" [inputTab]="homeTab" [staticTab]="homeTab"></app-mobile-view-slider> -->
              <app-mobile-view-tab *ngIf="homeTab" [inputTab]="homeTab" [fontSmaller]="fontSmaller"></app-mobile-view-tab>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #TaskTabsSide>
  <div class="overflow-x-hidden overflow-y-auto col" style="height: 70vh">
    <div class="row">
        <ng-container *ngIf="screens.includes('tabs')  && sortedSideListFiltered.length != 0">
        <div *ngFor="let sideTab of sortedSideListFiltered; index as item" style="height: fit-content" class="col-2" [id]="sideTab.id">
          <div class="card mb-0 p-0" *ngIf="sideTab.id != '0'">
            <div class="card-header p-1 d-flex align-items-center justify-content-between">
              <div class="font-size-12 text-truncate">
                Side/{{ sideTab.title? sideTab.title:sideTab?.id }}
              </div>
            </div>
            <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectTab(sideTab, 'tab')">
              <app-mobile-view-tab *ngIf="sideTab" [inputTab]="sideTab" [fontSmaller]="fontSmaller"></app-mobile-view-tab>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #TaskMenusAll>
  <div class="overflow-x-hidden overflow-y-auto col" style="height: 70vh">
    <div class="row">
      <ng-container *ngIf="screens.includes('menus') && sortedHomeMenuListFiltered.length != 0">
        <ng-container *ngFor="let menus of sortedHomeMenuListFiltered; index as item">
          <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
            <div class="card mb-0 p-0">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-size-12 text-truncate">
                  Navigation/{{ menu?.menu_name?  menu?.menu_name:menu?.menu_id }}
                </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
                <app-mobile-view-slider *ngIf="menu"  [enableStatic]="true" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab' [stat]='tab' [inputTab]='tab' [colors]="colors"></app-mobile-view-slider>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="screens.includes('menus') && sortedSideMenuListFiltered.length != 0">
        <ng-container *ngFor="let menus of sortedSideMenuListFiltered; index as item">
          <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
            <div class="card mb-0 p-0">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-size-12 text-truncate">
                  Side/{{ menu?.menu_name?  menu?.menu_name:menu?.menu_id }}                    </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
                <app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab'  [enableStatic]="true"  [staticTab]="tab"   [staticMenuId]="menu?.menu_id" [colors]="colors"></app-mobile-view-slider>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #TaskMenusHome>
  <div class="overflow-x-hidden overflow-y-auto col" style="height: 70vh">
  <div class="row">
    <ng-container *ngIf="screens.includes('menus') && sortedHomeMenuListFiltered.length != 0">
      <ng-container *ngFor="let menus of sortedHomeMenuListFiltered; index as item">
        <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
          <div class="card mb-0 p-0">
            <div class="card-header p-1 d-flex align-items-center justify-content-between">
              <div class="font-size-12 text-truncate">
                Navigation/{{ menu?.menu_name?  menu?.menu_name:menu?.menu_id }}
              </div>
            </div>
            <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
              <app-mobile-view-slider *ngIf="menu"  [enableStatic]="true" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab' [stat]='tab' [inputTab]='tab' [colors]="colors"></app-mobile-view-slider>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
</ng-template>
<ng-template #TaskMenusSide>
  <div class="overflow-x-hidden overflow-y-auto col" style="height: 70vh">
    <div class="row">
      <ng-container *ngIf="screens.includes('menus') && sortedSideMenuListFiltered.length != 0">
        <ng-container *ngFor="let menus of sortedSideMenuListFiltered; index as item ">
          <div *ngFor="let menu of menus.menus; index as item" style="height: fit-content" class="col-2" [id]="menu.menu_id">
            <div class="card mb-0 p-0">
              <div class="card-header p-1 d-flex align-items-center justify-content-between">
                <div class="font-size-12 text-truncate">
                  Side/{{ menu?.menu_name?  menu?.menu_name:menu?.menu_id }}                                        </div>
              </div>
              <div class="card-body border border-1 rounded-3 border-secondary-subtle" (click)="selectMenu(menu, 'menu')">
                <app-mobile-view-slider *ngIf="menu" [fontSmaller]="fontSmaller" [inputMenuId]="menu?.menu_id" [inputTab]='tab'  [enableStatic]="true"  [staticTab]="tab"   [staticMenuId]="menu?.menu_id" [colors]="colors"></app-mobile-view-slider>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
