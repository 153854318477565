import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number = 100): string {
    if (!value) return value;
    // Check if the string exceeds the limit
    if (value.length > limit) {
      return value.substring(0, limit) + '...'; // Truncate and add ellipsis
    }
    return value; // Return the original text if it's within the limit
  }

}
