export const enum ServerErrors {
  TokenExpired = 100098,
  AuthError = 100099,
  NoPermission = 199990,
  AccountNotFound = 199991,
  MultiLogin = 199992,
  AppVersionRejected = 199996,
  NoServer = 199997,
  Redirect = 199998,
  OtherError = 199999,
  TacIsWrong = 100007,
  AlreadyActived = 100008,
  // Errors in change password
  OldPasswordNotCorrect = 500099,
  PasswordTooLong = 510019,
  PasswordTooShort = 510023,
  PasswordMustContainLowerCase = 510024,
  PasswordMustContainUpperCase = 510025,
  PasswordMustContainDigit = 510026,
  PasswordMustContainSpecial = 510027,
  PasswordMatchesPattern = 510028,
  PasswordMatchesDictionary = 510029,
  PasswordInHistory = 510030,
  PasswordsDoNotMatch = 510031,
  // Errors in Generating App
  GoogleAccountNotOpened = 500039,
  GoogleReleaseManagerNotSetup = 500041,
  AppNameNotProvided = 500042,
  GoogleScJsonNotProvided = 500043,
  AppleAccountNotOpened = 500044,
  AppleAppManagerNotSetup = 500045,
  AppleAppNameNotProvided = 500046,
  GooglePackageIdNotProvided = 500047,
  AppleBundleIdNotProvided = 500048,
  AppleTeamNameNotProvided = 500049,
  GoogleScJsonInvalidFormat = 500050,
  AppSpecificPasswordNotProvided = 500053,
  GooglePackageIdHasInvalidFormat = 500056,
  AppleBundleIdHasInvalidFormat = 500057,
  AppleDeveloperPasswordNotProvided = 500066,
  ApplePushNotiKeyNotProiveded = 500073,
  AppleUserNotProvided = 500074,
  AndroidFormatHasWrongValue = 500075,
  AppNameIsAlreadyUsed = 500076,
  BookingNotFound = 500105,
  CancelExpired = 500108,
  CancelIsNotAllowedForThisBooking = 500109,
  BookingStartTimePassed = 500115,
  //  Error in publishing bot
  UniqueBotNameError = 160014,
  DeleteChatError = 100012,
  SKUIsAlreadyUsed = 500088,
  AppleAppManagerNotConfirmed = 500052,
  DifferentCurrency = 500069,
  PatternNotmatched = 500090,
  InvalidPattern = 500089,
  NotifierIsAlreadyExist = 500096,
  UnAuthenticated = 100001,
  DifferentSubChannels = 500119,
  WrongTacError = 100007,
  RegionError = 199997,
  DeviceBanned = 100021,
  UserBanned = 188888,
  NotAllowed = 188889,
  Blocked = 188890,
  FailedTestVendor = 500121,
  ConfigurationTestVendorNotExist = 500120,
  MissingDataForTest = 500014,
  INVALID_NUMBER = 100020,
  MUST_WAIT_TAC = 100006,
  MAXIMUM_BUILD = 505050,
  APP_EXPIRED = 599980,
  OVER_LAPING_DAYS_AVILABOL = 500101,
  WRONG_FORMAT
}

export const SERVER_ERROR_MESSAGES = {
  500039: 'Error: Your Google account is not opened',
  500041: 'Error: Your Google Release Manager is not set up',
  500042: 'Error: Your app\'s name is missing',
  500043: 'Error: Your app\'s Json data is missing',
  500044: 'Please complete your App Store configuration in the Store Publishing section, click Setup, and enter the information as requested.',
  500045: `Error: You didn't add "publishing@nandbox.com" as your App Manager on your Apple Developer Account.`,
  500046: 'Error: The App Store Name is not added to your Store Publishing Info.',
  500047: 'Error: Google Package ID is missing in your Store Publishing Info. Make sure it has the format "com.nandbox.yourCompanyName".',
  500048: 'Error: Apple Bundle ID is missing in your Store Publishing Info. Make sure it has the format "com.nandbox.yourCompanyName".',
  500049: 'Error: The Team Name of your Apple Developer Account is required in your Store Publishing Info.',
  500050: 'Error: Invalid Json format',
  500052: `Error: You didn't confirm adding "publishing@nandbox.com" as your App Manager. Check your inbox to find the confirmation request sent by Apple.`,
  500053: `Error: You didn't add your Apple App Specific Password in your Store Publishing Info.`,
  500056: 'Error: The format of your Google Package ID is invalid. It should be as "com.nandbox.yourCompanyName".',
  500057: 'Error: The format of your Apple Bundle ID is invalid. It should be as "com.nandbox.yourCompanyName".',
  500066: 'Error: The password of your Apple Developer Account is missing.',
  500069: 'It is not allowed to add bundles or products with different currency',
  500073: 'Error: The Apple Push Notification Key is missing.',
  500074: 'Error: The username of your Apple Developer Account is missing.',
  500075: 'Error: Android Format has wrong value',
  500076: 'Error: App Name is already used before',
  500090: `Example doesn't match the Pattern`,
  500089: 'Invalid pattern',
  500099: 'Current Password wrong',
  510019: 'Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )',
  510023: `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`,
  510024: `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`,
  510025: `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`,
  510026: `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`,
  510027: `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`,
  510028: `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`,
  510029: `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`,
  510030: `This password was used before in your password history`,
  510031: 'Passwords are not the same',
  500105: 'Cannot cancel - booking not found',
  500108: 'Cannot cancel - Cancel expired.',
  500109: 'Cannot cancel - Cancel is not allowed for this booking',
  500115: 'Cannot cancel - booking start time passed',
  160014: 'The bot username should be unique, please choose another name and try again',
  100012: 'Cannot delete while having more than one admin',
  500096: 'Trip notifier is already exist',
  500119: "Product add-on can't be linked to a product that is owned by another sub-channel, please link it to a product that is owned by the same channel.",
  100007: 'The number you entered is invalid. Please check and re-enter the number.',
  199997: 'Your region is not currently supported but it will be soon! Please check with us later.',
  10002: 'You have been banned. If you think this is a mistake, please contact the app administrator.',
  188888: 'You have been banned. If you think this is a mistake, please contact the app administrator.',
  188889: 'Sorry, you are not allowed to sign up for this app. Please contact the administrator.',
  188890: 'Too many attempts, Please try again later.',
  500121: 'Authentication failed, please fix configuration and try again.',
  500120: 'Please save your configuration before testing.',
  500014: 'Incorrect or missing data, please make sure you entered the correct the configuration.',
  100020: 'The number you entered is invalid. Please check and re-enter the number.',
  100006: '',
  505050: 'Maximum build has been reached',
  599980: 'Build Faild -Subscription is expired.',
  500101: 'Conflicting time slots. Please adjust your booking times to avoid overlap.'
};
