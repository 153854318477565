import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { c1, c16, c2, c7, MobileView, TabNames } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab, colorSchema } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-brand-bar",
  templateUrl: "./brand-bar.component.html",
  styleUrls: ["./brand-bar.component.scss"],
})
export class BrandBarComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    public indexDBService: IndexDBService
  ) { }

  @Input() data: Tab;
  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  @Input() tabTheme: boolean = false;
  @Input() inputTab: Tab;


  updateBuilderComponentSubscribtion: any;

  // default values
  colors: any = {};
  app_info: any = {};
  bg_color: string;
  text_color: string;
  burger_icon_color: string;
  app_name_color: string;
  other_text_color: string;
  channel_name: string;
  isAndroid: boolean;
  c1_android =c1.ANDROID;
  c1_ios =c1.IOS;
  theme: any;
  isLarge: string;
  c7_Large: string = c7.LARGE;
  currentTabSubscription: any;
  colorsComponentSubscription: any;
  sortedTabList: any =[];
  tabList: any;
  cat: string;
  current_tab_id: any;
  makeItSmaller: boolean;
  title: string;
  isComponent: boolean;
  active_title_color: string;
  default_color: string;
  active_icon_color: string;
  isback: boolean;
  color_schema: any;
  color_schema_ios: any;
  light: boolean;
  labelColor: string;
  icon: string = "dripicons dripicons-menu";
  trailing_icon1: string= "fas fa-search" ;
  trailing_icon2: string= "fas fa-bell";
  trailing_icon3: string= "fas fa-ellipsis-v";
  trailing_icon4: string= null;
  trailing_icon5: string=null;
  trailing_icon6: string =null
  current_tab: Tab;
  mobileView = MobileView;
  name: string;
  image: string;
  col: string ="col-7"

  ngOnInit() {
    this.current_tab=this.data;
    this.light  = localStorage.getItem("mode")[15] ==c16.LIGHT? true: false
    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if(data){
          try {
            this.theme = this.tabTheme?this.mdw.tabMobileTheme(this.inputTab):this.mdw.mobileTheme(data.tab);
            this.brandBarInitialize()
          } catch (error) {
            if (localStorage.getItem("ndbx")) {
              setTimeout(async () => {
                this.brandBarInitialize()
              }, 2000);
            } else {
              this.brandBarInitialize()
            }
          }
        }


      });
      this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
        (data) => {
          if (data  && data.color_schema  && data.color_schema_ios && data.light !=null)  {
           this.colors = data;
            this.light = data.light
            this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
            this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
            this.colorInitialiaze()
            this.brandBarInitialize();

           }
        })

      this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
        (data) => {
          if (data && data["current_tab"]) {
            this.data = data;
            if (data["current_tab"].id!=="0"){
              this.title = data ["current_tab"].title;
              this.current_tab= data ["current_tab"];
            }else {
              this.title = ""
            }
            this.isLarge = localStorage.getItem("mode")[6];
          }
        })
  }
  brandBarInitialize() {
     const appHome = this.indexDBService.getItem("app", "home");
     appHome.onsuccess = (event) => {
       if (appHome.result) {
         this.colors.color_schema = appHome.result.app.tabs.color_schema;
         this.colors.color_schema_ios = appHome.result.app.tabs.color_schema_ios;
         this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
         this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
         this.app_name_color = this.theme?.view != this.mobileView.a_component? this.color_schema.m2Primary : this.color_schema.m2OnSurface;
         const appInfo = this.indexDBService.getItem("item", "app_info");
         appInfo.onsuccess = (event) => {
           if (appInfo.result) {
             this.app_info = appInfo.result;
             this.channel_name = appInfo.result.channel_name;

             if (this.inputTab && this.inputTab.module){
              if (this.theme?.view != this.mobileView.a_component){
                switch (this.inputTab.module){
                  case TabNames.SUB_MESSENGER_SELECT_MESSAGE:
                    this.icon= "bx bx-left-arrow-alt";
                    this.image =null;
                    this.col ="col-4";
                    this.name ="1";
                    this.trailing_icon1= "mdi mdi-reply";
                    this.trailing_icon2= "mdi mdi-arrow-right-bold" ;
                    this.trailing_icon3= "bx bxs-trash-alt";
                    this.trailing_icon4= "bx bxs-pencil";
                    this.trailing_icon5= "bx bxs-share-alt";
                    this.trailing_icon6= "fas fa-ellipsis-v";
                  break;

                  case TabNames.SUB_MESSENGER_CHAT:
                  case TabNames.SUB_MESSENGER_CHAT:
                  case TabNames.SUB_MESSENGER_CHAT:
                  case TabNames.SUB_MESSENGER_CHAT:
                  case TabNames.SUB_MESSENGER_VOICE_MESSAGE_1:
                  case TabNames.SUB_MESSENGER_VOICE_MESSAGE_2:
                  case TabNames.SUB_MESSENGER_VOICE_MESSAGE_3:
                  case TabNames.SUB_MESSENGER_VOICE_MESSAGE_4:
                  case TabNames.SUB_MESSENGER_SEND_VIDEO:
                  case TabNames.SUB_MESSENGER_SEND_PHOTO:
                  case TabNames.SUB_MESSENGER_SEND_FILE:
                  case TabNames.SUB_MESSENGER_SEND_LOCATION:
                  case TabNames.SUB_MESSENGER_SELECT_MESSAGE:
                  case TabNames.SUB_MESSENGER_CONTACTS:
                  case TabNames.SUB_MESSENGER_CHAT_TEXTM:
                  case TabNames.SUB_MESSENGER_CHAT_MENU:
                  case TabNames.SUB_MESSENGER_AUDIO_FILE:
                  case TabNames.SUB_MESSENGER_ATTACH:
                  case TabNames.SUB_EMOJI:
                  case TabNames.SUB_COLORED_MESSAGE_CHAT:
                  case TabNames.SUB_MESSENGER_ATTACH:
                  case TabNames.SUB_EMOJI:
                    this.icon= "bx bx-left-arrow-alt";
                    this.image ="";
                    this.name ="Jone Trump"
                    this.col ="col-7"
                    this.trailing_icon1= "bx bxs-phone";
                    this.trailing_icon2= "bx bxs-video" ;
                    this.trailing_icon3= "fas fa-ellipsis-v";
                    this.trailing_icon4= null;
                    this.trailing_icon5= null;
                    this.trailing_icon6= null;
                  break;

                  default:
                    this.col = "col-7"
                    this.icon= "dripicons dripicons-menu";
                    this.image =null;
                    this.name = this.channel_name;
                    this.trailing_icon1= "fas fa-search" ;
                    this.trailing_icon2= "fas fa-bell";
                    this.trailing_icon3= "fas fa-ellipsis-v";
                    this.trailing_icon4= null;
                    this.trailing_icon5= null;
                    this.trailing_icon6= null;
                  break;

                }
             }
             }

         }

      }


         this.colorInitialiaze()
      }
     };

  }


  colorInitialiaze(){
         // c2: full, home, side, component
      // c7: ios home - standard and large title

      // this.light  = localStorage.getItem("mode")[15] ==c16.LIGHT? true: false
      this.isAndroid = localStorage.getItem("mode")[0]== c1.ANDROID? true: false;
      this.isLarge = localStorage.getItem("mode")[6];
      this.isComponent = localStorage.getItem("mode")[1]==c2.COMPONENT?true: false;
      this.theme = this.mdw.mobileTheme()
      this.isback = this.theme.backNavComponent

      if (this.isAndroid) {
        this.bg_color = this.color_schema.m2ASurface;
        this.app_name_color= this.app_name_color? this.app_name_color : this.color_schema.m2OnSurface;
        this.burger_icon_color = this.color_schema.m2OnSurface;
        this.other_text_color = this.color_schema.m2OnSurface;
        this.labelColor = null;
      } else {
        this.bg_color = this.color_schema_ios.surface;
        this.app_name_color= this.app_name_color? this.app_name_color : this.color_schema.onSurface;
        this.burger_icon_color = this.color_schema_ios.onSurface;
        this.other_text_color = this.color_schema_ios.onSurface;
        this.labelColor = this.color_schema_ios.onSurface;
      }
  }
  get bgColor() {
    if (this.bg_color) {
      return {
        background: this.bg_color,
      };
    }
  }
  get otherTextColor() {
    if (this.other_text_color) {
      return {
        color: this.other_text_color,
      };
    }
  }
  get appNameColor() {
    if (this.app_name_color) {
      return {color: this.app_name_color,};
    }
  }

  get TopBarTextColor() {
    if (this.labelColor) {
      return {
        color: this.labelColor,
      };
    }
  }

  get burgerColor() {
    if (this.burger_icon_color) {
      return {
        color: this.burger_icon_color,
      };
    }
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
