import { Tab } from '../../core/services/interface';
import { ModalModule } from "ngx-bootstrap/modal";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames, c1, common_components_map } from "src/app/core/services/constants";
import { MiddlwareService } from "src/app/core/services/middleware.service";

@Component({
  selector: "app-mobile-view-tab",
  templateUrl: "./mobile-view-tab.component.html",
  styleUrls: ["./mobile-view-tab.component.scss"],
})
export class MobileViewTabComponent implements OnInit {
  updateBuilderComponentSubscription: any;
  colorsComponentSubscription: any;
  tabNames = TabNames;
  mode;
  mobileViewModeMap;
  inputData;
  image: string;
  tab_image: string;
  start_color: string;
  end_color: string;
  bg_color: string;

  colors: any = {};
  color_schema: any;
  color_schema_ios: any;
  light: {};
  dark: {};
  isAndroid: string;


  @Input() inputTab: any;
  @Input() fontSmaller: boolean;
  @Input() enableStatic: boolean =false;
  @Input() enableForChat: boolean =false;
  @Input() inputMenu: any;
  fontResize= true;

  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
  this.image = "./assets/images/mobile-view-builder-item-empty-s.webp";

   this.mobileViewModeMap = common_components_map;
   this.colorInitialize();



  this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(async (data) => {
    if (data && data.color_schema  && data.color_schema_ios && data.light !=null) {
      this.colors = data;
      this.light = data.light
      this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
      this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
      try {
        await this.colorInitialize()
        this.initialize();
      } catch (error) {

      }

    }
  })

   this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe(async (data) => {
        if (data) {
          if (this.inputTab){
            this.inputData = this.inputTab;
          }else if (this.inputMenu){
          } else
          {
            this.inputData = data.tab;

          }
               this.image = "./assets/images/mobile-view-builder-item-empty-s.webp";
               await this.colorInitialize()
               this.initialize()
        }
      });
  }

  initialize(){

    this.isAndroid = localStorage.getItem("mode")[0];
            if (this.isAndroid === c1.ANDROID && this.color_schema) {
              this.bg_color = this.color_schema.m2CSurface;
            } else if (this.isAndroid === c1.IOS && this.color_schema_ios) {
              this.bg_color = this.color_schema_ios.surface;
            }
            this.tabBackground(this.inputTab)
          }

tabBackground(tab) {
            if (tab&& tab.tab_bg && tab.tab_bg.image) {
              this.tab_image = tab.tab_bg.image;
            } else if (tab&& tab.tab_bg) {
              this.start_color = tab.tab_bg.start
                ? tab.tab_bg.start
                : null;
              this.end_color = tab.tab_bg.end
                ? tab.tab_bg.end
                : null;

              if (!this.end_color && this.start_color) {
                this.bg_color = this.start_color;
              } else if (!this.start_color && this.end_color) {
                this.bg_color = this.end_color;
              }
            } else {
              this.tab_image = null;
              this.start_color = null;
              this.end_color = null;
            }
          }


async colorInitialize() {
  try {
    const appHome = this.indexDBService.getItem("app", 'home');
    appHome.onsuccess = async (event) => {
      if (appHome.result) {
        this.colors['color_schema'] = appHome.result.app.tabs.color_schema;
        this.colors['color_schema_ios'] = appHome.result.app.tabs.color_schema_ios;

      } }
  } catch (error) {

  }
   }

  get setTabBg() {
    if (this.tab_image) {
      return {
        "background-image": `url(${this.tab_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    } else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(90deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return { background: this.bg_color };
    }
    return { background: "unset" };
  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
