import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { MiddlwareService } from "./middleware.service";
import { Router } from "@angular/router";
import { WebsocketService } from "./websocket.service";
import { Subject } from "rxjs";
import { IndexDBService } from "./indexDB.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public _signupContainer = new Subject<any>();
  public signupContainer$ = this._signupContainer.asObservable();
  public _loginContainer = new Subject<any>();
  public loginContainer$ = this._loginContainer.asObservable();
  public _verifyContainer = new Subject<any>();
  public verifyContainer$ = this._verifyContainer.asObservable();
  public _resetVerifyContainer = new Subject<any>();
  public resetVerifyContainer$ = this._resetVerifyContainer.asObservable();
  public _forgotContainer = new Subject<any>();
  public forgotContainer$ = this._forgotContainer.asObservable();
  errorMap = {
    401: "Email or password incorrect",
    505: "Account already exists",
    504: "Code is invalid",

    510030: "Password has been used before",
    160011: "Code expired",
    500021: "Account already exists",
    160012: "Account already exists",
    100099: "Email or password incorrect",
    160013: "Account does not exist"
  };

  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    private router: Router,
    private indexDBService: IndexDBService
  ) {}

  async login(email: any, pass: any, google_token: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pass,
        google_token: google_token,
        method: "login",
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          // console.log(response);
          if (response.status === 401) {
            this._loginContainer.next({
              response: false,
              code: response.status,
            });
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data.token != null && data.email != null && data.id != null && !data.su) {
          // const db =  this.indexDBService.connect();
          // if(db) {
            this.ws.sendRequest(
              JSON.stringify({
                method: "TOKEN_AUTH",
                token: data.token,
                email: data.email,
                id: data.id,
                version: 2,
                rem: true
              })
            );
            this._loginContainer.next({ response: true });
          // }else{

          // }
        }
        if(data.error){
          this._loginContainer.next({
            response: false,
            code: data.error,
          });
        }
        if(data.su){
          this._loginContainer.next({
            response: false,
            su: data.su
          });
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  async suLogin(email: any, pass: any, su: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pass,
        su: su,
        method: "getSuToken",
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          // console.log(response);
          if (response.status === 401) {
            this._loginContainer.next({
              response: false,
              code: response.status,
            });
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data.token != null && data.email != null && data.id != null) {
          // const db =  this.indexDBService.connect();
          // this.indexDBService.connect();
          // if(db) {
            this.ws.sendRequest(
              JSON.stringify({
                method: "TOKEN_AUTH",
                token: data.token,
                email: data.email,
                id: data.id,
                version: 2,
                rem: true
              })
            );
            this._loginContainer.next({ response: true });
          // }else{

          // }
        }
        if(data.error){
          this._loginContainer.next({
            response: false,
            code: data.error,
          });
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  async signup(name: any, email: any, pass: any, google_token: any, cc: any) {
    if (cc == null) {
      cc = {
        name: "Unknown",
        alpha: "EG",
        code: "000",
      };
    }

    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pass,
        google_token: google_token,
        full_name: name,
        // 'p_code': cc.code,
        countryIso: cc.alpha,
        name:  "My App",
        method: "signup",
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          // console.log(response);
          if (response.status === 401) {
            // console.log("Signup Failed");
          }
          if (response.status === 505) {
            this._signupContainer.next({
              response: false,
              code: response.status,
            });
          }
        }
        return response.json();
      })
      .then((data) => {
        // console.log(JSON.stringify(data));
        if (data.status === "SUCCESS") {
          // this.indexDBService.connect();
          localStorage.setItem("email", data.email);
          localStorage.setItem("pass", pass);
          localStorage.setItem("name", name);
          localStorage.setItem("countryIso", cc.alpha);
          this.router.navigate(["/hub/verification"]);
          this._signupContainer.next({ response: true });
        } else if (
          data.token != null &&
          data.email != null &&
          data.id != null
        ) {
          localStorage.removeItem("pass");
          localStorage.removeItem("name");
          this.ws.sendRequest(
            JSON.stringify({
              method: "TOKEN_AUTH",
              token: data.token,
              email: data.email,
              id: data.id,
              version: 2,
              rem: true
            }
            )
          );
          this._signupContainer.next({ response: true });
        }
        if(data.error){
          this._signupContainer.next({
            response: false,
            code: data.error,
          });
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  async resend(email: any, countryIso: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        countryIso: countryIso,
        method: "emailVerify",
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.status);
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  async resendForgetPassword(email: any, countryIso: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        countryIso: countryIso,
        method: "getEmailTac",
        type: 1
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.status);
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  async email_verify(otp: any, email: any, pass: any, name: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tac: otp,
        email: email,
        password: pass,
        full_name: name,
        name:  "My App",
        method: "verify",
      }),
    });
    request
      .then((response: any) => {
        if (!response.ok) {
          if (response.status === 504) {
            this._verifyContainer.next({
              response: false,
              code: response.status,
            });
          }
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.token != null && data.email != null && data.id != null) {
          this.ws.sendRequest(JSON.stringify({ method: "TOKEN_AUTH", token: data.token, version: 2 }));
          this._verifyContainer.next({ response: true });
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  async send_email(email: any, country_iso: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        type: 1,
        method: "emailVerify",
        countryIso: country_iso
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          this._forgotContainer.next({response: false, code: response.status});
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.status);
        if (data.status === "SUCCESS") {
          localStorage.setItem("email", email);
          this._forgotContainer.next({response: true})
          this.router.navigate(["/hub/change-password"]);
        }
        if(data.error){
          this._forgotContainer.next({response: false, code: data.error});
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  async changePassword(email: any, pass: any, otp: any) {
    let request = fetch(environment.http_url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        newPassword: pass,
        tac: otp,
        method: "changePassword",
      }),
    });

    request
      .then((response: any) => {
        if (!response.ok) {
          if (response.status === 504) {
            this._resetVerifyContainer.next({
              response: false,
              code: response.status,
            });
          }
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.status);
        if (data.status === "SUCCESS") {
          this._resetVerifyContainer.next({ response: true });
          this.router.navigate(["/hub/login"]);
        }
        if(data.error){
          this._resetVerifyContainer.next({response: false, code: data.error});
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }
}
