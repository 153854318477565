<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-12">
        <label class="offcanvas-header-text w-100" style="text-align: left">
          Component Label
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="title"
            #tabTitle
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="title"
            (change)="titleChange(tabTitle?.value)"
          />
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-5">
        <label class="offcanvas-header-text w-100" style="text-align: left">
          Android Icon
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <button
            type="button"
            class="form-control input-lg offcanvas-primary border-0"
            (click)="openSelector(selectorAndroid)"
          >
            <i
              class="fs-4"
              [class]="
                icon?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ icon?.id }}</i
            >
          </button>
          <button
            type="button"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorAndroid)"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>

      <div class="col-5">
        <label class="offcanvas-header-text w-100" style="text-align: left">
          iOS Icon
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <button
            type="button"
            class="form-control input-lg offcanvas-primary border-0"
            (click)="openSelector(selectorIOS)"
          >
            <i
              class="fs-4"
              [class]="
                icon_ios?.type == 'mir'
                  ? 'material-icons-outlined'
                  : 'material-icons'
              "
              >{{ icon_ios?.id }}</i
            >
          </button>
          <button
            type="button"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorIOS)"
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="config?.setcolor">
      <div class="row mb-3">
        <label
          for="bg-color"
          class="offcanvas-header-text col-9 m-0 d-flex align-items-center"
          style="text-align: left"
        >
          Android Icon Color
        </label>
        <div
          *ngIf="!enableCustomColor"
          class="col-3 d-flex justify-content-end"
        >
          <btn class="btn btn-primary" (click)="toggle(true)">SET</btn>
        </div>
        <ng-container *ngIf="enableCustomColor">
          <div class="col-6 mt-2">
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="bg-color"
                class="form-control input-lg offcanvas-primary border-0"
                (change)="colorChange($event, true)"
                [value]="labelColor"
                [readOnly]="!enableCustomColor"
              />
              <span class="input-group-text p-1 border-0 offcanvas-primary">
                <span
                  class="input-group-text colorpicker-input-addon h-100"
                  [style.background]="labelColor"
                  [cpOutputFormat]="'hex'"
                  [(colorPicker)]="labelColor"
                  [cpPosition]="'auto'"
                  (colorPickerChange)="colorChange($event, false)"
                  [cpDisabled]="!enableCustomColor"
                >
                </span>
              </span>
            </div>
          </div>
          <div
            class="col-6 text-start mt-2 d-flex align-items-center justify-content-end"
          >
            <span
              class="offcanvas-danger-text enablePointer"
              (click)="toggle(false)"
            >
              Remove
            </span>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <label
          for="bg-color"
          class="offcanvas-header-text col-9 m-0 d-flex align-items-center"
          style="text-align: left"
        >
          IOS Icon Color
        </label>
        <div
          *ngIf="!enableCustomColorIOS"
          class="col-3 d-flex justify-content-end"
        >
          <btn class="btn btn-primary" (click)="toggleIOS(true)">SET</btn>
        </div>
        <ng-container *ngIf="enableCustomColorIOS">
          <div class="col-6 mt-2">
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="bg-color"
                class="form-control input-lg offcanvas-primary border-0"
                (change)="colorChangeIOS($event, true)"
                [value]="labelColorIOS"
                [readOnly]="!enableCustomColorIOS"
              />
              <span class="input-group-text p-1 border-0 offcanvas-primary">
                <span
                  class="input-group-text colorpicker-input-addon h-100"
                  [style.background]="labelColorIOS"
                  [cpOutputFormat]="'hex'"
                  [(colorPicker)]="labelColorIOS"
                  [cpPosition]="'auto'"
                  (colorPickerChange)="colorChangeIOS($event, false)"
                  [cpDisabled]="!enableCustomColorIOS"
                >
                </span>
              </span>
            </div>
          </div>
          <div
            class="col-6 text-start mt-2 d-flex align-items-center justify-content-end"
          >
            <span
              class="offcanvas-danger-text enablePointer"
              (click)="toggleIOS(false)"
            >
              Remove
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #selectorAndroid let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="selector">Choose Android Icon</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="modalRef?.hide()"
        ></button>
      </div>
      <div class="modal-body">
        <app-icons-selector
          (selectedIcon)="iconChange($event)"
        ></app-icons-selector>
      </div>
    </ng-template>
    <ng-template #selectorIOS let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="selector">Choose iOS Icon</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="modalRef?.hide()"
        ></button>
      </div>
      <div class="modal-body">
        <app-icons-selector
          (selectedIcon)="iosIconChange($event)"
        ></app-icons-selector>
      </div>
    </ng-template>
  </div>
</div>
