import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MobileView, TabNames, c16, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-common-tab-component",
  templateUrl: "./common-tab-component.component.html",
  styleUrls: ["./common-tab-component.component.scss"],
})
export class CommonTabComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    public indexDBService: IndexDBService,
  ) { }

  @Input() inputTab: Tab;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  @Input() noTabs;

  tab: Tab;
  // default values

  imageLg: string;
  imageSm: string;
  image: string =null;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  updateBuilderComponentSubscribtion: any;
  colorsComponentSubscription: any;
  getAppConfigSubscription: any;
  theme: any = {};

  aLong: string = "./assets/images/emptyImageL.webp";
  aShort: string = "./assets/images/emptyImageS.webp";
  iLong: string = "";
  iShort: string = "";

  afull: string = "";
  ifull: string = "";
  aShortWide: string = "./assets/images/emptyImageS.webp";

  view: string;
  colors: any = {};

  isAndroid: string;
  color_schema: any;
  color_schema_ios: any;
  light : boolean =true;
  isDB: boolean

  ngOnInit() {
    this.isDB=localStorage.getItem("ndbx")=="true"? true: false
    if (this.inputTab) {
      this.tab = { ...this.inputTab };
      if (this.tab) {
        this.viewTab(this.tab)
      }
    }

    this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(async (data) => {
      if (data && data.color_schema  && data.color_schema_ios && data.light !=null) {
        this.colors = data;
        this.light = data.light
        this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
        try {
          // await this.colorInitialize()
          this.viewTab(this.tab)
        } catch (error) {

        }

      }
    })

    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
       if (data) {
          if (this.inputTab) {
            this.tab = { ...this.inputTab };
          } else {
            this.tab = { ...data.tab };
          }
        this.viewTab(this.tab)


        }
      });
  }
  viewTab(tab) {
    switch (tab.type) {
      case TabNames.OPENCHAT:
      case TabNames.CHANNEL:
      case TabNames.OPENBOT:
      case TabNames.CALL_LOG:
      case TabNames.CHAT:
      case TabNames.VIDEOAUDIO:
      case TabNames.CONTACT:
      case TabNames.CHANNELLIST:
      case TabNames.ONLINEGROUP:
      case TabNames.MAP_SEARCH:
      case TabNames.BOOKINGLIST:
      case TabNames.CALENDAR:
      case TabNames.VALIDATE_TICKET:
      case TabNames.OPENCHAT:
      case TabNames.REDEEM_TICKET:
      case TabNames.QR_PAGE:
      case TabNames.GROUP:
      case TabNames.INVITATION:
      case TabNames.ONLINECHANNEL:
      case TabNames.OPENGROUP:
      case TabNames.SPEEDDIALER:
      case TabNames.EMPTY:
      case TabNames.MYORDERS:
      case TabNames.QR:
      case TabNames.SETTINGS:
      case TabNames.PAYMENT_VIEW:
      case TabNames.LOGIN_VIEW:


      case TabNames.SUB_MESSENGER_VOICE_MESSAGE_1:
        case TabNames.SUB_MESSENGER_VOICE_MESSAGE_2:
        case TabNames.SUB_MESSENGER_VOICE_MESSAGE_3:
        case TabNames.SUB_MESSENGER_VOICE_MESSAGE_4:
        case TabNames.SUB_MESSENGER_VIDEO_CALL:
        case TabNames.SUB_MESSENGER_USER_PROFILE:
        case TabNames.SUB_MESSENGER_SEND_VIDEO:
        case TabNames.SUB_MESSENGER_SEND_PHOTO:
        case TabNames.SUB_MESSENGER_SEND_FILE:
        case TabNames.SUB_MESSENGER_SEARCH:
        case TabNames.SUB_MESSENGER_REPLY_MESSAGE:
        case TabNames.SUB_MESSENGER_MULTI_PROFILE:
        case TabNames.SUB_MESSENGER_MEDIA_FILE:
        case TabNames.SUB_MESSENGER_GALLERY:
        case TabNames.SUB_MESSENGER_DELETE_MESSAGE:
        case TabNames.SUB_MESSENGER_CONTACTS:
        case TabNames.SUB_MESSENGER_CHAT_MENU:
        case TabNames.SUB_MESSENGER_CHAT_TEXTM:
        case TabNames.SUB_MESSENGER_CHAT:
        case TabNames.SUB_MESSENGER_AUDIO_FILE:
        case TabNames.SUB_MESSENGER_AUDIO_CALL:
        case TabNames.SUB_MESSENGER_ATTACH:
        case TabNames.SUB_EMOJI:
        case TabNames.SUB_COLORED_MESSAGE_CHAT:
        case TabNames.SUB_CHANNELS_FORWARD_MESSAGE:
        case TabNames.SUB_MESSENGER_SEND_LOCATION:
        case TabNames.SUB_MESSENGER_SELECT_MESSAGE:


        this.theme = this.mdw.mobileTheme(tab)
        this.image = this.theme.image;
        this.roundedBottom = this.theme.roundedBottom;
        break;
    }

  }

  componentInitliaze(data?){
    if (data ==null){
      if (this.isDB){
        const appHome = this.indexDBService.getItem("app", 'home');
        appHome.onsuccess = async (event) => {
          if (appHome.result) {
            this.colors.color_schema = appHome.result.app.tabs.color_schema;
            this.colors.color_schema_ios = appHome.result.app.tabs.color_schema_ios;
            this.light  = localStorage.getItem("mode")[15] ==c16.LIGHT? true: false
            this.colors['light'] = this.light;
            this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
            this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark

          }
        }
      }

    }
  }


  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
