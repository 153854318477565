<div class="offcanvase-side">
  <div
    class="offcanvas offcanvas-end navbar-brand-box p-0 z-1"
    style="
      top: 70px;
      width: 400px !important;
      visibility: visible !important;
      height: calc(100vh - 70px);
    "
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    id="offcanvasScrollingValidationConf"
    aria-labelledby="offcanvasScrollingLabel"
  >
    <div class="offcanvas-header ps-0 mb-0 offcanvas-divider">
      <div id="sidebar-menu" class="p-0 offcanvas-header">
        <ul class="metismenu list-unstyled">
          <li class="no-line" draggable="false">
            <a class="side-nav-link-ref">
              <h5 class="mb-0">{{ title }}</h5>
            </a>
          </li>
        </ul>
      </div>
      <div
        class="text-reset enablePointer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        (click)="hideOffcanvas()"
      >
        <i class="mdi mdi-close font-size-22 icon-close"></i>
      </div>
    </div>
    <div class="offcanvas-menu p-0 m-0 offcanvas-scrollbar flex-grow-1 overflow-x-hidden">
      <div id="sidebar-menu" class="offcanvas-menu p-0">
        <!-- <ul class="metismenu list-unstyled">
          <li class="no-line" draggable="false">
            <a class="side-nav-link-ref text-start font-size-12 ps-3">
              <span>There are some incomplete steps that you need to complete before you can build your own app.</span>
            </a>
          </li>
        </ul> -->
        <ul class="metismenu list-unstyled offcanvas-list-unstyled pb-1 pe-3">
          <ng-container *ngFor="let item of validationItems; let i = index">
            <li class="rounded-3 m-2 offcanvas-list d-flex align-items-center" draggable="false">
              <a class="rounded-circle primary-button py-2 px-2 font-size-12 d-flex align-items-center justify-content-center ms-2 me-3" style="width: 20px; height:20px;">{{i+1}}</a>
              <a class="is-parent offcanvas-parent px-3 py-2 rounded-3 flex-grow-1" draggable="false">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center justify-content-start">
                    <i class="flex-shrink-0 mb-auto offcanvas-icon rounded-3 py-2 px-2 font-size-26 material-icons">{{item?.icon}}</i>
                    <div class="d-flex flex-column align-items-start">
                      <span class="font-size-12 ms-3" [ngClass]="item?.errorCode != 0 ? 'mb-1' : ''">{{ item?.name }}</span>
                      <span *ngIf="item?.errorCode != 0" class="font-size-10 ms-3 text-start">{{ item?.error }}, <span class="font-size-10 primary-text enablePointer" (click)="goToUrl(item?.link)">Click here</span></span>
                    </div>
                  </div>
                  <i class="material-icons mb-auto mt-2 font-size-22" [ngClass]="getStatusColor(item?.errorCode, item?.type)">{{getStatusIcon(item?.errorCode, item?.type)}}</i>
                </div>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
