import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-tabsplash-term",
  templateUrl: "./button-tabsplash-term.component.html",
  styleUrls: ["./button-tabsplash-term.component.scss"],
})
export class ButtonTabSplashTermComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) {}

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() tabData: Tab;
  @Input() config: any;

  imageConfig: any = {};
  button: MenuButton;
  image: string;
  //
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  tab: Tab;
  textColor: string;
  linkColor: string;
  enableBg: boolean;
  bgColor: string;
  terms_url: string;
  termForm: UntypedFormGroup;
  regURL =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  ngOnInit() {
    this.enableBg = this.config.bg ? true : false;

    this.field_id = "tabsplashtnc";
    this.sortedButtonList = [];
    this.buttonList = {};
    this.termForm = this.formBuilder.group({
      terms_url: new UntypedFormControl('', [Validators.pattern(this.regURL)]),
      policy_url: new UntypedFormControl('', [Validators.pattern(this.regURL)]),
    })

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data);
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          let tab = data["splash_tab"];
          this.buttonFunction(tab);
        }
      }
    );
  }

  intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.tabData);
  }

  buttonFunction(tabData) {
    if (tabData && tabData.param && tabData.param.splash) {
      this.tab = tabData;

      this.termForm = this.formBuilder.group({
        terms_url: new UntypedFormControl(this.tab.param.splash.terms_url ? this.tab.param.splash.terms_url : '', [Validators.pattern(this.regURL)]),
        policy_url: new UntypedFormControl(this.tab.param.splash.policy_url ? this.tab.param.splash.policy_url : '', [Validators.pattern(this.regURL)]),
      })
    }
  }

  buttonBgColor(item, value, manual) {
    let color = null;
    color =
      manual == true
        ? this.mdw.isValidHexaCode(value.target.value)
          ? value.target.value
          : null
        : value;

    if (color) {
      if (item === "text") {
        this.textColor = color;
        this.tab.param.splash.tnc_text_color = color;
      }
      if (item === "link") {
        this.linkColor = color;
        this.tab.param.splash.tnc_link_color = color;
      }

      if (item === "background") {
        this.bgColor = color;
        this.tab.param.splash.tnc_bgcolor = color;
      }

      this.updateButtonData(this.tab);
    }
  }

  termsURLChange() {
    this.tab.param.splash.terms_url = this.termForm.value.terms_url;
    this.updateButtonData(this.tab);
  }

  policyURLChange() {
    this.tab.param.splash.policy_url = this.termForm.value.policy_url;
    this.updateButtonData(this.tab);
  }

  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
      return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }

  updateButtonData(tab?) {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;
    this.data["splash_tab"] = this.tab;

    this.mdw._currentMenuContainer.next(this.data);

    if (tab) {
      this.mdw.changeTab(
        { button: this.button, tab: this.tab },
        this.mode,
        false
      );
    } else {
      this.mdw.changeTab(
        { button: this.button, tab: { module: "menu" } },
        this.mode,
        true
      );
    }
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
