import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-onClick-settings",
  templateUrl: "./onClick-settings.component.html",
  styleUrls: ["./onClick-settings.component.scss"],
})
export class OnClickSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService,
    private router: Router
  ) {}

  labelform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() config: any;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_text: string;
  row_id: string;
  labelColor: string;
  black_color: string = "#000000";
  enableCustomColor: boolean = false;

  button_label_color: string;
  button_label_style: any;
  button_button_divider: number;

  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  modalRef?: BsModalRef;
  icon: any;
  trailing_icon: any;
  iconType: number;
  fontSize = "md";
  textAlign = "left";
  isAndroid: boolean;
  light: boolean;
  text_id: any;
  colors: any;
  labelDarkColor: any;
  button_color_item: any = { schemes: { light: {}, dark: {} } };
  button_color_light: any = {};
  button_color_dark: any = {};
  sync = true;
  os_name: string;
  tab;

  ngOnInit() {
    this.field_id = "label";
    this.labelform = this.formBuilder.group({
      newPage: new UntypedFormControl(true),
      buttondb: new UntypedFormControl(true),
    });

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.icon;
          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
    if (this.button) {
      this.text_id = this.config.id.split(/_(.*)/s)[1];
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.os_name = this.sync ? null : this.isAndroid ? "Android" : "iOS";
      this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;

      this.labelform = this.formBuilder.group({
        newPage: new UntypedFormControl(this.button.button_newpage),
        buttondb: new UntypedFormControl(this.button.button_db),
      });
    }
  }

  get f() {
    if (this.labelform && this.labelform.controls) {
      return this.labelform.controls;
    }
  }

  updateNewPage() {
    const value = this.labelform.value.newPage;
    this.button.button_newpage = value;
    this.updateButtonData();
  }

  updatebuttondb() {
    const value = this.labelform.value.buttondb;
    this.button.button_db = Number(value);
    this.updateButtonData();
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
