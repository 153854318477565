import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-video-component",
  templateUrl: "./tab-video-component.component.html",
  styleUrls: ["./tab-video-component.component.scss"],
})
export class TabVideoComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  currentTabSubscription: any;
  colorsComponentSubscription: any;

  off_canvas_key: string = "tab";
  isAndroid: boolean;
  data: any;
  tabList = {};
  sortedTabList = [];
  current_tab_id;
  component_id: string;
  field_id: string;
  enableCustomColor = false;
  enableCustomColorIOS = false;
  sync;
  labelColor;
  labelDarkColor;
  labelColorIOS;
  labelDarkColorIOS;
  light: boolean = true;
  colors: any = {};
  color_schema: any;
  color_schema_ios: any;

  ngOnInit() {
    this.field_id = "tab_viedo_url";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.currentTab &&
          this.tab_id &&
          data.currentTab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key &&
          data.off_canvas_key === "tab" &&
          data.tab &&
          data.tab.id &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;

      if (this.tab.video_url) {
        this.videoUrl = this.tab.video_url;
      }
      if (this.tab.desc) {
        this.videoDesc = this.tab.desc;
      }
      if (this.tab.text_color) {
        this.textColor = this.tab.text_color;
      }
      if (this.tab.bg_color) {
        this.bgColor = this.tab.bg_color;
      }
    }
  }

  colorTextPickerChange(event) {
    this.tab.text_color = event;
    this.updateTabData();
  }

  colorBgPickerChange(event) {
    this.tab.bg_color = event;
    this.updateTabData();
  }

  videoUrlChange(event) {
    this.tab.video_url = event;
    this.updateTabData();
  }
  videoDescChange(event) {
    this.tab.desc = event;
    this.updateTabData();
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data["target"] = "tabSetting";

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
