<div class="p-0 w-100 d-flex align-items-center justify-content-center">
  <div class="card p-0 m-0 position-relative shadow-none w-100" style="max-width: 560px">
    <img [src]="image" class="card-img" alt="empty">
    <div class="card-img-overlay p-0" [ngClass]="fontSmaller?'':'rounded-3'">
      <div class="position-absolute start-0 end-0">

        <div class="row m-0 bg-white">
          <div class="row m-0 p-2">
            <div class="col-10 p-0 chat-user-box">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 me-2">
                  <img
                    class="rounded-circle"
                    [src]="channel_image"
                    alt="Generic placeholder image"
                    height="36"
                    width="36"
                    onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
                  />
                </div>
                <div class="flex-grow-1 chat-user-box">
                  <p class="user-title m-0">{{ channel_name }}</p>
                  <p class="text-muted m-0">{{ currentDate | date }}</p>
                </div>
              </div>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
              <i class="mdi mdi-dots-vertical font-size-14"></i>
            </div>
          </div>
          <img class="img-fluid p-0" src="./assets/images/post-1.webp" />

          <div
            class="row m-0 p-0 d-flex align-items-center mt-1 border-bottom border-2 border-secondary border-opacity-50"
            *ngIf="tab?.footer?.action?.length > 0"
          >
            <div class="col px-1 py-2" *ngIf="like">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="font-size-14 me-1"
                  [class]="
                    like_color?.type == 'mir'
                      ? 'material-icons-outlined'
                      : 'material-icons'
                  "
                  [class.font-smaller]="fontSmaller"
                  [ngStyle]="likeColor"
                >
                  {{ like_color?.icon }}
                </i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">29k</div>
              </div>
            </div>
            <div class="col px-1 py-2" *ngIf="reply">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="font-size-14 me-1"
                  [class.font-smaller]="fontSmaller"
                  [class]="reply?.type == 'mir' ? 'material-icons-outlined' : 'material-icons'
                  "
                >
                  {{ reply?.icon }}
                </i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">853</div>
              </div>
            </div>
            <div class="col px-1 py-2" *ngIf="view">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="font-size-14 me-1"
                  [class.font-smaller]="fontSmaller"
                  [class]="
                    view?.type == 'mir' ? 'material-icons-outlined' : 'material-icons'
                  "
                >
                  {{ view?.icon }}
                </i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">42k</div>
              </div>
            </div>
            <div class="col px-1 py-2" *ngIf="share">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="font-size-14 me-1"
                  [class.font-smaller]="fontSmaller"
                  [class]="
                    share?.type == 'mir' ? 'material-icons-outlined' : 'material-icons'
                  "
                >
                  {{ share?.icon }}
                </i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">124</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 position-relative bg-white">
          <div class="row m-0 p-2">
            <div class="col-10 p-0 chat-user-box">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 me-2">
                  <img
                    class="rounded-circle"
                    [src]="channel_image"
                    alt="Generic placeholder image"
                    height="36"
                    width="36"
                    onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
                  />
                </div>
                <div class="flex-grow-1 chat-user-box">
                  <p class="user-title m-0">{{ channel_name }}</p>
                  <p class="text-muted m-0">{{ currentDate | date }}</p>
                </div>
              </div>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
              <i class="mdi mdi-dots-vertical font-size-14"></i>
            </div>
          </div>
          <img class="img-fluid p-0" src="./assets/images/post-2.webp" />
          <div
            class="row m-0 p-0 d-flex align-items-center mt-1 border-bottom border-2 border-secondary border-opacity-50"
            *ngIf="tab?.footer?.action?.length > 0"
          >
            <div class="col px-1 py-2" *ngIf="like">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class=" {{ like?.icon }}  font-size-14 me-1"
                  [class.font-smaller]="fontSmaller"
                ></i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">29k</div>
              </div>
            </div>
            <div class="col px-1 py-2" *ngIf="reply">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class=" {{ reply?.icon }}  font-size-14 me-1"
                  [class.font-smaller]="fontSmaller"
                ></i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">853</div>
              </div>
            </div>
            <div class="col px-1 py-2" *ngIf="view">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class=" {{ view?.icon }}  font-size-14 me-1"
                  [class.font-smaller]="fontSmaller"
                ></i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">42k</div>
              </div>
            </div>
            <div class="col px-1 py-2" *ngIf="share">
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class=" {{ share?.icon }}  font-size-14 me-1"
                  [class.font-smaller]="fontSmaller"
                ></i>
                <div class="fs-6" [class.font-smaller]="fontSmaller">124</div>
              </div>
            </div>
          </div>
          <div
            *ngIf="talk_to_admin"
            class="position-absolute rounded-circle bottom-0 end-0 m-3 d-flex align-items-center justify-content-center"
            [ngStyle]="talkAdminColor"
          >
            <i
              class="bx bxs-comment-detail offcanvas-header-text"
              [class.fs-2]="talk_admin.size === 'l'"
              [class.fs-3]="talk_admin.size === 'm'"
              [class.fs-4]="talk_admin.size === 's'"
              id="float-icon"
            ></i>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>






