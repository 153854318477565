import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Route, Router, RouterModule } from "@angular/router";
import { TabsetComponent, TabDirective } from "ngx-bootstrap/tabs";
import {
  LOGIN_TYPES,
  PaymentProviderIDs,
  TabNames,
  c1,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-offcanvas-validation-list",
  templateUrl: "./offcanvas-validation-list.component.html",
  styleUrls: ["./offcanvas-validation-list.component.scss"],
})
export class OffcanvasValidationListComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private ref: ChangeDetectorRef,
    private builderService: BuilderService,
    private router: Router
  ) {}

  offcanvas_data: any = [];
  offCanvasSubscription: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "gen";

  page_id: string;
  title: string;
  hideoffcanvas: boolean;
  current_tab: string;
  active_tabset_index: number = 0;
  tab: Tab;
  updateBuilderComponentSubscription: any;
  data: any;
  login_types = LOGIN_TYPES;
  paymentProviderIDs = PaymentProviderIDs;
  validationItems = [];

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        this.data = data;
        this.OffCanvasData(data);
      }
    );

    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data &&  data.offcanvasRefersh && data.tab) {
          // offcanvasRefresh prevents flickering and prevent the component (offcanvas) to update itself and then components flikers.
          // only update from outside component can send updated data to the offcanvas.
          this.OffCanvasData(this.data);
        }
      });
  }

  OffCanvasData(data){
    if (data) {
      if (data.off_canvas_key === "validation") {
        this.page_id = data.page.id;
        this.current_tab = data.tab;
        this.title = data.page.title;
        this.validationItems = data.validationList;
        this.hideoffcanvas = true;
        // display components

      if (this.hideoffcanvas && data.show) {
          this.showOffcanvas();
          this.hideoffcanvas = false;
        } else {
          this.hideOffcanvas();
        }
        this.off_canvas_key = data.off_canvas_key;
      } else {
        this.hideOffcanvas();
      }
    }
  }

  activeTabSetting(pageId, tabIndex) {
    if (pageId === this.page_id) {
      if (tabIndex === this.active_tabset_index) {
        return true;
      } else return false;
    } else return false;
  }

  tabSetChange(event) {
    switch (this.page_id) {
      case "nav_tab":
      case "nav_side":
        this.setMobileThemeC1(event);

        break;
    }
  }

  setMobileThemeC1(event) {
    let modes: any;
    if (event.id !== localStorage.getItem("mode")[0]) {

      if (event.id == c1.IOS) {
        modes = [{ id: c1.ID, value: c1.IOS }];
      } else {
        modes = [{ id: c1.ID, value: c1.ANDROID }];
      }
      this.mdw.changeMobileTheme(modes);
      let data = { ...{ tab: this.tab } };

      this.mdw._updateBuilderComponentContainer.next(data);
    }
  }

  hideOffcanvas() {
    document
      .querySelector("#offcanvasScrollingValidationConf")
      .classList.remove("show");
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrollingValidationConf").classList.add("show");
  }

  getStatusIcon(code, type){
    if(code != 0){
      switch(type){
        case "error":
          return "cancel"
        case "warning":
          return "error"
      }
    } else {
      return "check_circle"
    }

  }

  getStatusColor(code, type){
    if(code != 0){
      switch(type){
        case "error":
          return "text-danger"
        case "warning":
          return "text-warning"
      }
    } else {
      return "text-success"
    }
  }

  goToUrl(url){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
    });
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
  }
}
