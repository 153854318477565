<div *ngIf="!isAndroid">
  <div class="p-0 w-100 d-flex align-items-center justify-content-center rounded-0 overflow-hidden">
    <div class="card p-0 m-0 position-relative shadow-none w-100 rounded-0" style="max-width: 260px">
      <img [src]="theme?.emptyImageNavDrawer"  class="card-img" alt="empty">
      <div class="card-img-overlay p-0" [ngStyle]="setButtonBg"  [ngClass]="fontSmaller?'':'rounded-0'">
        <div class="position-absolute start-0 end-0">

      <div *ngIf="albums && albums[0]?.en" class="card m-0 -p0 shadow-none rounded-3 m-2 overflow-hidden"
        style="background-color: rgba(0,0,0,{{albums[0].opacity}}); max-width: 260px; backdrop-filter: blur({{albums[0].blur}})">
        <img [src]="emptyImageP0" alt="empty" class="card-img" height="64">
        <div class="card-img-overlay border overflow-hidden m-0 p-0" [ngStyle]="setTopPartBg" [ngClass]="debug? 'border-1':'border-0'">
          <ng-template [ngTemplateOutlet]="part_0"></ng-template>
        </div>
      </div>

    <div *ngIf="albums && albums[1]?.en" class="card m-0 -p0 shadow-none rounded-0"
      style="background-color: rgba(0,0,0,{{albums[1].opacity}}); max-width: 260px; backdrop-filter: blur({{albums[1].blur}})">
      <img [src]="emptyImageP1" alt="empty" class="card-img">
      <div class="card-img-overlay border overflow-hidden m-0 p-0" [ngStyle]="setTopPartBg" [ngClass]="debug? 'border-1':'border-0'">
        <ng-template [ngTemplateOutlet]="part_4"></ng-template>
      </div>
    </div>

    <div *ngIf="albums && albums[2]?.en" class="card m-0 p-0 shadow-none" ngClass="{{albums[2].round}}"
          style="background-color: rgba(0,0,0,{{albums[2].opacity}}); max-width: 260px ; backdrop-filter: blur({{albums[2].blur}})"
          [ngStyle]="setTitleBg">
          <img [src]="emptyImageP2" alt="empty" class="card-img">
          <div class=" card-img-overlay border overflow-hidden m-0 p-0" [ngClass]="debug? 'border-1':'border-0'">
            <ng-template [ngTemplateOutlet]="part_2"></ng-template>
          </div>
       </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #part_0>

  <div class="card border-0 shadow" [ngStyle]="{'background-color': card_bg_color}">
    <div class="card-body rounded-3 border-5 p-2">
      <div class="row m-0 p-0 d-flex align-items-center h-100 ">
        <!-- Avatar -->
        <div class="col-auto p-0">
          <img
            [src]="image"
            class="avatar-sm rounded-circle"
            *ngIf="image"
            alt="Profile Avatar">
        </div>
        <!-- Text (Profile Name and Sublabel) -->
        <div class="col p-0 m-0 ps-2">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex flex-column">
              <!-- Profile Name -->
              <p
                class="card-title fw-bold p-0 m-0"
                [ngStyle]="labelColor">
                {{ label }}
              </p>
              <!-- Sublabel -->
              <p
                class="text-wrap text-muted p-0 m-0"
                [ngStyle]="sublabelColor">
                {{ sublabel }}
              </p>
            </div>
            <div>
              <i class="bx bx-chevron-right arrow-icon font-size-20" [ngStyle]="{color: arrow_color}"></i> <!-- Bootstrap Icons -->
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</ng-template>

<ng-template #part_2>
        <div class="card rounded-3 m-2 overflow-hidden"[ngStyle]="{'background-color':card_bg_color}">
        <div class="card-body p-0">
          <div class="row m-0" *ngFor="let tab of sortedTabList ;let last = last; let index = index; trackBy: tabTrackBy">
            <div class="col-2 p-0 d-flex align-items-center justify-content-center">
              <i
                class="font-size-20"
                *ngIf="tab?.icon"
                [class]="
                  builderService?.getIcon(tab?.icon).type == 'mir'
                    ? 'material-icons-outlined'
                    : 'material-icons'
                "
                [ngStyle]="iconStyleIOS(tab)"
              >
                {{ builderService?.getIcon(tab?.icon).id }}
              </i>
            </div>
            <div class="col-10 p-0 py-2 pe-2 d-flex align-items-center justify-content-between" [ngStyle]="{'border-bottom': !last ? '1px solid' + '  ' +  seperator_color: 'unset'}">
              <div
                class="text-truncate font-size-12"
                style="max-width: 90%;"
                [ngStyle]="{color: titleColor}"
              >
                {{ tab?.title }}
              </div>
              <i class="bx bx-chevron-right arrow-icon font-size-20" [ngStyle]="{color: arrow_color}"></i>
            </div>

          </div>
        </div>
      </div>
    <!-- </ng-container> -->

</ng-template>
<ng-template #part_4>
</ng-template>

