<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form [formGroup]="urlForm">
      <div
        class="row d-flex align-items-center justify-content-between align-items-center mb-3"
      >
        <div class="col-12">
          <label
            for="text-color"
            class="text-white w-100"
            style="text-align: left"
          >
            {{ "GENERALS.WEBURL" | translate }}
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
            [ngClass]="{
              'is-invalid': submitted && f.url.errors
            }"
          >
            <input
              type="text"
              id="url"
              #url
              class="form-control input-lg offcanvas-primary border-0"
              formControlName="url"
              (change)="urlChange(url?.value)"
            />
          </div>
          <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
            <div *ngIf="f.url.errors.pattern">
              {{ "GENERALS.PLEASEENTERVALIDURL" | translate }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
