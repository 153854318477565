import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MENU_BUTTON_CODE, MENU_BUTTON_FORM, TabNames, collection_composed_images, menu_button_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";
@Component({
  selector: "app-offcanvas-button-drag",
  templateUrl: "./offcanvas-button-drag.component.html",
  styleUrls: ["./offcanvas-button-drag.component.scss"],
})
export class OffcanvasButtonDragComponent
  implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) { }
  ngAfterViewInit(): void { }
  @Input() data: Tab;
  @Input() mode: string;
  @Input() offcanvas_data: any[] = [];

  offCanvasSubscription: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "home";
  selectedElement: any;
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (data && data.target == 'drag') {
          switch (data.off_canvas_key) {
            case "menu":
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "Components",
                      show: true,
                      name: "Headline",
                      icon: "title",
                      icon_color: "#349fff",
                      item: this.singleButton("bar_01", "bar", "1004"),
                    },
                    {
                      cat: "Components",
                      show: false,
                      list: [
                        {
                          id: "Cards",
                          name: "Cards",
                          icon: "space_dashboard",
                          icon_color: "#52ef7a",
                          options: [
                            this.singleButton("card_06", "card", "0603",),
                            this.singleButton("card_03", "card", "0303",),
                            this.singleButton("card_05", "card", "0503",),
                            this.singleButton("card_04", "card", "0403",),
                            this.singleButton("card_07", "card", "0703",),
                            this.singleButton("card_08", "card", "0803",),
                          ],
                        },
                        {
                          id: "Text Fields",
                          name: "Text Fields",
                          icon: "text_fields",
                          icon_color: "#ffd96a",
                          options: [
                            this.singleButton("inout_01", "inout", "01",),
                            this.singleButton("inout_03", "inout", "03",),
                            this.singleButton("inout_02", "inout", "02",),
                          ],
                        },
                        {
                          id: "Button",
                          name: "Buttons",
                          icon: "indeterminate_check_box",
                          icon_color: "#22d1ee",
                          options: [
                            this.singleButton("button_04", "button", "04",),
                            this.singleButton("button_01", "button", "01",),
                            this.singleButton("button_03", "button", "03",),
                            this.singleButton("button_02", "button", "02",),
                            this.singleButton("button_05", "button", "05",),
                          ],
                        },
                        {
                          id: "Output Cells",
                          name: "Output Cells",
                          icon: "output",
                          icon_color: "#cbf078",
                          options: [
                            this.singleButton("out_01", "out", "01",),
                            this.singleButton("out_02", "out", "02",),
                            this.singleButton("out_03", "out", "03",),

                          ],
                        },
                        {
                          id: "Radio Button",
                          name: "Radio Button",
                          icon: "radio_button_checked",
                          icon_color: "#5671ff",
                          options: [
                            this.singleButton("singlechoice_1000", "singlechoice", "1000",),
                            this.singleButton("singlechoice_2000", "singlechoice", "2000",),
                            this.singleButton("singlechoice_3000", "singlechoice", "3000",),
                          ],
                        },
                        {
                          id: "Checkbox",
                          name: "Checkbox",
                          icon: "check_box",
                          icon_color: "#e78775",
                          options: [
                            this.singleButton("multiplechoice_1000", "multiplechoice", "1000",),
                            this.singleButton("multiplechoice_2000", "multiplechoice", "2000",),
                            this.singleButton("multiplechoice_3000", "multiplechoice", "3000",),
                          ],
                        },
                       {
                          id: "Toggle Buttons",
                          name: "Toggle Buttons",
                          icon: "toggle_off",
                          icon_color: "#c582d5",
                          options: [
                            this.singleButton("toggle_1000", "toggle", "1000",),
                            this.singleButton("toggle_2000", "toggle", "2000",),
                            this.singleButton("toggle_3000", "toggle", "3000",),
                          ],
                        },
                        {
                          id: "Dropdown Menus",
                          name: "Dropdown Menus",
                          icon: "expand_circle_down",
                          icon_color: "#1abb9c",
                          options: [
                            this.singleButton("dropdown_01", "dropdown", "01",),
                            this.singleButton("dropdown_03", "dropdown", "03",),
                            this.singleButton("dropdown_02", "dropdown", "02",),
                          ],
                        },
                        {
                          id: "multichoiceSegmented",
                          name: "Multichoice segmented",
                          icon: "check_box",
                          icon_color: "#e78775",
                          options: [
                            this.singleButton("multichoice_segmented_1000", "multichoice_segmented", "1000",),
                          ],
                        },
                        {
                          id: "singlechoiceSegmented",
                          name: "Singlechoice segmented",
                          icon: "check_box",
                          icon_color: "#e78775",
                          options: [
                            this.singleButton("singlechoice_segmented_1000", "singlechoice_segmented", "1000",),
                          ],
                        },
                      ],
                    },
                  ],
                },
              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;
            case "store":
              this.offcanvas_data = [
                {
                  tab: "1st TAB",
                  data: [
                    {
                      cat: "Components",
                      show: true,
                      name: "Headline",
                      icon: "title",
                      icon_color: "#349fff",
                      item: this.singleButton("bar_01", "bar", "1004"),
                    },
                     {
                      cat: "CARD",
                      show: false,
                      list: [
                        // this.singleButton("bar_01", "bar", "1004",),
                        {
                          id: "Button",
                          name: "Buttons",
                          icon: "indeterminate_check_box",
                          icon_color: "#22d1ee",
                          options: [
                            this.singleButton("button_04", "button", "04",),
                            this.singleButton("button_01", "button", "01",),
                            this.singleButton("button_03", "button", "03",),
                            this.singleButton("button_02", "button", "02",),
                            this.singleButton("button_05", "button", "05",),
                          ],
                        },

                        {
                          id: "Cards",
                          name: "Cards",
                          icon: "space_dashboard",
                          icon_color: "#52ef7a",
                          options: [
                            this.singleButton("card_06", "card", "0603",),
                            this.singleButton("card_03", "card", "0303",),
                            this.singleButton("card_05", "card", "0503",),
                            this.singleButton("card_04", "card", "0403",),
                            this.singleButton("card_07", "card", "0703",),
                            this.singleButton("card_08", "card", "0803",),
                          ],
                        },
                        {
                          id: "Collections",
                          name: "Collections",
                          icon: "apps",
                          icon_color: "#ffd96a",
                          options: [
                            // this.singleButton("collection_01", "collection", "0103",),
                            // this.singleButton("collection_02", "collection", "0203",),

                            this.singleRow([
                              { code: "collection_13", form: "collection", style: "0204" },
                              { code: "collection_14", form: "collection", style: "0204" },
                              { code: "collection_15", form: "collection", style: "0204" },
                              { code: "collection_16", form: "collection", style: "0204" }
                            ],
                            "Row", "",
                            collection_composed_images ["collection-13-16"].image, 60),
                            this.singleRow([{ code: "collection_07", form: "collection", style: "0103" },
                              { code: "collection_08", form: "collection", style: "0103"  }], "Row", "",
                            collection_composed_images ["collection-7-8"].image, 60),

                            this.singleRow([{ code: "collection_09", form: "collection", style: "0403" },
                              { code: "collection_10", form: "collection", style: "0403"  }], "Row", "",
                              collection_composed_images ["collection-9-10"].image, 60),

                              this.singleRow([{ code: "collection_11", form: "collection", style: "0503" },
                              { code: "collection_12", form: "collection", style: "0503"  }], "Row", "",
                              collection_composed_images ["collection-11-12"].image, 60),

                            this.singleButton("collection_04", "collection", "0403",),
                            this.singleButton("collection_05", "collection", "0503",),

                         ],
                        },
                      ],
                    },
                  ],
                },
              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;
            case "poll":
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "Components",
                      show: true,
                      name: "Button",
                      icon: "indeterminate_check_box",
                      icon_color: "#22d1ee",
                      item: this.singleButton("button_02", "button", "02",),
                    },
                  ],
                },
              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;
            case "query":
                this.offcanvas_data = [
                  {
                    tab: "Basic",
                    data: [
                      {
                        cat: "Components",
                        show: true,
                        name: "Headline",
                        icon: "title",
                        icon_color: "#349fff",
                        item: this.singleButton("bar_01", "bar", "1004"),
                      },
                      {
                        cat: "Components",
                        show: false,
                        list: [

                          {
                            id: "Text Fields",
                            name: "Text Fields",
                            icon: "text_fields",
                            icon_color: "#ffd96a",
                            options: [
                              this.singleButton("inout_01", "inout", "01",),
                              this.singleButton("inout_03", "inout", "03",),
                              this.singleButton("inout_02", "inout", "02",),
                            ],
                          },
                          {
                            id: "Button",
                            name: "Buttons",
                            icon: "indeterminate_check_box",
                            icon_color: "#22d1ee",
                            options: [
                              this.singleButton("button_04", "button", "04",),
                              this.singleButton("button_01", "button", "01",),
                              this.singleButton("button_03", "button", "03",),
                              this.singleButton("button_02", "button", "02",),
                              this.singleButton("button_05", "button", "05",),
                            ],
                          },
                          {
                            id: "Dropdown Menus",
                            name: "Dropdown Menus",
                            icon: "expand_circle_down",
                            icon_color: "#1abb9c",
                            options: [
                              this.singleButton("dropdown_01", "dropdown", "01",),
                              this.singleButton("dropdown_03", "dropdown", "03",),
                              this.singleButton("dropdown_02", "dropdown", "02",),
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                this.off_canvas_key = data.off_canvas_key;
                break;
            case "market":
                  this.offcanvas_data = [
                    {
                      tab: "1st TAB",
                      data: [
                        {
                          cat: "Components",
                          show: true,
                          name: "Headline",
                          icon: "title",
                          icon_color: "#349fff",
                          item: this.singleButton("bar_01", "bar", "1004"),
                        },
                         {
                          cat: "CARD",
                          show: false,
                          list: [
                            // this.singleButton("bar_01", "bar", "1004",),
                            {
                              id: "Button",
                              name: "Buttons",
                              icon: "indeterminate_check_box",
                              icon_color: "#22d1ee",
                              options: [
                                this.singleButton("button_04", "button", "04",),
                                this.singleButton("button_01", "button", "01",),
                                this.singleButton("button_03", "button", "03",),
                                this.singleButton("button_02", "button", "02",),
                                this.singleButton("button_05", "button", "05",),
                              ],
                            },

                            {
                              id: "Cards",
                              name: "Cards",
                              icon: "space_dashboard",
                              icon_color: "#52ef7a",
                              options: [
                                this.singleButton("card_06", "card", "0603",),
                                this.singleButton("card_03", "card", "0303",),
                                this.singleButton("card_05", "card", "0503",),
                                this.singleButton("card_04", "card", "0403",),
                                this.singleButton("card_07", "card", "0703",),
                                this.singleButton("card_08", "card", "0803",),
                              ],
                            },
                            {
                              id: "Categories",
                              name: "Categories",
                              icon: "apps",
                              icon_color: "#ffd96a",
                              options: [
                                // this.singleButton("collection_01", "collection", "0103",),
                                // this.singleButton("collection_02", "collection", "0203",),

                                this.singleRow([
                                  { code: "collection_13", form: "collection", style: "0204" },
                                  { code: "collection_14", form: "collection", style: "0204" },
                                  { code: "collection_15", form: "collection", style: "0204" },
                                  { code: "collection_16", form: "collection", style: "0204" }

                                ],  "Row", "", collection_composed_images ["collection-13-16"].image, 60),


                                this.singleRow([{ code: "collection_07", form: "collection", style: "0103" },
                                  { code: "collection_08", form: "collection", style: "0103"  }], "Row", "",
                                collection_composed_images ["collection-7-8"].image, 60),

                                this.singleRow([{ code: "collection_09", form: "collection", style: "0403" },
                                  { code: "collection_10", form: "collection", style: "0403"  }], "Row", "",
                                  collection_composed_images ["collection-9-10"].image, 60),

                                  this.singleRow([{ code: "collection_11", form: "collection", style: "0503" },
                                  { code: "collection_12", form: "collection", style: "0503"  }], "Row", "",
                                  collection_composed_images ["collection-11-12"].image, 60),

                                this.singleButton("collection_04", "collection", "0403",),
                                this.singleButton("collection_05", "collection", "0503",),

                             ],
                            },
                          ],
                        },
                      ],
                    },
                  ];
                  if (data.show) {
                    this.showOffcanvas();
                  } else {
                    this.hideOffcanvas();
                  }
                  this.off_canvas_key = data.off_canvas_key;
                  break;
          }
        }
      }
    );
  }

  drag(event: any, i: any) {
    let data = i;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
        event.dataTransfer.setData("module", data.module);
        break;
      case "page":
      case "menu":
      case "store":
      case "market":
      case "poll":
      case "query":
        event.dataTransfer.setData("button", JSON.stringify(data));
        break;
    }
  }




  onClick(event, item) {
    event.stopPropagation();
    event.preventDefault();
    let data = item;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
        this.mdw._offCanvasResponseContainer.next({
          module: this.off_canvas_key,
          item: data.module,
        });


        break;
      case "menu":
      case "page":
      case "store":
      case "market":
      case "poll":
      case "query":
        this.mdw._offCanvasResponseContainer.next({
          module: this.off_canvas_key,
          item: JSON.stringify(data),
        });
        break;
    }
  }

  hasItems(item: any) {
    return item.options !== undefined ? item.options.length > 0 : false;
  }

  show(id) {
    const parentElement = document.getElementById(id);
    if (!parentElement.classList.contains("mm-active")) {
      parentElement.classList.add("mm-active");
      parentElement.getElementsByTagName("ul")[0].classList.add("mm-show");
    } else {
      parentElement.classList.remove("mm-active");
      parentElement.getElementsByTagName("ul")[0].classList.remove("mm-show");
    }
  }

  hideOffcanvas() {
    document.querySelector("#offcanvasScrollingMenu").classList.remove("show");
    document.querySelector("#faide").classList.remove("animate-fede-out");
    document.querySelector("#faide").classList.add("animate-fede-in");
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrollingMenu").classList.add("show");
    document.querySelector("#faide").classList.add("animate-fede-out");
    document.querySelector("#faide").classList.remove("animate-fede-in");
  }

  singleButton(code, form, style, name?, icon?) {
    let component = {
      row: [{ button_code: code, button_form: form, button_style: style, button_style_ios: style, }],
      name: menu_button_map[code].info.name ? menu_button_map[code].info.name : code,
      icon: menu_button_map[code].info.icon, min_grid: menu_button_map[code].info.min_grid,
      image: menu_button_map[code].info.image
    }
    return component
  }

  singleRow(cells, name?, icon?, image?, min_grid?) {
    let row = [];
    cells.forEach(cell => {
      let button = { button_code: cell.code, button_form: cell.form, button_style: cell.style, button_style_ios: cell.style }
      row.push(button)
    });

    let rowData = { row, name: name, icon: icon, image: image, min_grid: min_grid }

    return rowData
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}








            //  case "page":
            //     this.offcanvas_data = [
            //       {
            //         tab: "Page",
            //         data: [
            //           {
            //             cat: "Basic",
            //             show: false,
            //             list: [
            //               {
            //                 button_code: MENU_BUTTON_CODE.PAGETEXT,
            //                 button_form: "text",
            //                 button_style: "0",
            //                 name: "Page Text",
            //                 icon: "",
            //                 min_grid:
            //                   menu_button_map[MENU_BUTTON_CODE.PAGETEXT]
            //                     .info.min_grid,
            //                 image:
            //                   menu_button_map[MENU_BUTTON_CODE.PAGETEXT]
            //                     .info.image,
            //               },
            //               {
            //                 button_code: MENU_BUTTON_CODE.PAGESLIDER,
            //                 button_form: "pageslider",
            //                 button_style: "1000",
            //                 name: "Image Slider",
            //                 icon: "",
            //                 min_grid:
            //                   menu_button_map[
            //                     MENU_BUTTON_CODE.PAGESLIDER
            //                   ].info.min_grid,
            //                 image:
            //                   menu_button_map[
            //                     MENU_BUTTON_CODE.PAGESLIDER
            //                   ].info.image,
            //               }, {
            //                 button_code: MENU_BUTTON_CODE.PAGESLIDERLABEL,
            //                 button_form: "pagesliderlabel",
            //                 button_style: "2000",
            //                 name: "Image Slider Label",
            //                 icon: "",
            //                 min_grid:
            //                   menu_button_map[
            //                     MENU_BUTTON_CODE.PAGESLIDERLABEL
            //                   ].info.min_grid,
            //                 image:
            //                   menu_button_map[
            //                     MENU_BUTTON_CODE.PAGESLIDERLABEL
            //                   ].info.image,
            //               },

            //               {
            //                 button_code: MENU_BUTTON_CODE.PAGEGALLERY,
            //                 button_form: "pagegallery",
            //                 button_style: "1000",
            //                 name: "Image Gallery",
            //                 icon: "",
            //                 min_grid:
            //                   menu_button_map[
            //                     MENU_BUTTON_CODE.PAGEGALLERY
            //                   ].info.min_grid,
            //                 image:
            //                   menu_button_map[
            //                     MENU_BUTTON_CODE.PAGEGALLERY
            //                   ].info.image,
            //               },
            //               {
            //                 button_code: MENU_BUTTON_CODE.PAGESINGLEIMAGE,
            //                 button_form: MENU_BUTTON_FORM.PAGESINGLEIMAGE,
            //                 button_style: "1000",
            //                 name: "Single Image",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGESINGLEIMAGE].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGESINGLEIMAGE].info.image,
            //               }, {
            //                 button_code: MENU_BUTTON_CODE.PAGEHEADER,
            //                 button_form: MENU_BUTTON_FORM.PAGEHEADER,
            //                 button_style: "1000",
            //                 name: "Header",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEHEADER].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGEHEADER].info.image,
            //               },{
            //                 button_code: MENU_BUTTON_CODE.PAGEDIVIDER,
            //                 button_form: MENU_BUTTON_FORM.PAGEDIVIDER,
            //                 button_style: "1000",
            //                 name: "Divider",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEDIVIDER].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGEDIVIDER].info.image,
            //               },
            //               {
            //                 button_code: MENU_BUTTON_CODE.PAGEVIDEO,
            //                 button_form: MENU_BUTTON_FORM.PAGEVIDEO,
            //                 button_style: "1000",
            //                 name: "Video",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEVIDEO].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGEVIDEO].info.image,
            //               },  {
            //                 button_code: MENU_BUTTON_CODE.PAGEQR,
            //                 button_form: MENU_BUTTON_FORM.PAGEQR,
            //                 button_style: "1000",
            //                 name: "QR",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEQR].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGEQR].info.image,
            //               },  {
            //                 button_code: MENU_BUTTON_CODE.PAGELOCATION,
            //                 button_form: MENU_BUTTON_FORM.PAGELOCATION,
            //                 button_style: "1000",
            //                 name: "Location",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGELOCATION].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGELOCATION].info.image,
            //               },
            //               {
            //                 button_code: MENU_BUTTON_CODE.PAGEPDFVIEWER,
            //                 button_form: MENU_BUTTON_FORM.PAGEPDFVIEWER,
            //                 button_style: "0",
            //                 name: "Pdf Viewer",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEPDFVIEWER].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGEPDFVIEWER].info.image,
            //               },{
            //                 button_code: MENU_BUTTON_CODE.PAGESOCIALBAR,
            //                 button_form: MENU_BUTTON_FORM.PAGESOCIALBAR,
            //                 button_style: "1000",
            //                 name: "Social bar",
            //                 icon: "",
            //                 min_grid: menu_button_map[MENU_BUTTON_CODE.PAGESOCIALBAR].info.min_grid,
            //                 image : menu_button_map[MENU_BUTTON_CODE.PAGESOCIALBAR].info.image,
            //               }
            //             ],
            //           },
            //         ],
            //       },
            //     ];
            //     if (data.show) {
            //       this.showOffcanvas();
            //     } else {
            //       this.hideOffcanvas();
            //     }
            //     this.off_canvas_key = data.off_canvas_key;

            //     break;
