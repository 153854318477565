<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form [formGroup]="termForm">
      <div class="row mb-3">
        <div class="col-12">
          <label for="terms_url" class="offcanvas-header-text w-100" style="text-align: left">
            Terms of Service
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="terms_url"
              class="form-control input-lg offcanvas-primary border-0"
              placeholder="Enter URL"
              formControlName="terms_url"
              (change)="termsURLChange()"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <label for="policy_url" class="offcanvas-header-text w-100" style="text-align: left">
            Privacy Policy
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="policy_url"
              class="form-control input-lg offcanvas-primary border-0"
              placeholder="Enter URL"
              formControlName="policy_url"
              (change)="policyURLChange()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
