import { MENU } from "./../sidebar/menu";
import { UsersModule } from "./../../pages/users/users.module";
import { webInfo, Menu, Chat } from "./../../core/services/interface";
import { filter } from "rxjs/operators";
import { EventService } from "./../../core/services/event.service";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  Renderer2,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Message, Page } from "src/app/core/services/interface";
import { chatWithAI, GetAppConfig, GetAppInfo, getChatCounters, GetChatList, GetMsgMax, GetPaymentProviders, GetPoll, GetStore, GetTemplateMethod, RevokeSubscription, RunAppConfigTemplate, SelectChat, SetAppConfig, SetChat, SetMStore, SetPoll, SetTemplateFunctionMethod } from "src/app/core/services/outbound";
import { environment } from "src/environments/environment";
import {
  TabNames,
  c1,
  c16,
  colorSchemaDefault,
  message_status_map,
  plan1,
  plan2,
  plan3,
  plan5,
  plan6,
  plan7,
} from "src/app/core/services/constants";
import { BuilderService } from "src/app/sharedservices/builder.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import Swal from "sweetalert2";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit,OnDestroy {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  chatListSubscription: any;
  selectedChatSubscription: any;
  messageSubscription: any;
  messageListSubscription: any;
  userListSubscription: any;
  menuPageResponseSubscription: any;
  requestAppleCodeResponseSubscription: any;
  sendChatToAISubscription: any;
  getPollMethodSubscription: any;
  getPollSubscription: any;
  likeMessageAckSubscription: any;
  colorsComponentSubscription: any;
  verifySubscription: any;
  appBuilderSubscription: any;
  getAppConfigOnceSubscription: any;
  getAppConfigSubscription: any;
  updateBuilderComponentSubscription: any;

  schemeChangeSubscription: any;
  saveAppConfigSignupSubscription: any;
  saveAppConfigChangeTemplateSubscription: any;

  storeSubscription: any;
  pollSubscription: any;
  getStoreSubscription: any;
  getStoreMethodSubscription: any;
  getAppInfoSubscription: any;
  tempModeStatusSubscription: any;
  setAppConfigResponseSubscription: any;
  resetAppConfigSubscription: any;
  expiredSubscription: any;
  databaseOpenSubscription: any;
  setStoreSubscription: any;
  profile_name: any;
  profile_image: any = "assets/images/users/account.webp";
  mode: any = "light";
  balance: any;
  admin = false;
  super = false;
  chatList = [];
  main_chat: any;
  default_chat: any;
  isAndroid: boolean;
  colors: any;
  originalColors: any = {};
  scheme: any;
  component_ref: any;
  component_id: string = "topbar"
  inValid = true;
  otp = null;

  appVersion: any;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "50px",
    },
  };

  allChatsHidden= false;
  tempModeStatus: boolean;
  template_id: any;
  replies = [];
  appInfo;
  sample = false;

  targetDate: Date = new Date(); // Set your target date
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  interval: any;
  plan = "plan_4";
  expired = false;
  planLevel = 0;
  cancelEnd: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private ws: WebsocketService,
    private es: EventService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    private indexDBService: IndexDBService,
    private ref: ChangeDetectorRef,
    private load: LoaderService,
    private renderer: Renderer2
  ) { }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  reconnectNotice = "";
  today = 0;

  @ViewChild("menuButton", { static: false }) menuButton: ElementRef;
  @ViewChild("menu", { static: false }) menu: ElementRef;
  @ViewChild('dropdown', { static: false }) dropdown: BsDropdownDirective;
  ngOnInit() {
    let modes = "ahmtwbsttw3a3p0l";
    localStorage.setItem("mode", modes)
    this.today = Date.now();

    this.component_ref = this.mdw.makeRef(16);
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.colors = colorSchemaDefault;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    this.selectedChatSubscription = this.ws.selectedChatContainer$.subscribe(
      (selectedChatData) => {
        if (selectedChatData) {
          if (this.chatList) {
            this.default_chat = this.chatList.find(
              (e: any) => e.id == selectedChatData.id
            );
          }
        }
      }
    );
    this.messageSubscription = this.ws.messageTagsContainer$.subscribe((message) => {
      if (message) {
        if (
          !message.message.welcome &&
          !message.message.schedule &&
          !message.message.award
        ) {
          if (!message.status) {
            message.status = "sent";
          }
          if (!message.message.reply_to_message_id) {
            message.message.reply_to_message_id = "0";
          }
          if (message.message.from.id && message.message.sent_to && message.message.sent_to.id && message.message.from_admin == 1) {
            message.message.user = message.message.sent_to.id;
          } else if (message.message.from.id && (!message.message.from_admin || message.message.from_admin == 0)) {
            message.message.user = message.message.from.id;
          }

          if(message.message.reply_to_message_id != "0"){
            this.replies.push(message);
          }

          this.saveToDB(message, true, true);
          this.ws.processMethod(new getChatCounters(message.message.chat.id, [message.message.message_id]));
        }
      }
    });
    this.messageListSubscription = this.ws.messageListResponeContainer$.subscribe((data) => {
      let messages = data.messages;
      let message_ids = [];
      if (messages) {
        let mediaTypes = ["photo", "video", "gif", 'audio', "voice"];
        let mediaCount = 0;
        for (let i = 0; i < messages.length; i++) {
          if (
            !messages[i].message.welcome &&
            !messages[i].message.schedule_date &&
            !messages[i].message.award
          ) {
            if(messages[0].message.chat.type == "Channel" && !data.max){
              message_ids.push(messages[i].message.message_id);
            }

            if (!messages[i].status) {
              messages[i].status = "text";
            }
            if (!messages[i].message.reply_to_message_id) {
              messages[i].message.reply_to_message_id = "0";
            }

            if (messages[i].message.from.id && messages[i].message.sent_to && messages[i].message.sent_to.id && messages[i].message.from_admin == 1) {
              messages[i].message.user = messages[i].message.sent_to.id;
            } else if (messages[i].message.from.id && (!messages[i].message.from_admin || messages[i].message.from_admin == 0)) {
              messages[i].message.user = messages[i].message.from.id;
            }

            if (mediaTypes.includes(messages[i].message.type)) {
              mediaCount++;
              if (mediaCount > 10) {
                this.saveToDB(messages[i], false, false, data.max);
              } else {
                this.saveToDB(messages[i], false, true, data.max);
              }
            } else {
              this.saveToDB(messages[i], false, false, data.max);
            }
          }
        }
        if(message_ids.length > 0){
          this.ws.processMethod( new getChatCounters(messages[0].message.chat.id, message_ids));
        }
        // console.log("sent");
      }
    });
    this.menuPageResponseSubscription = this.ws.menuPageResponseContainer$.subscribe((data) => {
      if (data) {
        this.builderService.savePage(data);
      }
    });

    this.userListSubscription = this.ws.listUsersContainer$.subscribe((listUsersData) => {
      if (listUsersData) {
        for (let i = 0; i < listUsersData.members.length; i++) {
          // console.log(listUsersData.members[i].member_id);
          const user_message = this.indexDBService.getItemList("user", "user", listUsersData.members[i].member_id);
          user_message.onsuccess = () => {
            // console.log("USER MESSAGE GET ITEM LIST");
            // console.log(user_message?.result);
            if (user_message.result && user_message.result.length > 0) {
              let userMsg = user_message.result;
              userMsg.forEach((msg) => {
                msg.message.from["name"] = listUsersData.members[i].name;
                msg.message.from["url"] = listUsersData.members[i].media.photo.url;
                this.indexDBService.updateItem("user", msg);
                this.mdw._userSavedNotifyContainer.next({ ...msg, ref: listUsersData.ref });
              });
            }
          };
        }
      }
    });

    this.likeMessageAckSubscription = this.ws.likeMessageAckContainer$.subscribe((data) => {
      if (data) {
        const message = this.indexDBService.getItem("messages", data.message_id);
        let newMessage;
        message.onsuccess = (event) => {
          if (message.result) {
            newMessage = { ...message.result };
            newMessage.myLike = data.myLike;
            this.indexDBService.updateItem("messages", newMessage).onsuccess = (event) => {
              newMessage.message.status = message_status_map.UPDATED;
              this.ws._incomingMessageContainer.next(newMessage);
            };
          }
        }
      }
    });

    this.databaseOpenSubscription = this.indexDBService.databaseOpenContainer$.subscribe((opened) => {
      if(opened){
        this.getAppConfigSubscription = this.mdw.getAppConfigContainer$.subscribe((data) => {
          if (data) {
            if (localStorage.getItem("ndbx")=="true")
            this.chatsIntialize();
            this.colorInitialize();
            this.logoInitialize();
            this.mdw._colorsComponentContainer.next(this.originalColors)
            this.sample = localStorage.getItem("sample") ? true : false
          }
        });


        this.updateBuilderComponentSubscription = this.mdw.updateBuilderComponentContainer$.subscribe(data =>{
          if (data){
            this.colorInitialize();
          }
        })


        this.saveAppConfigSignupSubscription = this.mdw.saveAppConfigSignupContainer$.subscribe(data =>{
          if (data){
            let template_id =  localStorage.getItem("template_id")
            if (template_id){
              localStorage.removeItem("template_id")
              this.sendAppConfigFromTemplateToServer(data, this.component_ref)
              this.ws.processMethod(new RunAppConfigTemplate(template_id));
            }

          }
        })

        this.saveAppConfigChangeTemplateSubscription = this.mdw.saveAppConfigChangeTemplateContainer$.subscribe(data =>{
          if (data){
            let template_id =  localStorage.getItem("template_id")
            if (template_id){
              localStorage.removeItem("template_id")
              this.ws.processMethod(new RunAppConfigTemplate(template_id));
            }
          }
        })

        this.schemeChangeSubscription = this.mdw.schemeChangeContainer$.subscribe(
          (data) => {
            this.colorInitialize();
            }
        );
        this.chatListSubscription = this.ws.getChatListContainer$.subscribe((data) => {
          if (data) {
            const chats = data.chat_list;
            this.chatList = this.moveMainChannelToTop(chats.filter((chat) => chat.type === "Channel"));
            this.default_chat = this.chatList.find((e: any) => e.id == localStorage.getItem("chat_id"));
            this.indexDBService.updateItemList("chats", chats);
            let list = {};
            for (let i = 0; chats.length > i; i++) {
              if (chats[i]) {
                list[chats[i].id] = chats[i];
              }
            }
            this.mdw._chatList.next(list);

            if(data.ref == this.component_ref){
              let ids = [];
              chats.forEach(chat => {
                ids.push(Number(chat.id));
              });
              // this.load.isLoading.next(false);
              this.ws.processMethod(new GetMsgMax(ids, this.component_ref));
            }
          }
        });
      }
    });

    this.verifySubscription = this.ws.verifyContainer$.subscribe(async (verifyData) => {
      if (verifyData) {
        let template_id = JSON.parse(localStorage.getItem("template_id"))
        if (template_id){
          this.ws.processMethod(new GetTemplateMethod(Number(template_id), this.component_ref));
        } else {
          this.ws.processMethod(new GetAppConfig(this.component_ref));
        }
        this.ws.processMethod(new GetAppInfo(this.component_ref));
        this.ws.processMethod(new GetChatList(this.component_ref));
        this.plan = this.mdw.getPlanDetails()?.plan_id;
        if (this.plan == plan1.id || this.plan == plan5.id){
          this.planLevel = 1;
        } else if(this.plan == plan2.id || this.plan == plan6.id){
          this.planLevel = 2;
        } else if (this.plan == plan3.id || this.plan == plan7.id){
          this.planLevel = 3;
        } else {
          this.planLevel = 0;
        }
        this.targetDate = new Date(this.mdw.getPlanDetails()?.trial_end_date);
        this.cancelEnd = this.mdw.getPlanDetails().cancel_end;
        this.startCountdown();
        // this.ws.processMethod(new GetStore(0));
      }
    });

    this.getAppInfoSubscription = this.ws.getAppInfoContainer$.subscribe((getAppInfo) => {
      if (getAppInfo && getAppInfo.ref === this.component_ref) {
        this.mdw._appInfoContainer.next(getAppInfo);
      }
    });

    this.getAppConfigOnceSubscription = this.mdw.getAppConfigOnceContainer$.subscribe((data) => {
      if (data) {
        this.load.isLoading.next(false);
        this.ref.detectChanges();
        // for migration only
        if(localStorage.getItem('ndbx')=="true"){
          const tabs = this.indexDBService.getItemList("tab", "cat", null);
          tabs.onsuccess = (event) => {
            if (tabs.result){
              this.mdw.checkForSomeFlags(tabs.result)
            }
          }
        }
      }
    });

    this.appBuilderSubscription = this.ws.appBuilderContainer$.subscribe(async (data) => {
      if (data != null && ((data.reference === this.component_ref) || data.genAI ) && !data.error) {
        setTimeout(() => this.mdw.saveBuilderDB(data), 0);

        //the incoming configuration belong to a Template Setup itself. Meaning this Account is a Template Account.
        if(data.template){
          this.template_id = data.template;
          this.mdw._templateID.next(data.template);
        }
      } else {
        this.load.isLoading.next(false);
        this.ref.detectChanges();
      }
    });

    this.resetAppConfigSubscription = this.mdw.resetAppConfigContainer$.subscribe((data) => {
      if(data){
        this.ws.processMethod(new GetAppConfig(this.component_ref));
      }
    })

    this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe((data) => {
      if(data && data.component_id !== this.component_id){
        this.originalColors = data;

        this.originalColors['component_id']= this.component_id;
        // this.changeTheme();
      }
    })

    if (!localStorage.getItem("light") && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.mode = "dark";
      this.es.broadcast("changeMode", "dark");
      if (this.colors) {
        this.colors['light'] = false;
        localStorage.setItem('light', 'false')
        this.changeTheme();
      }
    } else if (!localStorage.getItem("light") || localStorage.getItem("light") == "true") {
      this.mode = "light";
      this.es.broadcast("changeMode", "light");
      if (this.colors){
        this.colors['light'] = true;
        localStorage.setItem('light', 'true')
        this.changeTheme();
      }
    } else if (localStorage.getItem("light") == "false") {
      this.mode = "dark";
      this.es.broadcast("changeMode", "dark");
      if (this.colors) {
        this.colors['light'] = false;
        localStorage.setItem('light', 'false')
        this.changeTheme();
      }
    }

    this.storeSubscription = this.mdw.storeContainer$.subscribe(data => {
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const id = Number(data[i].id);
          this.ws.processMethod(new SetMStore(
            data[i].menu_id,
            null,
            data[i].shop,
            data[i].image,
            data[i].name,
            data[i].description,
            id,
            data[i].is_default,
            data[i].category
          ));
          if(this.tempModeStatus && this.template_id){
            this.setTemplate(data[i]);
          }

        }
      }
    });

    this.setAppConfigResponseSubscription= this.ws.setAppConfigResponseContainer$.subscribe(data => {
      if (data) {
          localStorage.setItem("version", "0");
          const currentURL = window.location.href;
          if(!currentURL.includes('/appbuilder/build')){
            Swal.fire({
              icon: "success",
              title: "Saved successfully",
              showConfirmButton: false,
            });
          }
      }
    });

    this.pollSubscription = this.mdw.pollContainer$.subscribe(data => {
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const id = Number(data[i].id);
          this.ws.processMethod(
            new SetPoll(
            id,
            data[i].menu_id,
            data[i].name,
            data[i].menus,
          ));
        }
      }
    });

    this.getStoreMethodSubscription = this.mdw.getStoreMethodContainer$.subscribe(data => {
       if (data) {
        const id = Number(data);
        this.ws.processMethod(new GetStore(id));
      }else {
        // get the default store
         this.ws.processMethod(new GetStore(0, "store"));
         this.ws.processMethod(new GetStore(0, "market"));
      }
    })

    this.getStoreSubscription = this.ws.getStoreContainer$.subscribe(data => {
      if (data && data.data && data.data.id ) {
        let store: any;
        store = data.data;
        store.id = store.id.toString();
        this.mdw.saveStore(store, store.category, false,true )

      }
    })

    this.getPollMethodSubscription = this.mdw.getPollMethodContainer$.subscribe(data => {
      if (data) {
       const id = Number(data);
       this.ws.processMethod(new GetPoll(id));
     }
   })

    this.tempModeStatusSubscription = this.mdw.tempModeStatus$.subscribe((data)=>{
      this.tempModeStatus = data;
    });


    this.sendChatToAISubscription = this.ws.sendChatToAIContainer$.subscribe((data) => {
      if (data) {
        this.ws.processMethod(new chatWithAI(data, true, this.component_ref));
      }
    });

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (!localStorage.getItem("light") && this.colors) {
        this.mode = event.matches ? "dark" : "light";
        if (this.mode == "light") {
          this.es.broadcast("changeMode", "light");
          this.colors['light'] = true;
          // this.mdw._colorsComponentContainer.next( this.colors)
        } else {
          this.es.broadcast("changeMode", "dark");
          this.colors['light'] = false;
          // this.mdw._colorsComponentContainer.next( this.colors)
        }
        this.changeTheme();
      }
    });

    this.renderer.listen("window", "click", (e: Event) => {
      if (this.menu) {
        const btnClicked = this.menuButton.nativeElement.contains(e.target);
        const menuClicked = this.menu.nativeElement.contains(e.target);
        if (
          this.menu &&
          this.menu.nativeElement &&
          !btnClicked &&
          !menuClicked
        ) {
          this.dropdown.hide();
        }
      }
    });

    this.expiredSubscription = this.ws.expiredContainer$.subscribe((data) => {
      if(data != null){
        this.expired = data;
        this.mobileMenuButtonClicked.emit();
      }
    })

    this.setStoreSubscription = this.ws.setStoreContainer$.subscribe((data) => {
      if (data && data.data && data.data.id && data.ref == null) {
        let store: any;
        store = data.data;
        store.id = store.id.toString();
        this.mdw.saveStore(store, store.category, false, true);
        console.log(this.router.url.includes('/stores/store') || this.router.url.includes('/market/market'));
        if(this.router.url.includes('/stores/store') || this.router.url.includes('/market/market')){
          Swal.fire({
            icon: "success",
            title: "Saved successfully",
            showConfirmButton: false,
          });
        }
      }
    });

    this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
  }

  startCountdown(): void {
    this.interval = setInterval(() => {
      let now = new Date().getTime();
      let distance = this.targetDate.getTime() - now;

      if (distance < 0) {
        clearInterval(this.interval);
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
      } else {
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      }
    }, 1000);
  }

  ngAfterViewInit(): void {
    this.changeTheme();
  }

  selectChat(id: any) {
    this.ws.processMethod(new SelectChat(id, 0, this.component_ref));
  }

  moveMainChannelToTop(channels){
    let appId = localStorage.getItem("app_id");
    let sortedChannels = channels
    if(appId){
      let target = channels.find(channel => channel.id == appId); // Find the item with id === 0
      let rest = channels.filter(channel => channel.id != appId); // Exclude the item with id === 0
      sortedChannels = target ? [target, ...rest] : channels;
    }
    return sortedChannels;
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }
  /**
   * Toggles the right sidebar
   */
  toggleMode() {
     if (this.colors) {
      if (this.mode === "light") {
        this.mode = "dark";
        this.es.broadcast("changeMode", "dark");
        this.colors['light'] = false;
        localStorage.setItem("light", "false");

        let modes: any;
        modes = [{id:c16.ID, value:c16.DARK}];
        this.mdw.changeMobileTheme(modes);
        if(this.originalColors){
          this.originalColors['light']=false
          this.originalColors['component_id']=this.component_id;
          this.mdw._colorsComponentContainer.next( this.originalColors)
        }
      } else if (this.mode === "dark") {
        this.mode = "light";
        this.es.broadcast("changeMode", "light");
        this.colors['light'] = true;
        localStorage.setItem("light", "true");

        let modes: any;
        modes = [{id:c16.ID, value:c16.LIGHT}];
        this.mdw.changeMobileTheme(modes);
        if(this.originalColors){
          this.originalColors['light']=true
          this.originalColors['component_id']=this.component_id;
          this.mdw._colorsComponentContainer.next( this.originalColors)
        }
      }
      this.changeTheme();
    }
  }

  changeTheme() {
    // this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
    if(localStorage.getItem("light")){
      this.mode = localStorage.getItem("light") == "true" ? "light" : "dark";
    }
    if (this.mode === "light") {
      let color_schema = this.colors.color_schema.schemes.light;
      // let color_schema_ios = this.colors.color_schema_ios?.schemes?.light;
      // this.scheme = this.isAndroid ? color_schema : color_schema_ios;
      this.scheme = color_schema;
      (document.getElementsByClassName("logo-light")[0] as HTMLElement).style.display = "block";
      (document.getElementsByClassName("logo-dark")[0] as HTMLElement).style.display = "none";

      color_schema = this.colors.color_schema.schemes.dark;
      // color_schema_ios = this.colors.color_schema_ios?.schemes?.dark;
      // let dark_scheme = this.isAndroid ? color_schema : color_schema_ios;
      let dark_scheme = color_schema;
      document.documentElement.style.setProperty("--offcanvas-3d-bottom", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-3d-top", dark_scheme.surfaceContainerLowest);

      document.documentElement.style.setProperty("--offcanvas-bg", dark_scheme.surfaceContainer);
      document.documentElement.style.setProperty("--offcanvas-content-bg", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-parent-bg", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-icon-bg", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-submenu-bg", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-card-container-hover-bg", dark_scheme.surfaceContainerLowest);
      document.documentElement.style.setProperty("--offcanvas-card-container-bg", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-single-card-container-hover-bg", dark_scheme.surfaceContainerLowest);
      document.documentElement.style.setProperty("--offcanvas-single-card-container-bg", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-header", dark_scheme.surface);
      document.documentElement.style.setProperty("--offcanvas-header-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-active-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-hover-text", dark_scheme.secondary);
      document.documentElement.style.setProperty("--offcanvas-inactive-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-divider", dark_scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-primary", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-sub-menu-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-icon", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-scrollbar-color", dark_scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-outline", dark_scheme.outline);
      document.documentElement.style.setProperty("--offcanvas-outline-variant", dark_scheme.outlineVariant);
      document.documentElement.style.setProperty("--offcanvas-drag-icon", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-input-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-text", this.scheme.onPrimary);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-bg", dark_scheme.surfaceContainerLow);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-bg", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-error", dark_scheme.error);
      document.documentElement.style.setProperty("--offcanvas-active-tab", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-active-tab-text", dark_scheme.onSurface);

      document.documentElement.style.setProperty("--icon-close", dark_scheme.onSurface);

      document.documentElement.style.setProperty("--disabled-shade", dark_scheme.surface);
      document.documentElement.style.setProperty("--disabled-shade-text", dark_scheme.onSurface);

      document.documentElement.style.setProperty("--offcanvas-subcard", dark_scheme.surfaceContainer);

      document.documentElement.style.setProperty("--sidebar", dark_scheme.surfaceContainer);
      document.documentElement.style.setProperty("--sidebar-text", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--sidebar-active-text", dark_scheme.primary);
      document.documentElement.style.setProperty("--sidebar-hover-text", dark_scheme.secondary);
      document.documentElement.style.setProperty("--sidebar-sub-menu-text", dark_scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--sidebar-icon", dark_scheme.onSurface);
      document.documentElement.style.setProperty("--sidebar-menu-bg", dark_scheme.surfaceContainerHighest);
    } else if (this.mode === "dark") {
      let color_schema = this.colors.color_schema.schemes.dark;
      // let color_schema_ios = this.colors.color_schema_ios?.schemes?.dark;
      // this.scheme = this.isAndroid ? color_schema : color_schema_ios;
      this.scheme =  color_schema;

      (document.getElementsByClassName("logo-light")[0] as HTMLElement).style.display = "none";
      (document.getElementsByClassName("logo-dark")[0] as HTMLElement).style.display = "block";
      document.documentElement.style.setProperty("--offcanvas-3d-bottom", this.scheme.surfaceContainerLowest);
      document.documentElement.style.setProperty("--offcanvas-3d-top", this.scheme.surfaceContainerHighest);

      document.documentElement.style.setProperty("--offcanvas-bg", this.scheme.surfaceContainer);
      document.documentElement.style.setProperty("--offcanvas-content-bg", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-parent-bg", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-icon-bg", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-submenu-bg", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-card-container-hover-bg", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-card-container-bg", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-single-card-container-hover-bg", this.scheme.surfaceContainerLowest);
      document.documentElement.style.setProperty("--offcanvas-single-card-container-bg", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-header", this.scheme.surfaceDim);
      document.documentElement.style.setProperty("--offcanvas-header-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-active-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-hover-text", this.scheme.secondary);
      document.documentElement.style.setProperty("--offcanvas-inactive-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-divider", this.scheme.surfaceContainerHigh);
      document.documentElement.style.setProperty("--offcanvas-primary", this.scheme.surfaceContainerHighest);
      document.documentElement.style.setProperty("--offcanvas-sub-menu-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-icon", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-scrollbar-color", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-outline", this.scheme.outline);
      document.documentElement.style.setProperty("--offcanvas-outline-variant", this.scheme.outlineVariant);
      document.documentElement.style.setProperty("--offcanvas-drag-icon", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-input-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-text", this.scheme.onPrimary);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-bg", this.scheme.surfaceContainerLow);
      document.documentElement.style.setProperty("--offcanvas-radio-btn-active-bg", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-error", this.scheme.error);
      document.documentElement.style.setProperty("--offcanvas-active-tab", this.scheme.primary);
      document.documentElement.style.setProperty("--offcanvas-active-tab-text", this.scheme.primary);

      document.documentElement.style.setProperty("--icon-close", this.scheme.onSurface);

      document.documentElement.style.setProperty("--disabled-shade", this.scheme.surface);
      document.documentElement.style.setProperty("--disabled-shade-text", this.scheme.onSurface);

      document.documentElement.style.setProperty("--offcanvas-subcard", this.scheme.surfaceContainer);

      document.documentElement.style.setProperty("--sidebar", this.scheme.surfaceContainer);
      document.documentElement.style.setProperty("--sidebar-text", this.scheme.onSurface);
      document.documentElement.style.setProperty("--sidebar-active-text", this.scheme.primary);
      document.documentElement.style.setProperty("--sidebar-hover-text", this.scheme.secondary);
      document.documentElement.style.setProperty("--sidebar-sub-menu-text", this.scheme.onSurfaceVariant);
      document.documentElement.style.setProperty("--sidebar-icon", this.scheme.onSurface);
      document.documentElement.style.setProperty("--sidebar-menu-bg", this.scheme.surfaceContainerHighest);
    }

    document.documentElement.style.setProperty("--card-body", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--card-text", this.scheme.onSurface);

    document.documentElement.style.setProperty("--card-channel-body", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--card-channel-text", this.scheme.onSurface);
    document.documentElement.style.setProperty("--card-button-hover-bg", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--card-dim-bg", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--card-bright-bg", this.scheme.surfaceContainerLowest);
    document.documentElement.style.setProperty("--card-container-text", this.scheme.onSurfaceContainer);

    document.documentElement.style.setProperty("--slider-card-bg", this.scheme.surfaceContainerHigh);

    document.documentElement.style.setProperty("--subcard", this.scheme.surfaceContainer);

    document.documentElement.style.setProperty("--modal-bg", this.scheme.surfaceContainerLow);

    document.documentElement.style.setProperty("--topbar", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--topbar-icon", this.scheme.onSurface);

    document.documentElement.style.setProperty("--tab-selected", this.scheme.primary);
    document.documentElement.style.setProperty("--tab-border-outline-variant", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--os-tabset-bg", this.scheme.surfaceContainerLowest);

    document.documentElement.style.setProperty("--switch-bg", this.scheme.surfaceContainerHighest);
    document.documentElement.style.setProperty("--switch-border", this.scheme.outline);
    document.documentElement.style.setProperty("--switch-checked-bg", this.scheme.primary);
    document.documentElement.style.setProperty("--switch-dot", this.scheme.outline);
    document.documentElement.style.setProperty("--switch-checked-dot", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--switch-text", this.scheme.outline);
    document.documentElement.style.setProperty("--switch-checked-text", this.scheme.onPrimary);

    document.documentElement.style.setProperty("--table-header-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--table-header-border", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--table-header-bg", this.scheme.surfaceContainerHighest);
    document.documentElement.style.setProperty("--table-bg", this.scheme.surfaceContainer);
    document.documentElement.style.setProperty("--table-striped-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--table-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--table-hover-text", this.scheme.onSurface);
    document.documentElement.style.setProperty("--table-hover-bg", this.scheme.surfaceDim);

    document.documentElement.style.setProperty("--input-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--input-disabled-bg", this.scheme.surfaceDim);
    document.documentElement.style.setProperty("--input-addon-bg", this.scheme.surfaceContainer);
    document.documentElement.style.setProperty("--input-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--input-placeholder", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--input-border", this.scheme.outline);
    document.documentElement.style.setProperty("--input-selected-bg", this.scheme.surfaceContainerHighest);

    document.documentElement.style.setProperty("--module-bar-bg", this.scheme.secondary);
    document.documentElement.style.setProperty("--module-bar-ripple-before", this.scheme.secondaryDim);
    document.documentElement.style.setProperty("--module-bar-ripple-after", this.scheme.secondary);
    document.documentElement.style.setProperty("--module-bar-text", this.scheme.onSecondary);
    document.documentElement.style.setProperty("--module-bar-icon", this.scheme.onSecondary);

    document.documentElement.style.setProperty("--settings-btn-bg", this.scheme.surfaceContainerLowest);
    document.documentElement.style.setProperty("--settings-btn-text", this.scheme.onSurfaceVariant);

    document.documentElement.style.setProperty("--icon-button-bg", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--icon-button-icon", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--icon-back-hover-bg", this.scheme.surfaceDimmer);
    document.documentElement.style.setProperty("--media-drag-icon", this.scheme.surface);

    document.documentElement.style.setProperty("--calendar-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--calendar-text", this.scheme.onSurface);
    document.documentElement.style.setProperty("--calendar-hover", this.scheme.surfaceDim);
    document.documentElement.style.setProperty("--calendar-selected", this.scheme.primary);
    document.documentElement.style.setProperty("--calendar-selected-text", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--calendar-disabled", this.scheme.outlineVariant);

    document.documentElement.style.setProperty("--dropzone-bg", this.scheme.surfaceContainerLowest);
    document.documentElement.style.setProperty("--dropzone-border", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--dropzone-remove-text", this.scheme.error);

    document.documentElement.style.setProperty("--list-group-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--list-group-border", this.scheme.outlineVariant);
    document.documentElement.style.setProperty("--list-group-text", this.scheme.onSurface);

    document.documentElement.style.setProperty("--swal-bg", this.scheme.surfaceContainer);

    document.documentElement.style.setProperty("--loader-bg", this.scheme.surfaceContainerHigh);

    document.documentElement.style.setProperty("--dropdown-bg", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--dropdown-text", this.scheme.onSurfaceVariant);
    document.documentElement.style.setProperty("--dropdown-hover-bg", this.scheme.surfaceContainer);
    document.documentElement.style.setProperty("--dropdown-hover-text", this.scheme.onSurface);
    document.documentElement.style.setProperty("--dropdown-border", this.scheme.outlineVariant);

    document.documentElement.style.setProperty("--nav-link-hover-text", this.scheme.onSurfaceVariant);

    document.documentElement.style.setProperty("--chat-sidebar-bg", this.scheme.surfaceContainerLowest);
    document.documentElement.style.setProperty("--chat-bar-bg", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--chat-rcv-message-bg", this.scheme.surfaceContainerLowest);
    document.documentElement.style.setProperty("--chat-input-bg", this.scheme.surfaceContainerLowest);

    document.documentElement.style.setProperty("--channel-message-bg", this.scheme.surfaceContainerLowest);
    document.documentElement.style.setProperty("--channel-reply-bg", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--channel-post-radio-bg", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--channel-post-radio-check", this.scheme.surfaceContainerLowest);

    document.documentElement.style.setProperty("--chat-compose-bg", this.scheme.surfaceBright);

    document.documentElement.style.setProperty("--md-sys-color-outline", this.scheme.outline);

    document.documentElement.style.setProperty("--md-outlined-button-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-hover-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-focus-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-pressed-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-icon-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-icon-size", '20px');
    document.documentElement.style.setProperty("--md-outlined-button-hover-icon-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-focus-icon-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-outlined-button-pressed-icon-color", this.scheme.primary);

    document.documentElement.style.setProperty("--md-filled-button-container-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-filled-button-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-hover-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-focus-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-pressed-label-text-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-icon-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-hover-icon-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-focus-icon-color", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--md-filled-button-pressed-icon-color", this.scheme.onPrimary);

    document.documentElement.style.setProperty("--md-filled-tonal-button-container-color", this.scheme.secondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-label-text-color", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-hover-label-text-color", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-focus-label-text-color", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--md-filled-tonal-button-pressed-label-text-color", this.scheme.onSecondaryContainer);

    document.documentElement.style.setProperty("--md-elevated-button-container-color", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--md-elevated-button-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-elevated-button-hover-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-elevated-button-focus-label-text-color", this.scheme.primary);
    document.documentElement.style.setProperty("--md-elevated-button-pressed-label-text-color", this.scheme.primary);

    document.documentElement.style.setProperty("--primary", this.scheme.primary);
    document.documentElement.style.setProperty("--onPrimary", this.scheme.onPrimary);
    document.documentElement.style.setProperty("--primaryContainer", this.scheme.primaryContainer);
    document.documentElement.style.setProperty("--onPrimaryContainer", this.scheme.onPrimaryContainer);
    document.documentElement.style.setProperty("--secondary", this.scheme.secondary);
    document.documentElement.style.setProperty("--onSecondary", this.scheme.onSecondary);
    document.documentElement.style.setProperty("--secondaryContainer", this.scheme.secondaryContainer);
    document.documentElement.style.setProperty("--onSecondaryContainer", this.scheme.onSecondaryContainer);
    document.documentElement.style.setProperty("--tertiary", this.scheme.tertiary);
    document.documentElement.style.setProperty("--onTertiary", this.scheme.onTertiary);
    document.documentElement.style.setProperty("--tertiaryContainer", this.scheme.tertiaryContainer);
    document.documentElement.style.setProperty("--onTertiaryContainer", this.scheme.onTertiaryContainer);

    document.documentElement.style.setProperty("--success", this.scheme.success);
    document.documentElement.style.setProperty("--onSuccess", this.scheme.onSuccess);
    document.documentElement.style.setProperty("--error", this.scheme.error);
    document.documentElement.style.setProperty("--onError", this.scheme.onError);
    document.documentElement.style.setProperty("--errorContainer", this.scheme.errorContainer);
    document.documentElement.style.setProperty("--onErrorContainer", this.scheme.onErrorContainer);
    document.documentElement.style.setProperty("--outline", this.scheme.outline);
    document.documentElement.style.setProperty("--outline-variant", this.scheme.outlineVariant);

    document.documentElement.style.setProperty("--success-button-bg", this.scheme.success);
    document.documentElement.style.setProperty("--success-button-text", this.scheme.onSuccess);
    document.documentElement.style.setProperty("--success-container-button-bg", this.scheme.successContainer);
    document.documentElement.style.setProperty("--success-container-button-text", this.scheme.onSuccessContainer);

    document.documentElement.style.setProperty("--danger-button-bg", this.scheme.error);
    document.documentElement.style.setProperty("--danger-button-text", this.scheme.onError);
    document.documentElement.style.setProperty("--danger-container-button-bg", this.scheme.errorContainer);
    document.documentElement.style.setProperty("--danger-container-button-text", this.scheme.onErrorContainer);

    document.documentElement.style.setProperty("--warning-button-bg", this.scheme.warning);
    document.documentElement.style.setProperty("--warning-button-text", this.scheme.onWarning);
    document.documentElement.style.setProperty("--warning-container-button-bg", this.scheme.warningContainer);
    document.documentElement.style.setProperty("--warning-container-button-text", this.scheme.onWarningContainer);

    document.documentElement.style.setProperty("--surface-bg", this.scheme.surfaceContainerHighest);
    document.documentElement.style.setProperty("--surface-text", this.scheme.onSurface);

    document.documentElement.style.setProperty("--surfaceContainerHighest", this.scheme.surfaceContainerHighest);
    document.documentElement.style.setProperty("--surfaceContainerHigh", this.scheme.surfaceContainerHigh);
    document.documentElement.style.setProperty("--surfaceContainer", this.scheme.surfaceContainer);
    document.documentElement.style.setProperty("--surfaceContainerLow", this.scheme.surfaceContainerLow);
    document.documentElement.style.setProperty("--surfaceContainerLowest", this.scheme.surfaceContainerLowest);
    document.documentElement.style.setProperty("--onSurface", this.scheme.onSurface);

    document.documentElement.style.setProperty("--surfaceBright", this.scheme.surfaceBright);
    document.documentElement.style.setProperty("--surfaceDim", this.scheme.surfaceDim);

    document.body.style.backgroundColor = this.scheme.surfaceContainer;
    this.ref.detectChanges();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
    setTimeout(() => {
      this.mdw._layoutRefreshContainer.next(true);
    }, 250);
  }

  routeBillingPlans() {
    this.router.navigate(["/billings/plans"]);
  }

  routeBilling() {
    this.router.navigate(["/billings"]);
  }

  /**
   * Logout the user
   */
  logout() {
    this.load.isLoading.next(true);
    this.mdw._templateID.next(null);
    this.ws.logout();
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  async saveToDB(message: Message, recent: boolean, download: boolean, max?: boolean) {
    let table = "messages";
    if(max){
      table = "displayMessages";
    }
    if (message.message.message_id=="d1_4NKsDfSo126286"){
    }

    message.message.gmid = Number(message.message.gmid);
    switch (message.message.status) {
      case message_status_map.DELETED:
        this.indexDBService.updateItem(table, message).onsuccess = (event) => {
          this.ws._incomingMessageContainer.next(message);
        };
        // console.log("DELETING USER GMID");
        // console.log(message);
        this.indexDBService.deleteItemComposite("user", [message.message.chat.id, message.message.reply_to_message_id, message.message.from.id]);
        if (message.message.message_id=="d1_4NKsDfSo126286"){
        }
      break;
      case message_status_map.UPDATED:
        let msg = await this._fetchMessage(message.message.message_id);
        let newMessage;

        if (message.message.message_id=="d1_4NKsDfSo126286"){
        }

        msg.onsuccess = (event) => {
          if (msg.result) {
            // console.log("msg");
            // console.log(msg);
            newMessage = { ...message };
            newMessage.message.from = msg.result.message.from;
            newMessage.message.date = msg.result.message.date;
            newMessage.message.message_id = msg.result.message.message_id;
            newMessage.message.gmid = msg.result.message.gmid;
            if(newMessage.mediaStatus){
              delete newMessage.mediaStatus;
              if (message.message.message_id=="d1_4NKsDfSo126286"){
              }
            }
            newMessage["download"] = download;
            this.indexDBService.updateItem(table, newMessage).onsuccess = (event) => {
              this.ws._incomingMessageContainer.next(newMessage);

              if (message.message.message_id=="d1_4NKsDfSo126286"){
              }


            };
          } else {
            newMessage = message;
            newMessage.message.status = null;
            newMessage["download"] = download;
            this.indexDBService.addMessage(table, newMessage)
            .then(inserted => {
              if (inserted) {
                message["download"] = download;
                this.ws._incomingMessageContainer.next(message);
              }
            });



          }
        };
      break;
      default:

        this.indexDBService.addMessage(table, message)
        .then(inserted => {
          if (inserted) {
            message["download"] = download;
            message["recent"] = recent;

                 this.ws._incomingMessageContainer.next(message);
          }
          });
      break;
    }
  }

  private _fetchMessage(message_id) {
    const requestMessage = this.indexDBService.getItem("messages", message_id);

    return requestMessage;
  }

  getPoll(id){
    this.mdw._getPollMethodContainer.next(id);
  }

  channelsSearchs(value){
    const filter = value.toUpperCase();
    const dropDownItems: any = document.querySelectorAll('#list');
    let hiddinItems = 1;
    for(let i=0; i < dropDownItems.length; i++){
      const txtValue = dropDownItems[i].textContent;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          dropDownItems[i].style.display = "";
        } else {
          dropDownItems[i].style.display = "none";
          hiddinItems = hiddinItems + 1;
        }
    }
    this.allChatsHidden= hiddinItems === this.chatList.length;
  }

  chatsIntialize(){
    const requestChannel = this.indexDBService.getItemList(
      "chats",
      "type",
      IDBKeyRange.only("Channel")
    );
    if(requestChannel){
      requestChannel.onsuccess = (event) => {
        if(requestChannel.result){
          this.chatList = this.moveMainChannelToTop(requestChannel.result);
          this.default_chat = this.chatList.find(
            (e: any) => e.id == localStorage.getItem("chat_id")
          );
        }
      };
    }
  }

  colorInitialize(){
    try {
      const appHome = this.indexDBService.getItem("app", 'home');
      appHome.onsuccess = async (event) => {
        if (appHome.result) {
          this.originalColors['color_schema'] = appHome.result.app.tabs.color_schema;
          this.originalColors['color_schema_ios'] = appHome.result.app.tabs.color_schema_ios;
          this.originalColors['light'] = localStorage.getItem("mode")[15]==c16.LIGHT? true:false
          this.isAndroid = localStorage.getItem("mode")[0] == c1.ANDROID? true: false;
        }
      }
    } catch (error) {
    }

  }

  logoInitialize(){
    const appInfo = this.indexDBService.getItem("item", "app_info");
    if(appInfo){
      appInfo.onsuccess = async (event) => {
        if (appInfo.result) {
          this.appInfo = appInfo.result;
          if (this.appInfo.image){
            var image = new Image();
            image.crossOrigin = "Anonymous";
            image.setAttribute('crossOrign','*')
            image.src = this.appInfo.image;
            if (this.appInfo.color_logo_bg == null) {
              this.appInfo.color_logo_bg = "#ffffff"
            }
          }
        }
      }
    }
  }

  setTemplate(data) {
    let groupFormValues = { ...data };

    let type = "store";

    this.ws.processMethod(
      new SetTemplateFunctionMethod(data.id, this.template_id, type, groupFormValues)
    );
  }

  async sendAppConfigFromTemplateToServer(data, component_ref) {
    if ( data.appConfig &&  data.appConfig.app &&  data.appConfig.app.system &&  data.appConfig.app.system.app_info){
      let chat :Chat;
      chat = {id: localStorage.getItem('chat_id'), title: data.appConfig.app.system.app_info.channel_name };
      let photo;
      photo = data.appConfig.app.system.app_info.image_id? {id:data.appConfig.app.system.app_info.image_id} : null;
      if(photo){
        chat['photo']=photo;
      }
      let meta = {};
      meta['currency'] = data.appConfig.app.system.app_info.currency ?  data.appConfig.app.system.app_info.currency : 'USD';
      if (meta){
        chat['meta'] = meta;
      }
     this.ws.processMethod(new SetChat(chat));
    }
      this.ws.processMethod(
      new SetAppConfig(
        component_ref,
        2,
        data.appConfig,
        data.onlineAppConfig,
        data.onlineChannelConfig
      )
    );
  }
  goToReply(reply){
    this.replies = this.replies.filter(e => (e.message.message_id != reply.message.message_id && !(e.message.chat.id == reply.message.chat.id && e.message.from.id == reply.message.from.id)));
    this.router.navigate(["/message"]).then(async () => {
      let chat = this.indexDBService.getItem('chats', reply.message.chat.id);
      chat.onsuccess = (event) => {
        if (chat.result) {
          this.mdw._chatSelectedContainer.next(chat.result);
          let message = this.indexDBService.getItem('messages', reply.message.reply_to_message_id);
          message.onsuccess = (event) => {
            if (message.result) {
              this.mdw._selectedMessageRepliesContainer.next(message.result);
            }
          }
        }
      }
    })
  }

  startover(){
    window.open('https://nandbox.com/getstarted/', '_self');
  }

  buildForMe(){
    window.open('https://calendly.com/d/crg5-dgt-qqz/let-s-talk-about-your-app');
  }

  deleteSampleData(){
    this.ws.processMethod(new RunAppConfigTemplate(null));
  }

  setAppVersionSample() {
    const appVersion = this.indexDBService.getItem("item", "version");
    appVersion.onsuccess = (event) => {
      if (appVersion.result) {
        this.appVersion = appVersion.result;
        this.appVersion['sample'] = true;
        this.indexDBService.updateItem("item", this.appVersion);
      }
    }
  }

  getLogoBackground(){
    return this.isAndroid ? this.originalColors.color_schema.schemes.light.icLauncherBackground : '#ffffff';
  }

  revokePlan() {
    this.ws.processMethod(new RevokeSubscription());
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.messageListSubscription) {
      this.messageListSubscription.unsubscribe();
    }
    if (this.menuPageResponseSubscription) {
      this.menuPageResponseSubscription.unsubscribe();
    }
    if (this.chatListSubscription) {
      this.chatListSubscription.unsubscribe();
    }
    if (this.selectedChatSubscription) {
      this.selectedChatSubscription.unsubscribe();
    }
    if (this.userListSubscription) {
      this.userListSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
    if (this.getAppConfigOnceSubscription) {
      this.getAppConfigOnceSubscription.unsubscribe();
    }
    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.getStoreSubscription) {
      this.getStoreSubscription.unsubscribe();
    }
    if (this.getStoreMethodSubscription) {
      this.getStoreMethodSubscription.unsubscribe();
    }
    if (this.pollSubscription) {
      this.pollSubscription.unsubscribe();
    }
    if (this.getPollMethodSubscription) {
      this.getPollMethodSubscription.unsubscribe();
    }
    if (this.getPollSubscription) {
      this.getPollSubscription.unsubscribe();
    }
    if (this.getAppConfigSubscription) {
      this.getAppConfigSubscription.unsubscribe();
    }
    if (this.getAppInfoSubscription){
      this.getAppInfoSubscription.unsubscribe();
    }
    if (this.setAppConfigResponseSubscription){
      this.setAppConfigResponseSubscription.unsubscribe();
    }
    if (this.appBuilderSubscription){
      this.appBuilderSubscription.unsubscribe();
    }
    if (this.tempModeStatusSubscription){
      this.tempModeStatusSubscription.unsubscribe();
    }
    if(this.resetAppConfigSubscription){
      this.resetAppConfigSubscription.unsubscribe();
    }
    if(this.sendChatToAISubscription){
      this.sendChatToAISubscription.unsubscribe();
    }
    if(this.likeMessageAckSubscription){
      this.likeMessageAckSubscription.unsubscribe();
    }
    if(this.expiredSubscription){
      this.expiredSubscription.unsubscribe();
    }
    if(this.databaseOpenSubscription){
      this.databaseOpenSubscription.unsubscribe();
    }
    if(this.updateBuilderComponentSubscription){
      this.updateBuilderComponentSubscription.unsubscribe();
    }
    if(this.schemeChangeSubscription){
      this.schemeChangeSubscription.unsubscribe();
    }

    if(this.saveAppConfigSignupSubscription){
      this.saveAppConfigSignupSubscription.unsubscribe();
    }


    if(this.saveAppConfigChangeTemplateSubscription){
      this.saveAppConfigChangeTemplateSubscription.unsubscribe();
    }
    if(this.setStoreSubscription){
      this.setStoreSubscription.unsubscribe();
    }
    this.mdw._templateID.next(null);
  }
}
