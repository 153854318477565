import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TabNames, c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { listChatTags } from "src/app/core/services/outbound";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-post-tags-component",
  templateUrl: "./tab-post-tags-component.component.html",
  styleUrls: ["./tab-post-tags-component.component.scss"],
})
export class TabPostTagsComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  component_ref: any;
  offCanvasSubscription: any;
  currentTabSubscription: any;
  listChatTagsSubscription: any;
  verifySubscription: any;
  enablePostTags = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  post_tag_values;
  iconSize = {
    l: "Large",
    m: "Medium",
    s: "Small",
  };
  selectedIconSize = "m";
  selectedIconBg;
  colors;
  modalRef?: BsModalRef;
  tabList = {};
  sortedTabList = [];
  page: Array<any> = [];
  data: any;
  off_canvas_key: string = "tab";
  isAndroid: boolean;
  component_id: string;
  field_id: string;
  chatID: string;
  pageSize = 1000;
  currentPage = 0;
  userList = {};

  ngOnInit() {
    this.field_id = "tab_post_tags";
    this.chatID = localStorage.getItem("chat_id");
    this.component_ref = this.mdw.makeRef(16);
    this.userList = {};
    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.currentTab &&
          this.tab_id &&
          data.currentTab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );

    this.listChatTagsSubscription = this.ws.listChatTagsContainer$.subscribe(
      (listTagsData) => {
        if (listTagsData.ref === this.component_ref) {
          if (listTagsData) {
            this.currentPage = listTagsData.page_number;

            listTagsData.tags.forEach((tag: any) => {
              this.userList[String(tag.id)] = tag;
            });
            this.page = [];
            for (let i = 0; i < this.pageSize; i++) {
              if (
                Object.keys(this.userList)[
                  i + (this.currentPage - 1) * this.pageSize
                ]
              ) {
                this.page.push(
                  this.userList[
                    Object.keys(this.userList)[
                      i + (this.currentPage - 1) * this.pageSize
                    ]
                  ]
                );
              }
            }
          }
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          this.ws.processMethod(
            new listChatTags(0, this.chatID, this.component_ref)
          );
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;

      if (this.tab.post_tag_values && this.tab.post_tag_values.length > 0) {
        this.enablePostTags = true;
        this.post_tag_values = this.tab.post_tag_values;
      }
    }
  }

  togglePostTags(event) {
    this.enablePostTags = event;
    if(!this.enablePostTags){
      this.tab.post_tag_values = [];
      this.updateTabData();
    }
  }

  updatePostTags() {
    this.tab.post_tag_values = this.post_tag_values;
    this.updateTabData();
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data['target'] ='tabSetting'


    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
    if (this.listChatTagsSubscription) {
      this.listChatTagsSubscription.unsubscribe();
    }
  }
}
