
<!-- Android-->
<div *ngIf="isAndroid">
  <div class="card p-0 m-0 rounded-0">
    <img [src]="theme?.emptyImageTop" class="card-img" alt="empty">
    <div class="card-img-overlay p-0 rounded-0">
  <div class="row p-3 d-flex align-items-center justify-content-center" [ngStyle]="bgColor">
    <div class="col-1">
      <i class="{{icon}} responsave-font-size_xl" [ngStyle]="burgerColor"></i>
    </div>
    <div class={{col}} >
      <p class="card-title text-start mb-0 text-truncate responsave-font-size_xl "
        style="max-width: 95%;"  [ngStyle]="appNameColor"  > {{name}}
      </p>
    </div>
    <div class="col-1" >
      <i class="{{trailing_icon1}} responsave-font-size_xl" [ngStyle]="otherTextColor"></i>
    </div>
    <div class="col-1" >
      <i class="{{trailing_icon2}} responsave-font-size_xl" [ngStyle]="otherTextColor"></i>
    </div>
    <div class="col-1" >
      <i class="{{trailing_icon3}} responsave-font-size_xl" [ngStyle]="otherTextColor"></i>
    </div>
    <div class="col-1"  *ngIf="trailing_icon4 !=null">
      <i class="{{trailing_icon4}} responsave-font-size_xl" [ngStyle]="otherTextColor"></i>
    </div>

    <div class="col-1"  *ngIf="trailing_icon5 !=null">
      <i class="{{trailing_icon5}} responsave-font-size_xl" [ngStyle]="otherTextColor"></i>
    </div>

    <div class="col-1"  *ngIf="trailing_icon6 !=null">
      <i class="{{trailing_icon6}} responsave-font-size_xl" [ngStyle]="otherTextColor"></i>
    </div>

      </div>
    </div>
  </div>
</div>

<!-- IOS Small Title -->
<div *ngIf="!isAndroid && (isLarge !== c7_Large || isComponent)">
  <div class="card p-0 m-0 rounded-0 " [ngStyle]="bgColor">
    <img [src]="theme?.emptyImageTop" class="card-img" alt="empty">
    <div class="card-img-overlay p-0 m-0">
      <div class="row p-2"  >
        <div class="col-2">
          <i *ngIf="isback" class="fas fa-angle-left font-size-14 responsave-font-size_xl" [ngStyle]="TopBarTextColor"></i>
        </div>
        <div class="col-8">
          <p class="responsave-font-size_xl fw-bold text-black text-center"> {{ title }}</p>
        </div>

        <div class="col-2">

        </div>
      </div>
    </div>
  </div>
</div>

<!-- IOS Large Title -->
<div *ngIf="!isAndroid && isLarge === c7_Large && !isComponent ">
  <div class="card p-0 m-0 rounded-0" [ngStyle]="bgColor">
    <img [src]="theme?.emptyImageTop" class="card-img" alt="empty">
    <div class="card-img-overlay p-0 m-0 rounded-0">
      <div class="row p-2">
        <div class="col-2">
          <i *ngIf="isback" class="fas fa-angle-left font-size-14 responsave-font-size_xl" [ngStyle]="TopBarTextColor"></i>
        </div>
        <div class="col-8">
        </div>
        <div class="col-2">
        </div>
      </div>

      <!-- <div class="row p-5" >
        <p class="fs-6 fw-bolder text-black text-center"> {{ title }}</p>
      </div> -->
    </div>
  </div>
  <div *ngIf="isLarge === c7_Large">
    <div class="card p-0 m-0 rounded-0 " [ngStyle]="bgColor">
      <img [src]="theme?.emptyImageTitle" class="card-img" alt="empty">
      <div class="card-img-overlay m-0 p-0 rounded-0">
        <div class="row ps-3 p-0 align-text-middle" [ngStyle]="TopBarTextColor">
          <p class="fw-bolder fs-4 pb-2">  {{ title }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
