import { Component, ViewChild,Input } from '@angular/core';
import { PointExtensions } from '@foblex/2d';
import { EFConnectionBehavior, EFMarkerType, FCanvasComponent } from '@foblex/flow';
import { MiddlwareService } from 'src/app/core/services/middleware.service';
import { Tab } from 'src/app/core/services/interface';
import { common_components_map, TabNames, wireframeConst } from 'src/app/core/services/constants';

@Component({
  selector: 'app-wireframe',
  templateUrl: './wireframe.component.html',
  styleUrl: './wireframe.component.scss',
})

export class WireframeComponent {
  nodes = [];
  public eConnectionBehaviour = EFConnectionBehavior;
  protected readonly eMarkerType = EFMarkerType;
  @ViewChild(FCanvasComponent, { static: true })
public fCanvas!: FCanvasComponent;

@Input() current_tab: Tab;
sortedTabList = [ ];
tabLinks = [];
links = [];
getAppConfigSubscription: any;
tabList = {};
no_col_prev: number=0;
hx: number;
hy: number;
offsetY =0;
prevOffsetY=0;
column = [];
no_col: number =0;
position :any;

constructor(
    private mdw: MiddlwareService
  ){}
  ngOnInit() {
    this.getAppConfigSubscription = this.mdw.getAppConfigContainer$.subscribe(async (data) => {
      if (data) {
        try {
          if (this.current_tab){
            this.wireframeModule(this.current_tab)
          }else if (this.current_tab==null)  {
            this.wireframeAllApp()
          }
        } catch (error) {
        }
      }
    });
  }
  tabLinkDir(tab){
    let dir : string;
    switch (tab.cat){
      case 'home':
      dir = 'auto'
      break;
      case 'side':
      dir= 'auto'
      break
    }
    return dir
  }
  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }
  public onLoaded(): void {
    this.fCanvas.fitToScreen(PointExtensions.initialize(20, 20), false);
  }
  async wireframeModule(tab){
    let dx = wireframeConst.DX
    let dy = wireframeConst.DY
    this.hx = wireframeConst.HXINIT
    this.hy = wireframeConst.HYINIT + wireframeConst.DY

    tab.outputIds = []; // Initialize an empty array for outputIds
    tab.outputIds.push(tab.id);
    tab['position'] = {'x':this.hx , 'y':this.hy}
    this.tabList[tab.id] = tab;

    if (tab && tab.web && tab.web.module && tab.web.module.tabs){
    this.placeSubTabs(tab, this.hx,this.hy,dx,dy)
    }

    this.links.forEach(link => {
      link.out = this.mdw.makeRef(16);
      //  link.out = link.outputId
    });
    Object.values(this.tabList).forEach((tab: any) => {

      if (tab.outputIds){
        tab.outputIds =[]
      }
    })

    Object.values(this.tabList).forEach((tab: any) => {
      this.links.forEach(l => {
        if (tab.id === l.outputId) {
          tab.outputIds.push(l.out); // Push the matching link.out into tab.outputIds
        }
      });
      this.sortedTabList.push(tab);
    })
    this.tabLinks =this.links;
    this.fCanvas.resetScaleAndCenter(false);
    console.log("Links", this.tabLinks);
    console.log("sortedTabList", this.sortedTabList);

  }

  // Recursive function to place subTabs
placeSubTabs(tab: any, currentX: number, currentY: number, dx, dy) {
  let module = tab.web?.module;
  if (module) {
    let subTabs = module.tabs;
    this.links = module.links;
    // Add the inputIds as links (tab point to inputs)
    module.inputIds.forEach((inputId: string) => {
    let link = {outputId: tab.id, inputId:inputId}
    this.links.push(link)
    });
    // Start following links from input subTabs
  this.followLinks(this.links,subTabs, tab.id, currentX + dx, currentY, dx, dy, 0);
  }

}

// Recursive function to follow links and place subTabs
followLinks(links, subTabIds, outputId: string, startX: number, startY: number, dx, dy, level) {
  let linkX = 0;
  let linkY = 0;
  let offsetY = 0;
  let lowestIndex = 0;
  let linkedTabs = links.filter((link: any) => link.outputId === outputId);
  linkedTabs.forEach((link, index) => {
    if (!this.tabList[link.inputId]) {
      let inputSubTabId = subTabIds.find((stId: any) => stId === link.inputId);
      if (inputSubTabId) {
        linkX = startX + dx;
        linkY = startY + index * dy + offsetY * dy;
        let inputSubTabModule = inputSubTabId;
        let inputSubTab = structuredClone(common_components_map[inputSubTabModule]);
        inputSubTab['position'] = { x: linkX, y: linkY };
        this.tabList[inputSubTab.id] = inputSubTab;
        offsetY += this.followLinks(links, subTabIds, inputSubTab.id, linkX, linkY, dx, dy, level + 1);
        lowestIndex = (linkedTabs.length - 1);
      }
    }
  });
  return lowestIndex;
}



  async wireframeAllApp(){
    let data = await this.mdw.sendAppConfig(true, true);
    data.onlineChannelConfig.app.tabs.tabs.push(...data.onlineAppConfig.app.tabs.tabs)


    // home
    let dx = wireframeConst.DX
    let dy = wireframeConst.DY
    this.hx = wireframeConst.HXINIT
    this.hy = wireframeConst.DY


    let sx = this.hx + (2 * dx)
    let sy = 20;

    let homeTabs = data.onlineChannelConfig.app.tabs.tabs
    let sideTabs = data.onlineAppConfig.app.tabs.tabs;

    // homeTabs.forEach(tab => {
    //   tab['position'] = {'x':this.hx , 'y':this.hy}
    //   this.tabList[tab.id] = tab;
    //   // expand the module
    //   // if (tab && tab.web && tab.web.module && tab.web.module.tabs){
    //   // this.mdw.placeSubTabs(tab, this.hx,this.hy,dx,dy)
    //   // }
    //   this.hy = this.hy + dy;
    // });

    let mainApp: any

    mainApp = structuredClone(common_components_map[TabNames.SUB_MAIN]);
    mainApp['position'] = { x: 20, y: dy };
    this.tabList[mainApp.id] = mainApp;

    homeTabs.forEach(tab => {
      tab.outputIds = []; // Initialize an empty array for outputIds
      tab.outputIds.push(tab.id);
      this.tabList[tab.id] = tab;
      //home to tab link
      let homeLink = {outputId:TabNames.SUB_MAIN, inputId:tab.id}
      this.links.push(homeLink)

        if (tab && tab.web && tab.web.module && tab.web.module.tabs){

        let subTabIds =  tab.web.module.tabs;
        subTabIds.forEach(subTabId => {
          let subTabModule = subTabId;
          let subTab = structuredClone(common_components_map[subTabModule]);
          this.tabList[subTab.id] = subTab;
        });

        // Add the inputIds as links (tab point to inputs)
        tab.web.module.inputIds.forEach((inputId: string) => {
          let link = {outputId: tab.id, inputId:inputId}
          tab.web.module.links.push(link)
          });
        this.links = [...this.links,...tab.web.module.links]

        // this.placeSubTabs(tab, this.hx,this.hy,dx,dy)
      }
    });

    this.links.forEach(link => {
      link.out = this.mdw.makeRef(16);
    });

    this.followTabLinks(this.links, Object.values(this.tabList), "sub_main", this.hx,this.hy,dx,dy, 0);
    this.tabLinks = this.links

    // sideTabs.forEach(tab => {
    //   tab['position'] = {'x':sx , 'y':sy}
    //   sx = sx + 2* dx;
    //   this.tabList[tab.id] = tab;
    // });

    // let matchingTabs = await this.mdw.extractMatchingNextTabinSide(Object.values(this.tabList),data.onlineChannelConfig.app.menus,true)
    // // console.log("MATCHING ", matchingTabs);
    // if (matchingTabs){
    //   matchingTabs.forEach(tab => {
    //     this.tabLinks.push({'inputId': tab.id, 'outputId': tab.fromButton});
    //     if(this.tabList[tab.fromTab].outputIds){
    //       this.tabList[tab.fromTab].outputIds.push(tab.fromButton);
    //     } else {
    //       this.tabList[tab.fromTab].outputIds = [tab.fromButton];
    //     }
    //   });
    // }

      // this.followTabLinks(this.tabLinks, this.tabList, "0", this.hx,this.hy,dx,dy, 0);
//   loop to home tabs
    Object.values(this.tabList).forEach(tab => {
      this.sortedTabList.push(tab);
    })

    this.sortedTabList.forEach(tab => {
      this.links.forEach(l => {
        if (tab.id === l.outputId) {
          tab.outputIds.push(l.out); // Push the matching link.out into tab.outputIds
        }
      });
    });

    // console.log("sortedtabList ", this.sortedTabList);
    this.fCanvas.resetScaleAndCenter(false);
    console.log("Links", this.tabLinks);
    console.log("sortedTabList", this.sortedTabList);

  }

followTabLinks(links, subTabs, outputId: string, startX: number, startY: number, dx, dy, level){
  let linkX = 0;
    let linkY = 0;
    let offsetY = 0;
    let lowestIndex = 0;
    let inputSubTab: any
    let linkedTabs = links.filter((link: any) => link.outputId === outputId);
    linkedTabs.forEach((link, index) => {
      if (!this.tabList[link.inputId].position) {
        inputSubTab = subTabs.find((tab: any) => tab.id === link.inputId);
        if (inputSubTab) {
          linkX = startX + dx;
          linkY = startY + index * dy + offsetY * dy;
          lowestIndex = index;
          inputSubTab['position'] = { x: linkX, y: linkY };
          this.tabList[inputSubTab.id] = inputSubTab;
          offsetY += this.followTabLinks(links, subTabs, inputSubTab.id, linkX, linkY, dx, dy, level + 1);
        }
      }
    });
    return lowestIndex + offsetY;
  }
}
