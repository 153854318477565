<div
  *ngIf="loading"
  id="preloader"
  class="bg-transparent d-flex justify-content-center align-items-center"
>
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-0 shadow-none">
        <div class="card-body">
          <form [formGroup]="userListForm" (ngSubmit)="onSubmit()" class="mb-3">
            <div class="row g-3">
              <div class="col-xxl-4 col-lg-6">
                <div
                  class="input-group"
                  [ngClass]="{
                    'is-invalid': submitted && f.search_value.errors
                  }"
                >
                  <select
                    class="form-select"
                    style="max-width: 90px"
                    formControlName="search_type"
                    (change)="searchTypeChanged()"
                  >
                    <option value="name" selected>
                      {{ "GENERALS.NAME" | translate }}
                    </option>
                    <option value="member_id">
                      {{ "USERS.USER" | translate }}
                    </option>
                    <option value="msisdn">
                      {{ "GENERALS.LOGIN" | translate }}
                    </option>
                  </select>
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="
                      user_search_placeholder_map[
                        userListForm.get('search_type').value
                      ] | translate
                    "
                    formControlName="search_value"
                  />
                </div>
                <div
                  *ngIf="submitted && f.search_value.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.search_value.errors.pattern">
                    {{ "GENERALS.PLEASEENTERVALIDID" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-xxl-2 col-lg-6">
                <select class="form-control select2" formControlName="status">
                  <option value="">
                    {{ "GENERALS.SELECTSTATUS" | translate }}
                  </option>
                  <option value="A">{{ status_map["A"] | translate }}</option>
                  <option value="N">{{ status_map["N"] | translate }}</option>
                  <option value="B">{{ status_map["B"] | translate }}</option>
                </select>
              </div>
              <div class="col-xxl-2 col-lg-4">
                <select class="form-control select2" formControlName="role_id">
                  <option value="">{{ "USERS.SELECTROLE" | translate }}</option>
                  <option value="0">{{ user_role_map[0] | translate }}</option>
                  <option value="1">{{ user_role_map[1] | translate }}</option>
                  <option value="2">{{ user_role_map[2] | translate }}</option>
                  <option value="7">{{ user_role_map[7] | translate }}</option>
                  <option value="3">{{ user_role_map[3] | translate }}</option>
                  <option value="4">{{ user_role_map[4] | translate }}</option>
                  <option value="5">{{ user_role_map[5] | translate }}</option>
                  <option value="6">{{ user_role_map[6] | translate }}</option>
                </select>
              </div>
              <div class="col-xxl-2 col-lg-4">
                <div class="mb-3">
                  <input
                    class="form-control"
                    [placeholder]="'GENERALS.SELECTDATE' | translate"
                    name="dp"
                    bsDatepicker
                    [bsConfig]="{showWeekNumbers: false}"
                    autocomplete="off"
                    formControlName="date"
                  />
                </div>
              </div>
              <div class="col-xxl-2 col-lg-4">
                <button type="submit" class="btn btn-soft-secondary w-100">
                  <i class="bx bx-search-alt align-middle"></i>
                  {{ "GENERALS.SEARCH" | translate }}
                </button>
              </div>
            </div>
          </form>

          <div class="row justify-content-center">
            <div class="col-xl-12">
              <div
                [ngStyle]="{ height: mode === 'selector' ? '60vh' : 'auto' }"
              >
                <ngx-simplebar id="scroll" class="h-100" #scrollRef>
                  <div class="table-responsive me-1">
                    <table
                      class="table table-striped align-middle table-nowrap table-hover"
                    >
                      <thead class="table-light">
                        <tr>
                          <th scope="col" class="text-center rounded-top-4 rounded-end-0">#</th>
                          <th scope="col">{{ "GENERALS.NAME" | translate }}</th>
                          <th scope="col" class="text-center">
                            {{ "USERS.USERID" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "GENERALS.COUNTRY" | translate }}
                          </th>
                          <th scope="col">
                            {{ "USERS.JOINEDDATE" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "USERS.OS" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "USERS.ROLE" | translate }}
                          </th>
                          <th scope="col" class="text-center">
                            {{ "GENERALS.STATUS" | translate }}
                          </th>
                          <th scope="col" class="text-center rounded-top-4 rounded-start-0">
                            {{ "GENERALS.ACTION" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of page">
                          <td class="text-center">
                            <img
                              class="rounded-circle avatar-xs"
                              src="{{ data.media?.photo?.url }}"
                              onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
                            />
                          </td>
                          <td>
                            <p class="mb-0 fw-bold">{{ data.name }}</p>
                          </td>
                          <td class="text-center">
                            <p class="mb-0 font-size-14 fw-bold">
                              {{ data.member_id }}
                            </p>
                          </td>
                          <td>
                            <div
                              class="row justify-content-center d-flex align-items-center"
                            >
                              <div class="col-4 ml-auto">
                                <div style="width: 25px">
                                  <img
                                    *ngIf="data.country_code != null"
                                    class="img-fluid"
                                    style="height: 14px"
                                    src="https://flagcdn.com/h20/{{
                                      data.country_code.toLowerCase()
                                    }}.webp"
                                  />
                                </div>
                              </div>

                              <p
                                class="col-4 mr-auto font-size-11 fw-bold mb-0"
                              >
                                {{ getCountryName(data.country_code) }}
                              </p>
                            </div>
                          </td>
                          <td>
                            <p class="mb-0 font-size-14 fw-bold">
                              {{ data.created_date }}
                            </p>
                          </td>
                          <td class="text-center">
                            <p class="mb-0 font-size-14 fw-bold">
                              <i
                                class="font-size-24"
                                ngClass="{{ device_os_map[data.os] }}"
                              ></i>
                            </p>
                          </td>
                          <td class="text-center">
                            <p
                              class="badge font-size-10 mb-0"
                              ngClass="{{ user_role_color_map[data.role_id] }}"
                            >
                              {{ user_role_map[data.role_id] | translate }}
                            </p>
                          </td>
                          <td class="text-center">
                            <p
                              class="badge font-size-10 mb-0"
                              ngClass="{{ status_color_map[data.status] }}"
                            >
                              {{ status_map[data.status] | translate }}
                            </p>
                          </td>
                          <td class="text-center">
                            <div class="form-check d-flex align-items-center justify-content-center">
                              <input
                                type="checkbox"
                                id="formCheck1"
                                class="form-check-input"
                                (change)="selectUsers(data)"
                              />

                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ngx-simplebar>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="page.length == 0">
            <div class="col text-center mt-4 mb-0">
              <h6>
                {{ search_message }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div class="ps-0 mt-4">
                <md-outlined-button id="users_pagination_selector_back" (click)="backPage()">
                  <i slot="icon" class="mdi mdi-chevron-left font-size-18"></i>
                  <p class="mb-0">{{ "GENERALS.PREV" | translate }}</p>
                </md-outlined-button>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <p class="avatar-xs invisible mb-0 mt-4" id="usersShowPageNumberSelector">
                <span
                  class="avatar-title rounded-circle primary-text bg-transparent border border-outline"
                >
                  {{ currentPage }}
                </span>
              </p>
            </div>
            <div class="col-5">
              <div class="d-flex justify-content-end ps-0 mt-4">
                <md-outlined-button
                  trailing-icon
                  id="users_pagination_selector_next"
                  (click)="nextPage()"
                >
                  <span>{{ "GENERALS.NEXT" | translate }}</span>
                  <i slot="icon" class="mdi mdi-chevron-right font-size-18"></i>
                </md-outlined-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger w-md rounded-pill me-2" (click)="cancelEvent()">Cancel</button>
  <button
    class="btn btn-success w-md rounded-pill"
    [disabled]="isObjEmpty()"
    (click)="insertEvent()"
  >
    Done
  </button>
</div>
