<div
  *ngIf="loading"
  id="preloader"
  class="bg-transparent d-flex justify-content-center align-items-center"
>
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-0 shadow-none">
        <div class="card-body">
          <form
            [formGroup]="productListForm"
            (ngSubmit)="onSubmit()"
            class="mb-3"
          >
            <div class="row g-2">
              <div class="col-xxl-4 col-lg-6">
                <div class="input-group">
                  <select
                    class="form-select"
                    style="max-width: 120px"
                    formControlName="search_type"
                  >
                    <option value="name" selected>
                      {{ "GENERALS.NAME" | translate }}
                    </option>
                    <option value="product">
                      {{ "PRODUCTS.PRODUCT" | translate }}
                    </option>
                  </select>
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="
                      user_search_placeholder_map[
                        productListForm.get('search_type').value
                      ] | translate
                    "
                    formControlName="search_value"
                  />
                </div>
              </div>
              <div class="col-xxl-2 col-lg-6">
                <select class="form-control select2" formControlName="status">
                  <option value="">
                    {{ "GENERALS.SELECTSTATUS" | translate }}
                  </option>
                  <option value="A">{{ status_map["A"] | translate }}</option>
                  <option value="N">{{ status_map["N"] | translate }}</option>
                  <option value="B">{{ status_map["B"] | translate }}</option>
                </select>
              </div>
              <div class="col-xxl-2 col-lg-6">
                <select class="form-control select2" formControlName="category">
                    <ng-container *ngIf="className && className.length > 0 ">
                      <option value="">
                        {{ "GENERALS.SELECTTYPE" | translate }}
                      </option>
                      <option *ngIf="className.includes('product')" value="product">
                        {{ "PRODUCTS.PRODUCT" | translate }}
                      </option>
                      <option *ngIf="className.includes('booking')" value="booking">
                        {{ "PRODUCTS.BOOKING" | translate }}
                      </option>
                      <option *ngIf="className.includes('event')" value="event">
                        {{ "PRODUCTS.EVENT" | translate }}
                      </option>
                      <option *ngIf="className.includes('digital')" value="digital">
                        {{ "PRODUCTS.DIGITAL" | translate }}
                      </option>
                    </ng-container>
                </select>
              </div>
              <div class="col-xxl-2 col-lg-4">
                <div class="mb-3">
                  <input
                    class="form-control"
                    [placeholder]="'GENERALS.SELECTDATE' | translate"
                    name="dp"
                    bsDatepicker
                    [bsConfig]="{showWeekNumbers: false}"
                    autocomplete="off"
                    formControlName="created_date"
                  />
                </div>
              </div>
              <div class="col-xxl-2 col-lg-4 offset-xxl-0 offset-lg-4">
                <button type="submit" class="btn btn-soft-secondary w-100">
                  <i class="bx bx-search-alt align-middle"></i>
                  {{ "GENERALS.SEARCH" | translate }}
                </button>
              </div>
            </div>
          </form>
          <!-- Nav tabs -->

          <div class="row justify-content-center">
            <div class="col-xl-12">
              <div
                [ngStyle]="{ height: mode === 'selector' ? '60vh' : 'auto' }"
              >
                <ngx-simplebar id="scroll" class="h-100" #scrollRef>
                  <div class="row g-4 w-100">
                    <div
                      class="col-xxl-2 col-lg-3 col-md-4 col-sm-6"
                      *ngFor="let data of page"
                      (click)="selectProduct(data)"
                    >
                      <div
                        class="card p-2 border shadow-none"
                        [ngClass]="{
                          'border-primary': isSelected(data.id)
                        }"
                      >
                        <i
                          class="fas fa-check-circle position-absolute top-0 end-0 m-1 font-size-18 text-primary"
                          *ngIf="isSelected(data.id)"
                        ></i>
                        <div class="p-2">
                          <h5 class="text-truncate">
                            <a class="text-dark">{{ data.name }}</a>
                          </h5>
                          <p class="text-muted mb-0">{{ data.id }}</p>
                        </div>

                        <div
                          style="height: 150px"
                          class="position-relative d-flex align-items-center justify-content-center"
                        >
                          <img
                            src="{{ data?.image[0]?.url }}"
                            class="rounded"
                            style="max-width: 100%; max-height: 100%"
                            onerror="this.onerror=null; this.src='assets/images/group/group-empty.webp'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ngx-simplebar>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="page.length == 0">
            <div class="col text-center mt-4 mb-0">
              <h6>
                {{ search_message }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <ul class="ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-1 p-2"
                  id="pagination_back"
                  (click)="backPage()"
                >
                  <i class="mdi mdi-chevron-left font-size-18"></i>
                  <p class="mb-0">{{ "GENERALS.PREV" | translate }}</p>
                </button>
              </ul>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <p class="avatar-xs invisible mb-0" id="showPageNumber">
                <span class="avatar-title rounded-circle bg-primary">
                  {{ currentPage }}
                </span>
              </p>
            </div>
            <div class="col-5">
              <ul class="d-flex justify-content-end ps-0 mt-4">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-2 pe-1"
                  id="pagination_next"
                  (click)="nextPage()"
                >
                  <p class="mb-0">{{ "GENERALS.NEXT" | translate }}</p>
                  <i class="mdi mdi-chevron-right font-size-18"></i>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger w-md rounded-pill me-2" (click)="cancelEvent()">Cancel</button>
  <button
    class="btn btn-success w-md rounded-pill"
    [disabled]="isObjEmpty()"
    (click)="insertEvent()"
  >
    Done
  </button>
</div>
