<div class="mb-3">
  <label [for]="'search' + id"> Location </label>
  <div class="mb-3">
    <input
      type="text"
      class="form-control input-rounded input-focus-border"
      (keydown.enter)="$event.preventDefault()"
      placeholder="Search Location"
      type="text"
      [value]="address"
      [id]="'search' + id"
      [ngClass]="{ 'is-invalid': submitted && errors }"
      #search
    />
    <div *ngIf="submitted && errors" class="invalid-feedback">
      <div *ngIf="errors.required">
        {{ "GENERALS.LOCATIONREQUIRED" | translate }}
      </div>
    </div>
  </div>
  <div class="overflow-hidden rounded-3">
    <google-map
      #myGoogleMap
      height="300px"
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="options"
      (mapClick)="mapClicked($event)"
    >
      <map-marker [position]="center"></map-marker>
    </google-map>
  </div>
</div>
