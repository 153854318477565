
<div *ngIf="!enableForChat" class="row m-0 h-100">
  <div class="col-12 p-0 simplebar-content-wrapper">
    <div class="card p-0 m-0">
      <img [src]="image" class="card-img img-fluid" alt="empty">
      <div class="card-img-overlay p-0" [ngStyle]="setTabBg">
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENCHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENBOT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALL_LOG" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VIDEOAUDIO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CONTACT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNELLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINEGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.MAP_SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKINGLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.GROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKING" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALENDAR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VALIDATE_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.REDEEM_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.INVITATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINECHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.EMPTY" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SPEEDDIALER" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.MYORDERS" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SETTINGS" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.PAYMENT_VIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.LOGIN_VIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

        <app-feeds *ngIf="inputTab && inputTab?.module === tabNames?.FEED" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-feeds>
        <app-web-view *ngIf="inputTab && inputTab?.module === tabNames?.WEBVIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-web-view>
        <app-video-view *ngIf="inputTab && inputTab?.module === tabNames?.VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-video-view>
        <app-search-view *ngIf="inputTab && inputTab?.module === tabNames?.SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-search-view>
        <app-menus *ngIf="inputTab && (inputTab?.module === tabNames?.MENU || inputTab?.module === tabNames?.PAGE || inputTab?.module === tabNames?.SPLASH || inputTab?.module === tabNames?.STORE || inputTab?.module === tabNames?.MARKET ||inputTab?.module === tabNames?.POLL)  " [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" [mobViewBuilder]="true" [enableStatic]="enableStatic" [colors]="colors"></app-menus>
        <!-- <app-qr-view *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-view>
        <app-qr-page-view *ngIf="inputTab && inputTab?.module === tabNames?.QR_PAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-page-view> -->

        <!-- ****************************sub tabs ******************************* -->
         <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_1" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_2" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_3" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_4" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VIDEO_CALL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_USER_PROFILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_PHOTO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_FILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_REPLY_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_MULTI_PROFILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_MEDIA_FILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_PHOTO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_LOCATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SELECT_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GALLERY" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_DELETE_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CONTACTS" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT_MENU" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT_TEXTM" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_AUDIO_FILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_AUDIO_CALL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_ATTACH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_EMOJI" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_COLORED_MESSAGE_CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHANNELS_FORWARD_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_LOCATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SELECT_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

      </div>
    </div>
  </div>
</div>
<div *ngIf="enableForChat" class="row m-0">
  <div class="col-12 w-100 p-0 simplebar-content-wrapper">
    <div class="card p-2 m-0">
      <!-- <img [src]="image" class="card-img img-fluid" alt="empty"> -->
      <div class="p-0" [ngStyle]="setTabBg">
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENCHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENBOT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALL_LOG" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VIDEOAUDIO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CONTACT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNELLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINEGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.MAP_SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKINGLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.GROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKING" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALENDAR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VALIDATE_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.REDEEM_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.INVITATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINECHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.EMPTY" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SPEEDDIALER" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.MYORDERS" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SETTINGS" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.PAYMENT_VIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.LOGIN_VIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

        <app-feeds *ngIf="inputTab && inputTab?.module === tabNames?.FEED" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-feeds>
        <app-web-view *ngIf="inputTab && inputTab?.module === tabNames?.WEBVIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-web-view>
        <app-video-view *ngIf="inputTab && inputTab?.module === tabNames?.VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-video-view>
        <app-search-view *ngIf="inputTab && inputTab?.module === tabNames?.SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-search-view>
        <app-menus *ngIf="inputTab && (inputTab?.module === tabNames?.MENU || inputTab?.module === tabNames?.PAGE || inputTab?.module === tabNames?.SPLASH || inputTab?.module === tabNames?.STORE || inputTab?.module === tabNames?.MARKET ||  inputTab?.module === tabNames?.POLL)  " [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" [mobViewBuilder]="true" [enableStatic]="enableStatic" [colors]="colors"></app-menus>
        <!-- <app-qr-view *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-view>
        <app-qr-page-view *ngIf="inputTab && inputTab?.module === tabNames?.QR_PAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-page-view> -->
          <!-- ****************************sub tabs ************************************************ -->
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_1" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_2" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_3" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_4" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VIDEO_CALL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_USER_PROFILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_PHOTO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_FILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_REPLY_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_MULTI_PROFILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_MEDIA_FILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_PHOTO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_LOCATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SELECT_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GALLERY" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_DELETE_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CONTACTS" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT_MENU" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT_TEXTM" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_AUDIO_FILE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_AUDIO_CALL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_ATTACH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_EMOJI" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_COLORED_MESSAGE_CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHANNELS_FORWARD_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_LOCATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
          <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SELECT_MESSAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

      </div>
    </div>
  </div>
</div>
