import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-qr-component",
  templateUrl: "./tab-qr-component.component.html",
  styleUrls: ["./tab-qr-component.component.scss"],
})
export class TabQrComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enablePostFooter = false;
  highColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;

  likeEnabled = true;
  likeIcon: string;
  likedIcon: string;

  replyEnabled = true;
  replyIcon: string;

  viewEnabled = true;
  viewIcon: string;

  shareEnabled = true;
  shareIcon: string;
  qrAbout = '';
  enableCopy = false;
  enableOpenWeb = false;
  enableShare = false;
  qrTypes = {
  '0':'User QR Code with Scanner as Secondary Screen',
  '1':'QR Scanner with User QR Code as Secondary Screen',
  '2':'Generic QR Scanner'
  }
  qrGeneric = {
    '0':'Show Copy Option',
    '1':'Show Share Option',
    '2':'Enable QR Scanner to Open Links'
    }
  qrOption;
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");

          if (this.tab.options) {
            // set the default.
            this.qrOption = this.qrTypes[this.tab.options];

          }
          if (this.tab.generic_qr) {
            // set the default.
            if(this.tab.generic_qr.about){
                     this.qrAbout = this.tab.generic_qr.about;
                    }
                    if(this.tab.generic_qr.enable_copy){
                      this.enableCopy = this.tab.generic_qr.enable_copy ? true : false;
                     }

                     if(this.tab.generic_qr.enable_open_web){
                      this.enableOpenWeb = this.tab.generic_qr.enable_open_web ? true : false;;
                     }

                     if(this.tab.generic_qr.enable_share){
                      this.enableShare = this.tab.generic_qr.enable_share  ? true : false;;
                     }


          }

        }
      }
    );
  }

  toggleSplash(event) {
    this.enablePostFooter = event;
    if (this.enablePostFooter) {
      this.tab.footer.hidden = 0;
    } else {
      this.tab.footer.hidden = 1;
    }

    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }
  toggleCopy(event) {
    const  genericQr = {...  this.tab.generic_qr}
    if (event) {
      genericQr.enable_copy = 1
    } else {
      genericQr.enable_copy = 0
    }


    this.tab.generic_qr = genericQr;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  toggleShare(event) {
    const  genericQr = {...  this.tab.generic_qr}
    if (event) {
      genericQr.enable_share = 1
    } else {
      genericQr.enable_share = 0
    }


    this.tab.generic_qr = genericQr;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  toggleWeb(event) {
    const  genericQr = {...  this.tab.generic_qr}
    if (event) {
      genericQr.enable_open_web = 1
    } else {
      genericQr.enable_open_web = 0
    }


    this.tab.generic_qr = genericQr;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }
  changeQrType(value){
    this.qrOption = this.qrTypes[value];
    this.tab.options = value;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }
  aboutChange(value){
    const  genericQr = {...  this.tab.generic_qr}
    genericQr.about = value;
    this.tab.generic_qr = genericQr;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
