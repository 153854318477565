import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";

@Component({
  selector: "app-button-images",
  templateUrl: "./button-images.component.html",
  styleUrls: ["./button-images.component.scss"],
})
export class ButtonImagesComponent implements OnInit, OnDestroy {
  constructor(
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() button_id: string;
  @Input() config: any;
  imageConfig: any = {};
  imageDarkConfig: any = {};
  button: MenuButton;
  image: string;
  //
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  bgimage: string = null;
  sync = true;
  isAndroid: boolean;
  toggleDarkImage = false;

  ngOnInit() {
    this.field_id = "button_img_url";
    this.imageConfig = {
      id: this.button_id,
      title: this.config && this.config.title ? this.config.title : "Image",
      max: this.config && this.config.max ? this.config.max : 1,
      aspectRatio:
        this.config && this.config.aspectRatio
          ? this.config.aspectRatio
          : "1x1",
      resizeToWidth:
        this.config && this.config.resizeToWidth
          ? this.config.resizeToWidth
          : 260,
      resizeToHeight:
        this.config && this.config.resizeToHeight
          ? this.config.resizeToHeight
          : 260,
      cropperView:
        this.config && this.config.cropperView
          ? this.config.cropperView
          : false,
      selectedImages: [],
      removeButton: true,
      imageType:
        this.config && this.config.imageType
          ? this.config.imageType
          : "image/png,",
      imageSet: false,
      keyword:
        this.config && this.config.keyword ? this.config.keyword : "Image",
      mode:["upload", "gallery", "url"],
      params: "",
    };

    this.imageDarkConfig = {
      id: this.button_id + "dark",
      title:
        this.config && this.config.title
          ? this.config.title + " Dark"
          : "Image",
      max: this.config && this.config.max ? this.config.max : 1,
      aspectRatio:
        this.config && this.config.aspectRatio
          ? this.config.aspectRatio
          : "1x1",
      resizeToWidth:
        this.config && this.config.resizeToWidth
          ? this.config.resizeToWidth
          : 260,
      resizeToHeight:
        this.config && this.config.resizeToHeight
          ? this.config.resizeToHeight
          : 260,
      cropperView:
        this.config && this.config.cropperView
          ? this.config.cropperView
          : false,
      selectedImages: [],
      removeButton: true,
      imageType:
        this.config && this.config.imageType
          ? this.config.imageType
          : "image/png,",
      imageSet: false,
      keyword:
        this.config && this.config.keyword ? this.config.keyword : "Image",
      mode: ["upload", "gallery", "url"],
      params: "",
    };

    this.sortedButtonList = [];
    this.buttonList = {};
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data);
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          this.buttonFunction(this.button);
        }
      }
    );
  }

  intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.button);
  }

  buttonFunction(button) {
    this.isAndroid =
      localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;

    if (this.button) {
      if (this.config && this.config.image) {
        const selectedRatio = this.sortedButtonList[this.button.row_id].length;
        const aspectNumber = this.button.info.ratio[selectedRatio].split("x");
        this.imageConfig = {
          ...this.imageConfig,
          aspectRatio: this.button.info.ratio[selectedRatio],
          resizeToWidth: Number(aspectNumber[0]),
          resizeToHeight: Number(aspectNumber[1]),
          cropperView: true,
          // imageType: "image/jpeg,image/pjpeg,image/png",
        };
        this.imageDarkConfig = {
          ...this.imageDarkConfig,
          aspectRatio: this.button.info.ratio[selectedRatio],
          resizeToWidth: Number(aspectNumber[0]),
          resizeToHeight: Number(aspectNumber[1]),
          cropperView: true,
          // imageType: "image/jpeg,image/pjpeg,image/png",
        };
      }
      if (!this.config.bg) {
        this.image = this.button.button_img_url;
        if (this.button.button_img_url) {
          let media: any = {};
          media.permanentUrl = this.button.button_img_url;
          this.imageConfig.selectedImages = [{ media: media }];
        }

        if (this.button.button_img_url_dark) {
          this.toggleDarkImage = true;
          let media: any = {};
          media.permanentUrl = this.button.button_img_url_dark;
          this.imageDarkConfig.selectedImages = [{ media: media }];
        }
      } else {
        this.image = this.button.button_bgimage;
        if (this.button.button_bgimage) {
          let media: any = {};
          media.permanentUrl = this.button.button_bgimage;
          this.imageConfig.selectedImages = [{ media: media }];
        }

        if (this.button.button_bgimage_dark) {
          this.toggleDarkImage = true;
          let media: any = {};
          media.permanentUrl = this.button.button_bgimage_dark;
          this.imageDarkConfig.selectedImages = [{ media: media }];
        }
      }
    }
  }

  buttonImage(value) {
    if (this.sync) {
      if (!this.config.bg) {
        if (value && value.length > 0) {
          this.button.button_img_url = value[0].media.permanentUrl;
          this.button.button_img_url_ios = value[0].media.permanentUrl;
        } else {
          delete this.button.button_img_url;
          delete this.button.button_img_url_ios;
        }
      } else {
        if (value && value.length > 0) {
          this.button.button_bgimage = value[0].media.permanentUrl;
          this.button.button_bgimage_ios = value[0].media.permanentUrl;
        } else {
          delete this.button.button_bgimage;
          delete this.button.button_bgimage_ios;
        }
      }
    } else {
      if (!this.config.bg) {
        if (this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_img_url = value[0].media.permanentUrl;
          } else {
            delete this.button.button_img_url;
          }
        }
        if (!this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_img_url_ios = value[0].media.permanentUrl;
          } else {
            delete this.button.button_img_url_ios;
          }
        }
      } else {
        if (this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_bgimage = value[0].media.permanentUrl;
          } else {
            delete this.button.button_bgimage;
          }
        }
        if (!this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_bgimage_ios = value[0].media.permanentUrl;
          } else {
            delete this.button.button_bgimage_ios;
          }
        }
      }
    }
    this.updateButtonData();
  }

  buttonImageDark(value) {
    if (this.sync) {
      if (!this.config.bg) {
        if (value && value.length > 0) {
          this.button.button_img_url_dark = value[0].media.permanentUrl;
          this.button.button_img_url_dark_ios = value[0].media.permanentUrl;
        } else {
          delete this.button.button_img_url_dark;
          delete this.button.button_img_url_dark_ios;
        }
      } else {
        if (value && value.length > 0) {
          this.button.button_bgimage_dark = value[0].media.permanentUrl;
          this.button.button_bgimage_dark_ios = value[0].media.permanentUrl;
        } else {
          delete this.button.button_bgimage_dark;
          delete this.button.button_bgimage_dark_ios;
        }
      }
    } else {
      if (!this.config.bg) {
        if (this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_img_url_dark = value[0].media.permanentUrl;
          } else {
            delete this.button.button_img_url_dark;
          }
        }
        if (!this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_img_url_dark_ios = value[0].media.permanentUrl;
          } else {
            delete this.button.button_img_url_dark_ios;
          }
        }
      } else {
        if (this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_bgimage_dark = value[0].media.permanentUrl;
          } else {
            delete this.button.button_bgimage_dark;
          }
        }
        if (!this.isAndroid) {
          if (value && value.length > 0) {
            this.button.button_bgimage_dark_ios = value[0].media.permanentUrl;
          } else {
            delete this.button.button_bgimage_dark_ios;
          }
        }
      }
    }
    this.updateButtonData();
  }

  buttonImageSet(value) {
    if (value) {
      const buttonImageSet: any = {};

      buttonImageSet.hdpi = value[0].media.permanentUrl;
      buttonImageSet.mdpi = value[1].media.permanentUrl;
      buttonImageSet.xhdpi = value[2].media.permanentUrl;
      buttonImageSet.xxhdpi = value[3].media.permanentUrl;
      buttonImageSet.xxxhdpi = value[4].media.permanentUrl;
      buttonImageSet.ios1x = value[5].media.permanentUrl;
      buttonImageSet.ios2x = value[6].media.permanentUrl;
      buttonImageSet.ios3x = value[7].media.permanentUrl;

      this.button.button_img_urlSet = buttonImageSet;
      this.updateButtonData();
    } else {
      this.button.button_img_urlSet = null;
    }
  }

  enableDarkImageMode(e) {
    this.toggleDarkImage = e.target.checked;
    if (!this.toggleDarkImage) {
      if (!this.config.bg) {
        delete this.button.button_img_url_dark;
        delete this.button.button_img_url_dark_ios;
      } else {
        delete this.button.button_bgimage_dark;
        delete this.button.button_bgimage_dark_ios;
      }
      this.updateButtonData();
    }
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
