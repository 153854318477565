import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class ImagePreloaderService {
  constructor(
    private http: HttpClient
  ) {}

  preloadImagesInChunks(imageUrls: string[], chunkSize: number): void {
    const chunks = Array.from({ length: Math.ceil(imageUrls.length / chunkSize) }, (_, i) =>
      imageUrls.slice(i * chunkSize, i * chunkSize + chunkSize)
    );

    const loadChunk = (chunk: string[], resolve: () => void) => {
      let loadedCount = 0;

      const onLoad = () => {
        loadedCount++;
        if (loadedCount === chunk.length) {
          // Clear references to free memory
          chunk.forEach((_, index) => chunk[index] = null);
          resolve();
        }
      };

      chunk.forEach(url => {
        const img = new Image();
        img.src = url; // Triggers the loading and caching
        img.onload = img.onerror = onLoad;
      });
    };

    (async () => {
      for (let i = 0; i < imageUrls.length; i += chunkSize) {
        const chunk = imageUrls.slice(i, i + chunkSize);
        await new Promise((resolve: any) => loadChunk(chunk, resolve));
      }
    })();
  }

}
