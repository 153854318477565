<div id="layout-wrapper" >

  <app-topbar [ngClass]="{'d-none': aiPage}" (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>

  <app-sidebar *ngIf="!expired && !aiPage" [isCondensed]="isCondensed" [isHover]="isHover" (mobileMenuHover)="onHoverMobileMenu()"></app-sidebar>

  <div class="main-content vh-100 overflow-y-auto" [ngStyle]="{ 'margin-left': isHover ? '70px' : '' }" [ngClass]="{'ms-0' : expired}">
    <div class="page-content pb-0">
      <!-- content -->
        <router-outlet></router-outlet>
    </div>
    <!-- footer -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>

<app-rightsidebar></app-rightsidebar>
