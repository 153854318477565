<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form class="needs-validation" [formGroup]="checkform">
        <div class="form-check d-flex align-items-center p-0 ">

          <label class="offcanvas-header-text w-100 m-0" for="changeEmail">
            Allow User to Update Email
          </label>
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="changeEmail"
            formControlName="allowEmail"
            (change)="appInfoUpdate($event)"
            style="width: 18px; height: 18px"
          />
        </div>
      </form>
    </div>
  </div>
</div>
