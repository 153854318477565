import { ColorPickerModule } from "ngx-color-picker";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TabsModule } from "ngx-bootstrap/tabs";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";

import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { NgApexchartsModule } from "ng-apexcharts";
import { SimplebarAngularModule } from "simplebar-angular";

import { QRCodeModule } from "angularx-qrcode";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonStyleComponentComponent } from "./button-style-component/button-style-component.component";
import { ButtonInfoComponentComponent } from "./boutton-info-component/boutton-info-component.component";
import { ButtonLabelComponentComponent } from "./button-label-component/button-label-component.component";
import { ButtonActionTypeComponentComponent } from "./button-action-type-component/button-action-type-component.component";
import { ButtonBorderColorComponentComponent } from "./button-border-color-component/button-border-color-component.component";
import { ButtonMenuCacheComponentComponent } from "./button-menu-cache-component/button-menu-cache-component.component";
import { ButtonNewScreenComponentComponent } from "./button-new-screen-component/button-new-screen-component.component";
import { ButtonOptionComponentComponent } from "./button-option-component/button-option-component.component";
import { ButtonTextColorComponentComponent } from "./button-text-color-component/button-text-color-component.component";
import { TabStickyPostComponentComponent } from "./tab-steky-post-component/tab-sticky-post-component.component";
import { TabPostFooterComponentComponent } from "./tab-post-footer-component/tab-post-footer-component.component";
import { TabEnableAdsComponentComponent } from "./tab-enable-ads-component/tab-enable-ads-component.component";
import { TabSetGroupComponentComponent } from "./tab-set-group-component/tab-set-group-component.component";
import { TabSetBotComponentComponent } from "./tab-set-bot-component/tab-set-bot-component.component";
import { TabEnableVideoCallComponentComponent } from "./tab-enable-video-component/tab-enable-video-call-component.component";
import { TabVideoComponentComponent } from "./tab-video-component/tab-video-component.component";
import { TabInvitationLinkComponentComponent } from "./tab-invitation-link-component/tab-invitation-link-component.component";
import { TabQrComponentComponent } from "./tab-qr-component/tab-qr-component.component";
import { TabWebViewComponentComponent } from "./tab-web-view-component/tab-web-view-component.component";
import { TabSearchComponentComponent } from "./tab-search-component/tab-search-component.component";
import { TabMapSearchComponentComponent } from "./tab-map-search-component/tab-map-search-component.component";
import { TabInfoComponentComponent } from "./tab-info-component/tab-info-component.component";
import { TabEnableChatWithAdminComponentComponent } from "./tab-enable-chat-with-admin-component/tab-enable-chat-with-admin-component.component";
import { TabChatWithAdminIconComponentComponent } from "./tab-chat-with-admin-icon-component/tab-chat-with-admin-icon-component.component";
import { TabPostTagsComponentComponent } from "./tab-post-tags-component/tab-post-tags-component.component";
import { TabPostContextMenuComponentComponent } from "./tab-post-context-menu-component/tab-post-context-menu-component.component";
import { TabBubbleThemesComponentComponent } from "./tab-bubble-themes-component/tab-bubble-themes-component.component";
import { TabChannelContextMenuComponentComponent } from "./tab-channel-context-menu-component/tab-channel-context-menu-component.component";
import { TabEnableInvitationLinkComponentComponent } from "./tab-enable-invitation-link-component/tab-enable-invitation-link-component.component";
import { TabChannelFooterComponentComponent } from "./tab-channel-footer-component/tab-channel-footer-component.component";
import { TabChannelChatWithAdminComponentComponent } from "./tab-channel-chat-with-admin-component/tab-channel-chat-with-admin-component.component";
import { ImageUploaderComponent } from "./image-uploader/image-uploader.component";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { ImageCropperModule } from "ngx-image-cropper";
import { OffcanvasSwaperComponent } from "./offcanvas-swaper/offcanvas-swaper.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ButtonPageSocialBarComponentComponent } from "./button-page-social-bar-component/button-page-social-bar-component.component";
import { ButtonPageSocialBarStyleComponentComponent } from "./button-page-social-bar-style-component/button-page-social-bar-style-component.component";
import { ButtonPageStylePositionComponentComponent } from "./button-page-style-position-component/button-page-style-position-component.component";
import { ButtonPageLocationMarkersComponentComponent } from "./button-page-location-markers-component/button-page-location-markers-component.component";
import { ButtonPageLocationCenterComponentComponent } from "./button-page-location-center-component/button-page-location-center-component.component";
import { ButtonPageLocationZoomComponentComponent } from "./button-page-location-zoom-component/button-page-location-zoom-component.component";
import { ButtonPageVideoComponentComponent } from "./button-page-video-component/button-page-video-component.component";
import { ButtonPageQrComponentComponent } from "./button-page-qr-component/button-page-qr-component.component";
import { ButtonPageMultiImageUploaderComponent } from "./button-page-multi-image-uploader-component/button-page-multi-image-uploader-component.component";
import { ButtonPageGalleryStyleComponentComponent } from "./button-page-gallery-style-component/button-page-gallery-style-component.component";
import { ButtonPageSingleImageUploaderComponent } from "./button-page-single-image-uploader-component/button-page-single-image-uploader-component.component";
import { ButtonPageSingleImageStyleComponentComponent } from "./button-page-single-image-style-component/button-page-single-image-style-component.component";
import { ButtonPageHeaderComponentComponent } from "./button-page-header-component/button-page-header-component.component";
import { ButtonPageBgColorsComponentComponent } from "./button-page-bg-colors-component/button-page-bg-colors-component.component";
import { ButtonPageHeaderImageUploaderComponent } from "./button-page-header-image-uploader-component/button-page-header-image-uploader-component.component";
import { ButtonPageIntervalComponentComponent } from "./button-page-interval-component/button-page-interval-component.component";
import { ButtonPageBorderColorComponentComponent } from "./button-page-border-color-component/button-page-border-color-component.component";
import { ButtonPageBorderMtComponentComponent } from "./button-page-border-mt-component/button-page-border-mt-component.component";
import { ButtonPageBorderMbComponentComponent } from "./button-page-border-mb-component/button-page-border-mb-component.component";
import { ButtonPageBorderMsComponentComponent } from "./button-page-border-ms-component/button-page-border-ms-component.component";
import { ButtonPageBorderSizeComponentComponent } from "./button-page-border-size-component/button-page-border-size-component.component";
import { ButtonPageBorderStyleComponentComponent } from "./button-page-border-style-component/button-page-border-style-component.component";
import { ButtonPageSwaperImageUploaderComponent } from "./button-page-swaper-image-uploader-component/button-page-swaper-image-uploader-component.component";
import { ButtonPageSliderAnimationComponentComponent } from "./button-page-slider-animation-component/button-page-slider-animation-component.component";
import { ButtonPageSliderStyleComponentComponent } from "./button-page-slider-style-component/button-page-slider-style-component.component";
import { ButtonPageVideoStyleComponentComponent } from "./button-page-video-style-component/button-page-video-style-component.component";
import { GenAnavTabComponentComponent } from "./gen-anavtab-component/gen-anavtab-component.component";
import { GenAnavTabStyleComponentComponent } from "./gen-anavtabstyle-component/gen-anavtabstyle-component.component";
import { GenAnavColorComponentComponent } from "./gen-anavcolor-component/gen-anavcolor-component.component";
import { GenInavColorComponentComponent } from "./gen-inavcolor-component/gen-inavcolor-component.component";
import { GenInavInfoComponentComponent } from "./gen-inavinfo-component/gen-inavinfo-component.component";
import { GenINavTabStyleComponentComponent } from "./gen-inavtabstyle-component/gen-inavtabstyle-component.component";
import { GenISideToggleComponentComponent } from "./gen-isidetoggle-component/gen-isidetoggle-component.component";
import { GenISideInfoComponentComponent } from "./gen-isideinfo-component/gen-isideinfo-component.component";
import { GenISideComponentComponent } from "./gen-iside-component/gen-iside-component.component";
import { GenASideToggleComponentComponent } from "./gen-asidetoggle-component/gen-asidetoggle-component.component";
import { GenASideColorComponentComponent } from "./gen-asidecolor-component/gen-asidecolor-component.component";
import { ButtonLabelHtmlComponent } from "./button-label-html/button-label-html.component";
import { ButtonSubLabelHtmlComponent } from "./button-sublabel-html/button-sublabel-html.component";
import { ButtonImageComponent } from "./button-image/button-image.component";
import { ButtonTabSplashTncComponent } from "./button-tabsplash-tnc/button-tabsplash-tnc.component";
import { ButtonTabSplashButtonComponent } from "./button-tabsplash-button/button-tabsplash-button.component";
import { MediaCenterComponent } from "./media-center/media-center.component";
import { ButtonSplashImageComponent } from "./button-splashimage/button-splashimage.component";
import { ButtonBgColorComponent } from "./button-bgcolor/button-bgcolor.component";
import { ButtonSplashBGImageComponent } from "./button-splashbgimage/button-splashbgimage.component";
import { ButtonSplashBgColorComponent } from "./button-splashbgcolor/button-splashbgcolor.component";
import { ButtonSplashLabelHtmlComponent } from "./button-splashlabel-html/button-splashlabel-html.component";
import { ButtonTabSplashTitleHtmlComponent } from "./button-tabsplash-titlehtml/button-tabsplash-titlehtml.component";
import { IconsSelectorComponent } from "./icons-selector/icons-selector.component";
import { GenAppLoginMessageHtmlComponent } from "./gen-apploginmessage-html/gen-apploginmessage-html.component";
import { GenAppLoginSmtpServerComponent } from "./gen-apploginsmtp-server/gen-apploginsmtp-server.component";
import { GenChangeEmailComponent } from "./gen-changeemail/gen-changeemail.component";
import { GenAppLoginVendorsMobileComponent } from "./gen-apploginvendors-mobile/gen-apploginvendors-mobile.component";
import { GenVerificationAccountComponent } from "./gen-verificationaccount/gen-verificationaccount.component";
import { NgOtpInputModule } from "ng-otp-input";
import { GenAppPaymentStripeComponent } from "./gen-appPayment-stripe/gen-appPayment-stripe.component";
import { GenAppPaymentPaypalComponent } from "./gen-appPayment-paypal/gen-appPayment-paypal.component";
import { GenAppPaymentInappPurchasesComponent } from "./gen-appPayment-inappPurchases/gen-appPayment-inappPurchases.component";
import { GenAppPaymentGoogleAdsAndroidComponent } from "./gen-appPaymentInGoogleAds-android/gen-appPaymentInGoogleAds-android.component";
import { GenAppPaymentGoogleAdsIOSComponent } from "./gen-appPaymentInGoogleAds-ios/gen-appPaymentInGoogleAds-ios.component";
import { ButtonMainComponentComponent } from "./button-main-component/button-main-component.component";
import { ButtonImagesComponent } from "./button-images/button-images.component";
import { OnClickSettingsComponent } from "./onClick-settings/onClick-settings.component";
import { OnClickAccessComponent } from "./onClick-access/onClick-access.component";
import { TabStoreInfoComponentComponent } from "./tab-store-info-component/tab-store-info-component.component";
import { TabPollInfoComponentComponent } from "./tab-poll-info-component/tab-poll-info-component.component";
import { TabChatLabelComponentComponent } from "./tab-chatLabel-component/tab-chatLabel-component.component";
import { SelectorsModule } from "../selector/selectors.module";
import { TabEnablePublicGroupComponentComponent } from "./tab-enable-public-group-component/tab-enable-public-group-component.component";
import { ButtonTabSplashTermComponent } from "./button-tabsplash-term/button-tabsplash-term.component";
import { GenSideUserComponentComponent } from "./gen-sideuser-component/gen-sideuser-component.component";

const config: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  maxFilesize: 100,
};

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    TabStickyPostComponentComponent,
    TabPostFooterComponentComponent,
    TabEnableAdsComponentComponent,
    TabSetGroupComponentComponent,
    TabSetBotComponentComponent,
    TabEnableVideoCallComponentComponent,
    TabVideoComponentComponent,
    TabInvitationLinkComponentComponent,
    TabQrComponentComponent,
    TabWebViewComponentComponent,
    TabSearchComponentComponent,
    TabMapSearchComponentComponent,
    TabInfoComponentComponent,
    TabEnableChatWithAdminComponentComponent,
    TabChatWithAdminIconComponentComponent,
    TabPostTagsComponentComponent,
    TabPostContextMenuComponentComponent,
    TabBubbleThemesComponentComponent,
    TabChannelContextMenuComponentComponent,
    TabEnableInvitationLinkComponentComponent,
    TabChannelChatWithAdminComponentComponent,
    TabChannelFooterComponentComponent,
    ButtonStyleComponentComponent,
    ButtonInfoComponentComponent,
    ButtonLabelComponentComponent,
    ButtonActionTypeComponentComponent,
    ButtonBorderColorComponentComponent,
    ButtonMenuCacheComponentComponent,
    ButtonNewScreenComponentComponent,
    ButtonOptionComponentComponent,
    ButtonTextColorComponentComponent,
    ImageUploaderComponent,
    OffcanvasSwaperComponent,
    ButtonPageSocialBarComponentComponent,
    ButtonPageSocialBarStyleComponentComponent,
    ButtonPageStylePositionComponentComponent,
    ButtonPageLocationMarkersComponentComponent,
    ButtonPageLocationCenterComponentComponent,
    ButtonPageLocationZoomComponentComponent,
    ButtonPageVideoComponentComponent,
    ButtonPageQrComponentComponent,
    ButtonPageMultiImageUploaderComponent,
    ButtonPageGalleryStyleComponentComponent,
    ButtonPageSingleImageUploaderComponent,
    ButtonPageSingleImageStyleComponentComponent,
    ButtonPageHeaderComponentComponent,
    ButtonPageBgColorsComponentComponent,
    ButtonPageHeaderImageUploaderComponent,
    ButtonPageIntervalComponentComponent,
    ButtonPageBorderColorComponentComponent,
    ButtonPageBorderMtComponentComponent,
    ButtonPageBorderMbComponentComponent,
    ButtonPageBorderMsComponentComponent,
    ButtonPageBorderSizeComponentComponent,
    ButtonPageBorderStyleComponentComponent,
    ButtonPageSwaperImageUploaderComponent,
    ButtonPageSliderAnimationComponentComponent,
    ButtonPageSliderStyleComponentComponent,
    ButtonPageVideoStyleComponentComponent,
    GenAnavTabComponentComponent,
    GenAnavTabStyleComponentComponent,
    GenAnavColorComponentComponent,
    GenInavColorComponentComponent,
    GenInavInfoComponentComponent,
    GenINavTabStyleComponentComponent,
    GenISideToggleComponentComponent,
    GenISideInfoComponentComponent,
    GenISideComponentComponent,
    GenASideToggleComponentComponent,
    GenASideColorComponentComponent,
    ButtonLabelHtmlComponent,
    ButtonSubLabelHtmlComponent,
    ButtonImageComponent,
    ButtonTabSplashTncComponent,
    ButtonTabSplashButtonComponent,
    MediaCenterComponent,
    ButtonSplashImageComponent,
    ButtonBgColorComponent,
    ButtonSplashBGImageComponent,
    ButtonSplashBgColorComponent,
    ButtonSplashLabelHtmlComponent,
    ButtonTabSplashTitleHtmlComponent,
    IconsSelectorComponent,
    GenAppLoginMessageHtmlComponent,
    GenAppLoginSmtpServerComponent,
    GenChangeEmailComponent,
    GenAppLoginVendorsMobileComponent,
    GenVerificationAccountComponent,
    GenAppPaymentStripeComponent,
    GenAppPaymentPaypalComponent,
    GenAppPaymentInappPurchasesComponent,
    GenAppPaymentGoogleAdsAndroidComponent,
    GenAppPaymentGoogleAdsIOSComponent,
    ButtonMainComponentComponent,
    ButtonImagesComponent,
    OnClickSettingsComponent,
    OnClickAccessComponent,
    TabStoreInfoComponentComponent,
    TabPollInfoComponentComponent,
    TabChatLabelComponentComponent,
    TabEnablePublicGroupComponentComponent,
    ButtonTabSplashTermComponent,
    GenSideUserComponentComponent
  ],
  imports: [
    CommonModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    BsDropdownModule.forRoot(),
    DropzoneModule,
    ReactiveFormsModule,
    NgSelectModule,
    PaginationModule.forRoot(),
    UiSwitchModule,
    CKEditorModule,
    CarouselModule.forRoot(),
    NgApexchartsModule,
    SimplebarAngularModule,
    TranslateModule,
    QRCodeModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ColorPickerModule,
    CollapseModule.forRoot(),
    ImageCropperModule,
    DragDropModule,
    NgOtpInputModule,
    SelectorsModule
  ],
  exports: [
    TabStickyPostComponentComponent,
    TabPostFooterComponentComponent,
    TabEnableAdsComponentComponent,
    TabSetGroupComponentComponent,
    TabSetBotComponentComponent,
    TabEnableVideoCallComponentComponent,
    TabVideoComponentComponent,
    TabInvitationLinkComponentComponent,
    TabQrComponentComponent,
    TabWebViewComponentComponent,
    TabSearchComponentComponent,
    TabMapSearchComponentComponent,
    TabInfoComponentComponent,
    TabEnableChatWithAdminComponentComponent,
    TabChatWithAdminIconComponentComponent,
    TabPostTagsComponentComponent,
    TabPostContextMenuComponentComponent,
    TabBubbleThemesComponentComponent,
    TabChannelContextMenuComponentComponent,
    TabEnableInvitationLinkComponentComponent,
    TabChannelChatWithAdminComponentComponent,
    TabChannelFooterComponentComponent,
    ButtonStyleComponentComponent,
    ButtonInfoComponentComponent,
    ButtonLabelComponentComponent,
    ButtonActionTypeComponentComponent,
    ButtonBorderColorComponentComponent,
    ButtonMenuCacheComponentComponent,
    ButtonNewScreenComponentComponent,
    ButtonOptionComponentComponent,
    ButtonTextColorComponentComponent,
    ImageUploaderComponent,
    OffcanvasSwaperComponent,
    ButtonPageSocialBarComponentComponent,
    ButtonPageSocialBarStyleComponentComponent,
    ButtonPageStylePositionComponentComponent,
    ButtonPageLocationMarkersComponentComponent,
    ButtonPageLocationCenterComponentComponent,
    ButtonPageLocationZoomComponentComponent,
    ButtonPageVideoComponentComponent,
    ButtonPageQrComponentComponent,
    ButtonPageMultiImageUploaderComponent,
    ButtonPageGalleryStyleComponentComponent,
    ButtonPageSingleImageUploaderComponent,
    ButtonPageSingleImageStyleComponentComponent,
    ButtonPageHeaderComponentComponent,
    ButtonPageBgColorsComponentComponent,
    ButtonPageHeaderImageUploaderComponent,
    ButtonPageIntervalComponentComponent,
    ButtonPageBorderColorComponentComponent,
    ButtonPageBorderMtComponentComponent,
    ButtonPageBorderMbComponentComponent,
    ButtonPageBorderMsComponentComponent,
    ButtonPageBorderSizeComponentComponent,
    ButtonPageBorderStyleComponentComponent,
    ButtonPageSwaperImageUploaderComponent,
    ButtonPageSliderAnimationComponentComponent,
    ButtonPageSliderStyleComponentComponent,
    ButtonPageVideoStyleComponentComponent,
    GenAnavTabComponentComponent,
    GenAnavTabStyleComponentComponent,
    GenAnavColorComponentComponent,
    GenInavColorComponentComponent,
    GenInavInfoComponentComponent,
    GenINavTabStyleComponentComponent,
    GenISideToggleComponentComponent,
    GenISideInfoComponentComponent,
    GenISideComponentComponent,
    GenASideToggleComponentComponent,
    GenASideColorComponentComponent,
    ButtonLabelHtmlComponent,
    ButtonSubLabelHtmlComponent,
    ButtonImageComponent,
    ButtonTabSplashTncComponent,
    ButtonTabSplashButtonComponent,
    MediaCenterComponent,
    ButtonSplashImageComponent,
    ButtonBgColorComponent,
    ButtonSplashBGImageComponent,
    ButtonSplashBgColorComponent,
    ButtonSplashLabelHtmlComponent,
    ButtonTabSplashTitleHtmlComponent,
    IconsSelectorComponent,
    GenAppLoginMessageHtmlComponent,
    GenAppLoginSmtpServerComponent,
    GenChangeEmailComponent,
    GenAppLoginVendorsMobileComponent,
    GenVerificationAccountComponent,
    GenAppPaymentStripeComponent,
    GenAppPaymentPaypalComponent,
    GenAppPaymentInappPurchasesComponent,
    GenAppPaymentGoogleAdsAndroidComponent,
    GenAppPaymentGoogleAdsIOSComponent,
    ButtonMainComponentComponent,
    ButtonImagesComponent,
    OnClickSettingsComponent,
    OnClickAccessComponent,
    TabStoreInfoComponentComponent,
    TabPollInfoComponentComponent,
    TabChatLabelComponentComponent,
    TabEnablePublicGroupComponentComponent,
    ButtonTabSplashTermComponent,
    GenSideUserComponentComponent
  ],
})
export class OffcanvasSettingsComponentModule {}
