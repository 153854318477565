<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex flex-shrink-0">
      <!--  LOGO  -->
      <div class="navbar-brand-box logo-box overflow-x-hidden ps-3">
        <a routerLink="/" class="logo logo-dark" [ngClass]="{'pe-none' : expired}">
          <span class="logo-sm">
            <img src="assets/images/logo-icon.png" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light" [ngClass]="{'pe-none' : expired}">
          <span class="logo-sm">
            <img src="assets/images/logo-icon.png" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" />
          </span>
        </a>
      </div>

      <button *ngIf="!expired"
        type="button"
        class="btn btn-sm ps-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
      <div class="d-none d-md-flex ps-3 align-items-center justify-content-center">
        <span class="primary-text enablePointer" (click)="startover()">
          Change Template
        </span>
        <md-filled-tonal-button *ngIf="sample" class="ms-2 enablePointer" (click)="deleteSampleData()">
          Clear Sample Data
        </md-filled-tonal-button>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <h2 *ngIf="admin && !super">Administrator</h2>
      <h2 *ngIf="admin && super">Super Administrator</h2>
      <h2 *ngIf="reconnectNotice" class="mb-0">{{reconnectNotice}}</h2>
      <h2 *ngIf="tempModeStatus" class="mb-0">Template Mode</h2>
    </div>

    <div class="d-flex flex-grow-1 justify-content-end h-100">
      <!-- <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" dropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
        </div>
      </div> -->
      <div *ngIf="planLevel == 0 && !(days == 0 && hours == 0 && minutes == 0)" class="d-none d-lg-flex align-items-center h-100 flex-shrink-0 mx-auto">
        <div class="row g-2 ms-0 ms-xl-5">
          <div class="col-4">
            <div class="countdown-box rounded-3 px-2 d-flex align-items-center justify-content-center flex-column">
              <div class="countdown-number fw-bold text-secondary font-size-22">{{ days }}</div>
              <div class="countdown-label font-size-10">Days</div>
            </div>
          </div>
          <div class="col-4">
            <div class="countdown-box rounded-3 px-2 d-flex align-items-center justify-content-center flex-column">
              <div class="countdown-number fw-bold text-secondary font-size-22">{{ hours }}</div>
              <div class="countdown-label font-size-10">Hours</div>
            </div>
          </div>
          <div class="col-4">
            <div class="countdown-box rounded-3 px-2 d-flex align-items-center justify-content-center flex-column">
              <div class="countdown-number fw-bold text-secondary font-size-22">{{ minutes }}</div>
              <div class="countdown-label font-size-10">Minutes</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!cancelEnd" class="d-none d-sm-flex align-items-center me-3">
        <button class="d-none d-lg-block btn primary-button rounded-3 w-md me-2" (click)="buildForMe()">
          <span>Build It For Me</span>
        </button>
        <button *ngIf="planLevel < 3" class="btn success-button rounded-3 w-md" (click)="routeBillingPlans()">
          <span>Upgrade</span>
        </button>
      </div>

      <div *ngIf="cancelEnd && today < cancelEnd" class="d-flex flex-grow-1 align-items-center justify-content-between me-3">
        <div class="d-flex align-items-center mx-auto">
          <i class="mdi mdi-information font-size-24 text-danger me-2"></i>
          <div>
            <div class="d-flex align-items-center me-2">
              <span class="fw-medium">
                Your subscription has been canceled. Your app will stop working on {{ cancelEnd | date : "MMM d, y" }}
              </span>
            </div>
          </div>
        </div>
        <button type="button" class="btn w-md btn-success ms-2" (click)="revokePlan()">
          Reactivate
        </button>
      </div>

      <span class="border-start border-secondary border-opacity-25 my-3 d-none d-sm-block"></span>

      <div
        class="dropdown d-inline-block"
        dropdown
        [autoClose]="false"
        *ngIf="default_chat"
        #dropdown="bs-dropdown"
      >
        <button
          type="button"
          class="btn header-item dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          #menuButton
        >
          <span class="d-flex align-items-center justify-content-center h-100" dropdownToggle>
            <img *ngIf="appInfo?.image" [src]="appInfo?.image" class="d-none d-sm-flex avatar-s rounded-3 me-2" [ngStyle]="{'background': getLogoBackground()}" alt="" onerror="this.onerror=null; this.src='assets/images/users/logo.webp'"/>
            <span *ngIf="!appInfo?.image && default_chat?.media?.photo?.permanentUrl" class="d-none d-sm-flex avatar-title rounded-3 me-2 bg-transparent">
              <img [src]="default_chat?.media?.photo?.permanentUrl" class="avatar-s rounded-3"/>
            </span>
            <div *ngIf="!appInfo?.image && !default_chat?.media?.photo?.permanentUrl" class="d-none d-sm-flex avatar-s flex-shrink-0 me-2">
              <span class="avatar-title bg-primary rounded-3 me-2">
                {{ default_chat?.name?.charAt(0) }}
              </span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start me-2 h-100">
              <span class="font-size-14 fw-medium">{{ default_chat.name }}</span>
              <span *ngIf="default_chat.is_main == 1" class="text-muted font-size-11">Main App</span>
            </div>
            <i class="mdi mdi-chevron-down font-size-20"></i>
          </span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-end dropdown-menu-md pt-0 rounded-3"
          *dropdownMenu
          #menu
        >
          <div class="dropdown-item d-flex w-100 px-1 py-2 border-bottom border-secondary border-opacity-25 pe-none">
            <div class="avatar-xxs flex-shrink-0 me-3">
              <span *ngIf="!default_chat?.media?.photo?.permanentUrl" class="avatar-title bg-primary rounded-circle font-size-16 ms-2">
                {{ default_chat?.name?.charAt(0) }}
              </span>
              <span *ngIf="default_chat?.media?.photo?.permanentUrl" class="avatar-title bg-transparent rounded-circle font-size-16 ms-2">
                <img [src]="default_chat?.media?.photo?.permanentUrl" class="avatar-xxs rounded-circle"/>
              </span>
            </div>
            <div class="flex-grow-1 text-truncate d-flex align-items-center justify-content-between me-3">
              <h6 class="text-truncate mw-50 mb-0">
                {{ default_chat?.name }}
              </h6>
              <div class="d-flex align-items-center">
                <span *ngIf="default_chat.is_main == 1" class="d-flex align-items-center fw-bold">
                  Main
                </span>
                <div class="float-end ms-2">
                  <i class="far fa-check-circle text-primary"></i>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="chatList.length > 1">
            <div
              class="search-box chat-search-box mt-2"
              style="margin-bottom: var(--bs-dropdown-divider-margin-y);"
              id="searchBox"
            >
              <div class="position-relative px-2 btn-md">
                <input
                  type="text"
                  class="form-control ps-4 font-size-12"
                  placeholder="Search Channels"
                  id="searchChatsDrop"
                  autocomplete="off"
                  #search
                  (keyup)="channelsSearchs(search.value)"
                />
              </div>
            </div>
            <ngx-simplebar
              style="position: relative; height: 190px"
              #scrollChatList
              name="scrollChatList"
            >
              <ng-container *ngFor="let chat of chatList">
                <div *ngIf="chat.id != default_chat.id" class="dropdown-item d-flex w-100 px-1 py-2 border-bottom border-secondary border-opacity-25 enablePointer" (click)="selectChat(chat.id)">
                  <div class="avatar-xxs flex-shrink-0 me-3">
                    <span *ngIf="!chat?.media?.photo?.permanentUrl" class="avatar-title bg-primary rounded-circle font-size-16 ms-2">
                      {{ chat?.name?.charAt(0) }}
                    </span>
                    <span *ngIf="chat?.media?.photo?.permanentUrl" class="avatar-title bg-transparent rounded-circle font-size-16 ms-2">
                      <img [src]="chat?.media?.photo?.permanentUrl" class="avatar-xxs rounded-circle"/>
                    </span>
                  </div>
                  <div class="flex-grow-1 text-truncate d-flex align-items-center justify-content-between me-3">
                    <h6 class="text-truncate mw-50 mb-0">
                      {{ chat?.name }}
                    </h6>
                    <span *ngIf="chat.is_main == 1" class="d-flex align-items-center fw-bold">
                      Main
                    </span>
                  </div>
                </div>
                <!-- <a
                  *ngIf="chat.id != default_chat.id"
                  id="list"
                  class="dropdown-item enablePointer mb-2"
                  (click)="selectChat(chat.id)"
                >
                  {{ chat.name }}
                  <span *ngIf="chat.is_main == 1" class="float-end">Main</span>
                </a> -->
              </ng-container>
              <ng-container *ngIf="allChatsHidden">
                  <p class="m-0 text-muted text-center">
                    No results found
                  </p>
              </ng-container>
            </ngx-simplebar>
          </div>
        </div>
      </div>

      <span class="border-start border-secondary border-opacity-25 my-3"></span>

      <div class="dropdown d-none d-lg-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon d-flex align-items-center pe-2 ps-3"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="material-icons-outlined font-size-30">fullscreen</i>
        </button>
      </div>

      <div class="d-none d-sm-block dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon d-flex align-items-center right-bar-toggle px-2"
          (click)="toggleMode()"
        >
          <i class="material-icons-outlined font-size-26">dark_mode</i>
        </button>
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button
          type="button"
          class="btn header-item noti-icon d-flex align-items-center pe-2 pe-lg-3 ps-2 ps-sm-0 ps-lg-2"
          id="page-header-notifications-dropdown"
          dropdownToggle
        >
          <i class="material-icons-outlined font-size-26">notifications</i>
          <span *ngIf="replies.length > 0" class="badge bg-danger rounded-pill">{{replies.length}}</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          *dropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; max-height: 230px">
            <a *ngFor="let reply of replies" class="text-reset notification-item enablePointer" (click)="goToReply(reply)">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ reply?.message?.from?.name }}
                  </h6>
                  <div class="d-flex p-0">
                    <div *ngIf="reply?.message?.type == 'text'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><span class="text-truncate text-nowrap">{{ reply?.message?.text }}</span></div>
                    <div *ngIf="reply?.message?.type == 'photo'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-12 me-1">photo_camera</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "Photo"}}</span></div>
                    <div *ngIf="reply?.message?.type == 'gif'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-26 me-1">gif</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "GIF"}}</span></div>
                    <div *ngIf="reply?.message?.type == 'video'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-12 me-1">videocam</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "Video"}}</span></div>
                    <div *ngIf="reply?.message?.type == 'file'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-12 me-1">description</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "File"}}</span></div>
                    <div *ngIf="reply?.message?.type == 'audio'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-12 me-1">headphones</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "Audio"}}</span></div>
                    <div *ngIf="reply?.message?.type == 'voice'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-12 me-1">mic</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "Voice"}}</span></div>
                    <div *ngIf="reply?.message?.type == 'location'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-12 me-1">location_on</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "Location"}}</span></div>
                    <div *ngIf="reply?.message?.type == 'calendar'" class="p-0 mb-0 font-size-10 d-flex align-items-center" style="max-width: 75%"><i class="material-icons font-size-12 me-1">calendar_month</i> <span class="text-truncate text-nowrap">{{ reply?.message?.text ? reply?.message?.text : "Calendar"}}</span></div>
                  </div>
                  <p class="d-flex align-items-center mb-0 p-0">
                    <i class="mdi mdi-clock-outline font-size-10"></i>
                    <span class="font-size-10 ms-1">{{ reply?.message?.date | date : "hh:mm" }}</span>
                  </p>
                </div>
              </div>
            </a>
            <a *ngIf="replies.length == 0">
              <div class="d-flex justify-content-center mb-3">
                <span class="fw-bold">No new notifications</span>
              </div>
            </a>
          </ngx-simplebar>
        </div>
      </div>

      <span class="border-start border-secondary border-opacity-25 my-3"></span>

      <div class="dropdown d-inline-block" dropdown>
        <button
          type="button"
          class="btn header-item d-flex align-items-center"
          dropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user border border-secondary border-opacity-25 p-0"
            src="{{ profile_image }}"
            onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
          />
          <span class="d-none d-xl-inline-block ms-1">{{ profile_name }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block font-size-20"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- <a class="dropdown-item" routerLink="/account/profile"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ "HEADER.LOGIN.PROFILE" | translate }}</a
          > -->
          <a class="dropdown-item enablePointer" (click)="routeBilling()">
            <i class="material-icons-outlined font-size-16 align-middle me-1">payments</i>
            Billing
          </a>
          <a class="d-block d-md-none dropdown-item enablePointer" (click)="startover()">
            <i class="material-icons-outlined font-size-16 align-middle me-1">restart_alt</i>
            Change Template
          </a>
          <a class="d-block d-md-none dropdown-item enablePointer" (click)="deleteSampleData()">
            <i class="material-icons-outlined font-size-15 align-middle me-1">delete</i>
            Clear Sample Data
          </a>
          <a class="d-block d-sm-none dropdown-item enablePointer" (click)="toggleMode()">
            <i class="material-icons-outlined font-size-15 align-middle me-1">dark_mode</i>
            Dark Mode
          </a>
          <a class="d-block d-lg-none dropdown-item enablePointer">
            <i class="material-icons-outlined font-size-15 align-middle me-1">contact_support</i>
            Build It For Me
          </a>
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
          >
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="toggleRightSidebar()"
        >
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>
