import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Lightbox } from "ngx-lightbox/lightbox.service";
import { c1, c3, c4, c7, c9, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-gen-iside-component",
  templateUrl: "./gen-iside-component.component.html",
  styleUrls: ["./gen-iside-component.component.scss"],
})
export class GenISideComponentComponent implements OnInit, OnDestroy {

  validationform: UntypedFormGroup; // bootstrap validation form


  constructor(
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
 ) { }

  @Input() page_id: string;
  @Input() tabData: Tab;
  page: any;
  c9_value: string;
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  enableSideMenu: boolean;
  modalRef?: BsModalRef;
  title: string;
  icon: any;
  tab: any ={};
  appInfo: any = { id: "app_info" };
  updateBuilderComponentSubscription: any;


  ngOnInit() {

    this.validationform = this.formBuilder.group({
      navTitle: ['', [Validators.required, Validators.pattern('[a-zA-Z_ ]+'), Validators.minLength(3), Validators.maxLength(10)]],
    });

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        ) {
          this.enableSideMenu = localStorage.getItem("mode")[8]==c9.SIDE? true: false;
          this.getAppInfo();
          }
      }
    );

this.updateBuilderComponentSubscription =
this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
    if (data && data.tab) {
      this.enableSideMenu = localStorage.getItem("mode")[8]==c9.SIDE? true: false;
    }
});

 }

  openSelector(exlargeModal: any) {
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  get f() { return this.validationform.controls; }

  get form() {
    return this.validationform.controls;
  }

  getAppInfo(){
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result) {
        this.appInfo = appInfo.result;
        this.icon = this.builderService.getIcon(this.appInfo.ios_more_icon);
        this.validationform = this.formBuilder.group({
          navTitle: [this.appInfo.ios_more_title, [Validators.required, Validators.pattern('[a-zA-Z_ ]+'), Validators.minLength(3), Validators.maxLength(10)]],
        });

      }
    }
  }

  titleChange(event) {
    if (this.validationform.valid) {
      this.updateAppInfo("ios_more_title",this.f.navTitle.value)
      this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)
    }
  }

  iconChange(event) {
    this.icon = this.builderService.getIcon(event);
    this.updateAppInfo("ios_more_icon",event)
    this.modalRef.hide();
    this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)
  }


  updateAppInfo(key,value){
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result) {
        this.appInfo = appInfo.result;
        this.appInfo[key] = value;
       this.indexDBService.updateItem("item",  this.appInfo);
      }
    }
  }



  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }

  }
}
