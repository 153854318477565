<div class="card m-0 offcanvas-card bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-centerm my-2">
      <div class="col-10 d-flex align-items-center">
        <h6 class="m-0 offcanvas-header-text d-flex align-items-center">
          Android Navigation Drawer
          <i class="mdi mdi-information-outline font-size-16 ms-1" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Allow Stick Note to show on bottom right of Channel Posts"></i>
        </h6>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch [color]="offcanvasColors.activeColor" [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor" [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor" [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off" [checked]="enableSideMenu" checkedLabel="On" size="small" class="me-1"
          (change)="toggleSideMenu($event)"></ui-switch>
      </div>
    </div>
  </div>
</div>



