<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form
        class="needs-validation"
        [formGroup]="inAppPurchasesform"
        (ngSubmit)="onSubmit()"
      >
        <div class="row d-flex justify-content-between align-items-center mb-4">
          <div class="col-10 d-flex align-items-center">
            <h4 class="card-title m-0 offcanvas-header-text d-flex align-items-center">
              In App Purchase Settings
              <!-- <i
                class="mdi mdi-information-outline font-size-16 ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You can show or hide the foooter's component at the bottom of your Channel Posts"
              ></i> -->
            </h4>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 mb-3">
            <label
              for="environment"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              Upload File
            </label>
            <div class="input-group">
              <input
                type="file"
                class="form-control offcanvas-primary offcanvas-border-primary"
                id="inputGroupFile01"
                accept="application/JSON"
                (change)="fileChange($event)"
              />
            </div>

          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-success w-md ms-auto">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
