import { MENU } from './../../layouts/sidebar/menu';
import { UsersModule } from "./../../pages/users/users.module";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { cl } from "@fullcalendar/core/internal-common";
import { MENU_BUTTON_CODE, MENU_BUTTON_FORM, TabNames, c2, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  Tab,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";
import { CommonService } from "src/app/sharedservices/common.service";

@Component({
  selector: "app-menus",
  templateUrl: "./menus.component.html",
  styleUrls: ["./menus.component.scss"],
})
export class MenusComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService,
    public sanitizer: DomSanitizer
  ) {}

  @Input() inputTab: Tab;
  @Input() inputPage: Page;
  @Input() inputRow: MenuRow;
  @Input() inputMenu: Menu;
  @Input() fontResize: boolean;
  @Input() fontSmaller: boolean;
  @Input() fixedTop : boolean;
  @Input() mobViewBuilder: boolean;
  @Input() mobViewButton: boolean;
  @Input() inputMenuId: string;

  @Output() onClickNotifier = new EventEmitter<any>(null);

  // @Input() staticMenuId: string;
  // @Input() staticTab: Tab;
  // @Input() enableStatic: boolean =false;
  @Input() colors: any;

  @Input() inputButton: any;
  @Input() menuGroup: any;
  @Input() splashTab: Tab;

  current_row: MenuRow;
  // default values
  imageLg = "assets/images/group-7.webp";
  imageSm = "assets/images/group-7.webp";
  image: string;
  icon = "ic_feeds_books_white_24_dp";
  icon_ios = "ic_feeds_books_white_24_dp";
  title = "Chat";
  roundedBottom = true;
  updateBuilderComponentSubscription: any;
  isfull: boolean;
  menu: any;
  page_url: string;
  button_form = MENU_BUTTON_FORM;
  button_code = MENU_BUTTON_CODE;
  button: MenuButton;
  tab: any;
  page: Page;
  c2_full = c2.FULL;

  async ngOnInit() {
    this.isfull = localStorage.getItem("mode")[1] == c2.FULL? true:false


    if (this.inputTab){
      this.tab= {...this.inputTab};
      if (this.tab.menu_id){
        this.menu = {...await this.mdw.constructMenuSync(this.tab.menu_id)};
      }
    }
    if (this.inputMenu){
      this.menu = this.inputMenu;
      }

    if (this.inputPage && this.inputPage.menu){
            this.menu = this.inputPage.menu;
        }
    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe(async (data) => {
        if (data && data.tab && (data.tab.module =="menu" || data.tab.module =="page" || data.tab.module =="store" || data.tab.module =="market" || data.tab.module =="poll"|| data.tab.module =="query"|| data.tab.module =="splash") ) {
          if (this.inputMenuId) {
            this.menu = {...await this.mdw.constructMenuSync(this.inputMenuId)};
          }else  if (this.inputButton) {
            this.menu = data.button && (data.button.button_id === this.inputButton.button_id)? { rows: [{ row_id: data.button.row_id , buttons: [{ ...data.button }] }] }: { rows: [{ row_id: this.inputButton.row_id ,buttons: [{ ...this.inputButton }] }] };

            if (this.menuGroup){
              this.menu.menu_group = this.menuGroup;
            }
           }else if (data.button) {
            this.menu = { rows: [{ row_id: data.button.row_id, buttons: [{ ...data.button }] }] };
            if (this.menuGroup){
              this.menu.menu_group = this.menuGroup;
            }}
            else if (this.inputTab){
            this.tab= {...this.inputTab};
              if (this.tab.menu_id){
              this.menu = {...await this.mdw.constructMenuSync(this.tab.menu_id)};
            }
          }

        }
        }
      );
  }

  async constructMenus(query) {
    return await this.indexDBService
      .getItemOnsucss("menu", query)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        menu.rows = await this.constructMenuRows(menu.menu_id);
        return menu;
      });
  }

  async constructMenuRows(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);

        rows.forEach(async (row) => {
          let newRow = row;
          newRow.buttons = await this.constructMenuRowButtons(row.row_id);
        });
        return rows;
      });
  }

  async constructMenuRowButtons(rowId) {
    return await this.indexDBService
      .getItemListOnsucss("button", "row", rowId)
      .then(async (buttonResponse: any) => {
        let buttons = await this.sortButtons(buttonResponse);
        return buttons;
      });
  }

  sortRows(rows: any[]) {
    return rows.sort((a, b) => Number(a.row_order) - Number(b.row_order));
  }
  sortButtons(buttons: any[]) {
    return buttons.sort(
      (a, b) => Number(a.button_order) - Number(b.button_order)
    );
  }

  get secureUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.page_url);
  }

  rowTrackBy(index, row){
    return row.row_verion;
  }

  buttonTrackBy(index, button){
    return button.button_version

  }

  onClick(button){
    this.onClickNotifier.emit(button);
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
  }
}
