import { BuilderService } from "../../sharedservices/builder.service";
import { Footer, Tab } from "../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  TabNames,
  c1,
  c10,
  c16,
  c5,
  common_components_map,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";


@Component({
  selector: "app-idrawer",
  templateUrl: "./idrawer.component.html",
  styleUrls: ["./idrawer.component.scss"],
})

export class IDrawerComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) { }

  @Input() fontResize = false;
  @Input() fontSmaller = false;
  @Input() tab_id;

  updateBuilderComponentSubscribtion: any;
  colorsComponentSubscription: any;
  // default values

  tabs: Tab[] = [];
  colors: any = {};
  home;
  bg_color: string =null;
  start_color: string = null// = "#1be6e2";
  end_color: string = null//= "#1be6e2";

  text_color: string;
  isAndroid: boolean
  active_icon_color: string
  active_title_color: string
  default_color: string
  currentTabSubscription: any;
  viewSubscription: any;
  sortedTabList: any =[];
  tabList: any;
  cat: string;
  current_tab_id: any;
  data: any;
  mode: string;
  profileName: string;
  current_tab: string;
  updateBuilderComponentSubscription: any;
  wide: boolean;
  theme: any;
  makeItSmaller: boolean;
  c1_Android: string = c1.ANDROID
  c1_Ios: string =c1.IOS
  color_schema: any;
  color_schema_ios: any;
  light: boolean = true;
  bg_image: string = null
  album_button: any;
  albums: any;
  emptyImage: string;
  settings: string;
  emptyImageP0: string;
  emptyImageP1: string;
  emptyImageP2: string;
  emptyImageP3: string;
  emptyImageP4: string;
  seperator_color: string;

  widthP0: string;
  image: string =null;
  ovalImage: string;
  styleP0: number = 0;
  styleP2: number = 0;
  label_color: string;
  sublabel_color: string;
  textAlign: any = {};
  textFontsize: any = {};
  bgTop_color: string;
  labelTop_color: string;
  label: string;
  sublabel: string;
  dividersIndex= [];
  startIndex = 0;
  card_bg_color: string;
  arrow_color: string;
  titleColor: string;

  ngOnInit() {
    this.light = localStorage.getItem("mode")[15]==c16.LIGHT? true:false
    this.mode = localStorage.getItem("mode");
    this.data = {};
    this.data["sortedTabList"] = [];
    this.data["tabList"] = {};
    try {
      this.mobileIDrawerColorInitialize()
    } catch (error) {
      if (localStorage.getItem("ndbx")) {
        setTimeout(async () => {
          this.mobileIDrawerColorInitialize()
        }, 2000);
      } else {
        this.mobileIDrawerColorInitialize()
      }
    }

  this.updateBuilderComponentSubscription =  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
    if (data && data.tab) {
      this.mobileIDrawerColorInitialize()
      this.navDrawerInitialize()
}});

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
    (data) => {
      if (data && data["current_tab"] && data["current_tab"].cat =="side") {
        this.data = data;
        this.sortedTabList = structuredClone(data["sortedTabList"]);
        this.current_tab_id = data ["current_tab"].id;
        let index = this.sortedTabList.findIndex((e) => e.id == '0');
          if (index!== -1){
          this.sortedTabList.splice(index, 1);
        }

        // this.dividersIndex= [this.sortedTabList.length - 1];
        this.mobileIDrawerColorInitialize()
        this.navDrawerInitialize()
      }
    })

    this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
      (data) => {
        if (data  && data.color_schema  && data.color_schema_ios && data.light !=null) {
          this.colors = data;
          this.light = data.light
          this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
          this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark;
           this.colorIntialiaze();
          this.navDrawerInitialize();
        }
      })
    }
navDrawerInitialize(){
      this.isAndroid = localStorage.getItem("mode")[0] ==c1.ANDROID? true: false;
        this.album_button = album_button_map_ios;
        this.albums = this.album_button.albums
        this.settings = this.album_button.settings;
        this.emptyImageP0 = this.albums[0].emptyImage[1];
        this.emptyImageP1 = this.albums[1].emptyImage[1];
        this.emptyImageP2 = this.albums[2].emptyImage[1];
        this.widthP0 = this.albums[0].width[1];
        this.styleP2=3;
        this.ovalImage= 'assets/images/small/oval180.png';
        this.image = 'assets/images/users/account.webp';
        this.label="User Name"
        this.sublabel="Hey! I am using..."
  }

    mobileIDrawerColorInitialize() {
    this.isAndroid = localStorage.getItem("mode")[0] ==c1.ANDROID? true: false;
    this.theme = this.mdw.mobileTheme()
    const appHome = this.indexDBService.getItem("app", "home");
    appHome.onsuccess = (event) => {
      if (appHome.result) {
        this.colors.color_schema = appHome.result.app.tabs.color_schema;
        this.colors.color_schema_ios = appHome.result.app.tabs.color_schema_ios;
        this.colors['light'] = localStorage.getItem("mode")[15]==c16.LIGHT? true:false
        this.light = this.colors['light'];
        this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
        this.colorIntialiaze();
  }
}}
  colorIntialiaze(){
    this.isAndroid = localStorage.getItem("mode")[0] ==c1.ANDROID? true: false;
      //iOS
      this.bg_color = this.color_schema_ios.surface;
      this.card_bg_color = this.light?this.color_schema_ios?.surfaceContainerLowest: this.color_schema_ios.surfaceContainerLow
      this.seperator_color= this.color_schema_ios.surfaceVariant;
      this.titleColor = this.color_schema_ios.onSurface;
      this.arrow_color =this.color_schema_ios.onSurface
      this.label_color = this.color_schema_ios.surfaceVariant;
      this.bgTop_color = this.color_schema.onPrimary;
      this.labelTop_color = this.color_schema.surfaceVariant;
      this.active_title_color = this.color_schema_ios.primary;
      this.active_icon_color = this.color_schema_ios.primary;
}

titleStyle(tab_id) {
      if (tab_id === this.current_tab_id &&  this.bg_color &&  this.active_title_color ) {
         return {  color: this.active_title_color  };
      } else if (tab_id !== this.current_tab_id &&  this.bg_color &&  this.label_color) {
        return { color: this.label_color };
      }
    }

iconStyle(tab_id) {
      if (tab_id === this.current_tab_id &&  this.bg_color &&  this.active_icon_color ) {
        return {  color: this.active_icon_color  };
      } else if (tab_id !== this.current_tab_id &&  this.bg_color &&  this.label_color) {
        return { color: this.label_color };
      }
    }
iconStyleIOS(tab) {
      if (tab && tab.color_schema_ios && tab.color_schema_ios.schemes && tab.color_schema_ios.schemes.light && tab.color_schema_ios.schemes.light.icon && tab.color_schema_ios.schemes.dark && tab.color_schema_ios.schemes.dark.icon) {
        return {  color: this.light ? tab.color_schema_ios.schemes.light.icon : tab.color_schema_ios.schemes.dark.icon };
      }else if(this.color_schema_ios){
        return {  color: this.color_schema_ios.n2onSurface };
      }
    }

get backColors() {
    if (this.colors) {
      return {    background: this.bgTop_color };
    }
}

sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
}

tabTrackBy(index, tab){
    return tab.tab_version;
}

get setButtonBg() {
   if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    } else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return {
        background: this.bg_color,
        color: this.label_color,
       };
    } else {
      return { background: "unset" };
    }
  }

get setTopPartBg() {
  if (this.bgTop_color) {
      return {
        background: this.bgTop_color,
        color: this.label_color,
      };
    } else {
      return { background: "unset" };
    }
  }


get labelColor() {
    return {
      "color": this.label_color,
      'font-size': '12px'
    };
  }

get sublabelColor() {
    return {
      "color": this.sublabel_color,
       'font-size': '10px',
    };
  }

fontScale(item, value) {
    let result: number;
    result = value.replace("px", "");
    // large is greater +2 and smaller is less -2
    if (this.textFontsize && this.textFontsize[item]) {

      switch (this.textFontsize[item]) {
        case 'sm':
          result = Number(result) - 2;
          return result + 'px'
          break;
        case 'md':
          return Number(result) + 'px'
          break;
        case 'lg':
          result = Number(result) + 2;
          return result + 'px'
          break;
      }
    } else {
      return value
    }
  }

  getStartIndex(i: number): number {
    return i > 0 ? this.dividersIndex[i - 1] : 0;
  }



  ngOnDestroy() {
    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
// for iOS
export const album_button_map_ios = {
  albums: [
    {
      id: 0,
      en: true,
      emptyImage: { 1: "./assets/images/mobile/emptyImageSideTopBar3.png", },
      padding: 0,
      opacity: 0,
      blur: "0px",
      round: "rounded-0",
      width: { 1: "100%", 2: "100%", 3: "100%" },
    },
    {
      id: 1,
      en: false,
      emptyImage: { 1: "./assets/images/mobile/emptyImageSideTopBar4.png", },
      padding: 0,
      opacity: 0,
      blur: "0px",
      round: "rounded-0",
      width: { 1: "100%", 2: "100%", 3: "100%" },
    },
    {
      id: 2,
      en: true,
      emptyImage: {  1: "./assets/images/mobile/emptyImageSideBottom.png", },
      padding: 0,
      opacity: 0,
      blur: "0px",
      round: "rounded-4",
    },
  ],
}

